import Axios from "axios";
import { filter, upperFirst } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FormItemInterface } from "../../../components/form/Form";
import {
  activateFormModal,
  deactivateFormModal,
  updateFormModal,
} from "../../../store/actions";
import { apiClient } from "../../config";
import { RoleInterface, StorageReducerInterface } from "../../interfaces";
import { useShowError } from "../../useShowError";

const useNewMemberModal = ({
  onMemberCreation,
  setLoading,
}: {
  onMemberCreation: () => void;
  setLoading: (value: boolean) => void;
}) => {
  const dispatch = useDispatch<any>();
  const showError = useShowError();
  const auth = useSelector((store: StorageReducerInterface) => store.auth);

  const newMemberModal = () => {
    setLoading(true);
    Axios.get(`${apiClient}/member-roles`).then(
      ({ data: roles }: { data: RoleInterface[] }) => {
        setLoading(false);
        let items: FormItemInterface[] = [
          {
            name: "recipient_name",
            placeholder: "Name",
            rules: [
              { required: true, message: "'Name' is required" },
              {
                type: "string",
                min: 2,
                message: "'Name' must be at least 2 characters",
              },
            ],
            span: 12,
          },
          {
            name: "recipient_email",
            placeholder: "Email",
            rules: [
              { required: true, message: "'Email' is required" },
              { type: "email", message: "'Email' is not a valid email" },
            ],
            span: 12,
          },
          {
            name: "role_id",
            placeholder: "Role",
            type: "select",
            rules: [{ required: true }],
            data: roles?.map((role) => ({
              label: upperFirst(role.name),
              value: role.id,
            })),
          },
          {
            type: "number",
            name: "monthly_spending_limit",
            placeholder: "Monthly spending limit",
          },
        ];

        dispatch(
          activateFormModal({
            primaryAction: (values) => {
              dispatch(
                updateFormModal({
                  loadingPrimaryButton: true,
                })
              );
              Axios.post(
                `${apiClient}/companies/${auth.default_company?.company_id}/invitations`,
                values
              )
                .then(async () => {
                  await onMemberCreation();
                  dispatch(deactivateFormModal());
                })
                .catch(showError);
            },
            initialValues: {
              monthly_spending_limit: 0,
            },
            onFieldsChange: (changedFields) => {
              const role: { value: any } | undefined = filter(changedFields, {
                name: ["role_id"],
              })?.[0];
              if (filter(roles, { id: role?.value })[0]?.name === "viewer") {
                dispatch(
                  updateFormModal({
                    items: [
                      ...items,
                      {
                        type: "checkbox",
                        name: "allow_to_seeing_all_mails",
                        data: "Allow viewing of all mails",
                      },
                    ],
                  })
                );
              } else if (role?.value) {
                dispatch(
                  updateFormModal({
                    items: items,
                  })
                );
              }
            },
            title: "New Member",
            description:
              "Send invitations to your coworkers and made them part of your organization.",
            items: items,
          })
        );
      }
    );
  };

  return newMemberModal;
};

export default useNewMemberModal;
