import { SwitchCompaniesReducerInterface } from "../../utils";

export const activateSwitchCompanies = (
  payload?: SwitchCompaniesReducerInterface
) => ({
  type: "ACTIVATE_SWITCH_COMPANIES",
  payload,
});

export const updateSwitchCompanies = (
  payload?: Partial<SwitchCompaniesReducerInterface>
) => ({
  type: "UPDATE_SWITCH_COMPANIES",
  payload,
});

export const deactivateSwitchCompanies = () => ({
  type: "DEACTIVATE_SWITCH_COMPANIES",
});
