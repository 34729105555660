import React from "react";
import styled from "styled-components";
import { Col, Input, Row } from "antd";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";

function Search({
  className,
  style,
  onInput,
  onChange,
}: {
  className?: string;
  style?: React.CSSProperties;
  onInput?: (value: string) => void;
  onChange?: (value: string) => void;
}) {
  return (
    <Row style={style} className={className} align="middle" wrap={false}>
      <SearchIcon className="icon" />
      <Col flex="1">
        <Input
          className="input"
          placeholder="Search"
          onInput={(e) => onInput?.(e.currentTarget.value)}
          onChange={(e) => onChange?.(e.currentTarget.value)}
        />
      </Col>
    </Row>
  );
}

export default styled(Search)`
  border: 1px solid black;
  border-radius: 100px;
  padding: 2.5px 15px;

  .icon {
  }

  .input {
    border: none;
    background-color: transparent;
    border-radius: 100px;

    &:focus {
      box-shadow: none;
    }
  }
`;
