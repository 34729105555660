import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { guidelines } from "../../utils";
import { H1, H2, Body1, Body2 } from "..";

export const CardVertical = styled(
  ({
    className,
    prefix,
    title,
    suffix,
    subtitle,
    description = [],
    ...props
  }) => {
    let descriptionList = description.map((line, index) => {
      return <Body2 key={index}>{line}</Body2>;
    });
    return (
      <Col flex="0 1 auto" className={className} {...props}>
        <Row justify="end" align="bottom">
          <Col flex="1" className="container">
            <Row className="content">
              <Col span={24}>
                <H1 marginTop="0" color={guidelines.colors.blue[0]}>
                  <span>{prefix}</span> {title} <span>{suffix}</span>
                </H1>
                <H2 notera={true}>{subtitle}</H2>
                {descriptionList}
              </Col>
            </Row>
          </Col>
          <Col flex="none" className="corner"></Col>
          <Col flex="none" className="footer">
            <Body1 marginTop="0">Activate</Body1>
          </Col>
        </Row>
      </Col>
    );
  }
)`
  width: 270px;
  .container {
    border-top-right-radius: ${guidelines.borderRadius[0]}px;
    border-top-left-radius: ${guidelines.borderRadius[0]}px;
    border-bottom-left-radius: ${guidelines.borderRadius[0]}px;
    background: ${guidelines.colors.yellow[0]};
  }
  .content {
    background: white;
    padding: ${guidelines.padding[4]}px;
    min-height: 350px;
    border-top: 1px solid ${guidelines.colors.blue[0]};
    border-left: 1px solid ${guidelines.colors.blue[0]};
    border-radius: ${guidelines.borderRadius[0]}px;
  }
  .corner {
    width: 25px;
    height: 300px;
    background: ${guidelines.colors.yellow[0]};
    border-top-right-radius: ${guidelines.borderRadius[0]}px;
  }
  .footer {
    width: 85%;
    padding: ${guidelines.padding[0]}px ${guidelines.padding[3]}px;
    background: ${guidelines.colors.yellow[0]};
    border-bottom-left-radius: ${guidelines.borderRadius[0]}px;
    border-bottom-right-radius: ${guidelines.borderRadius[0]}px;
  }
  h1 span {
    font-size: ${guidelines.fontSize.h3}px;
    font-weight: 300;
  }
`;
