import React, { useState, useEffect } from "react";
import { Button, Col, Row, Select, Form, RowProps } from "antd";
import { H2, Body1 } from "../typography";
import {
  guidelines,
  WindowSizeReducerInterface,
  VoipPromptsInterface,
  VoipUserInterface,
  StorageReducerInterface,
  apiClient,
} from "../../utils";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Digit from "./Digit";
import Action from "./Action";
import { Mp3Upload } from "../form";
import { ReactComponent as UserIcon } from "../../icons/user.svg";
import { ReactComponent as VoicemailIcon } from "../../icons/voicemail.svg";
import { ReactComponent as PhoneIcon } from "../../icons/phone.svg";
import { Space } from "../layout";

const { Item, List } = Form;

const iconSize = 28;

const connectTo = [
  <Select.Option value="user" key="user">
    <Row align="middle" wrap={false}>
      <UserIcon style={{ width: iconSize, height: iconSize }} />
      <Space width={7.5} /> User
      {/* <Space width={7.5} />
      <u> User</u> */}
    </Row>
  </Select.Option>,
  <Select.Option value="other" key="other">
    <Row align="middle" wrap={false}>
      <PhoneIcon style={{ width: iconSize, height: iconSize }} />
      <Space width={7.5} /> Phone
      {/* <Space width={7.5} />
      <u>Phone</u> */}
    </Row>
  </Select.Option>,
  <Select.Option value="prompt" key="prompt">
    <Row align="middle" wrap={false}>
      <VoicemailIcon style={{ width: iconSize, height: iconSize }} />
      <Space width={7.5} /> Message
      {/* <Space width={7.5} />
      <u>Message</u> */}
    </Row>
  </Select.Option>,
];

const Menu = ({
  audio,
  promptOptions,
  users,
  fetching,
  windowSize,
}: {
  audio: HTMLAudioElement;
  users: VoipUserInterface[];
  fetching: {
    prompts: boolean;
  };
  promptOptions: VoipPromptsInterface[];
  windowSize: WindowSizeReducerInterface;
}) => {
  return (
    <>
      <List name={["voice_app", "menu"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              <Col span={24}>
                <Body1 marginTop="0" marginBottom={guidelines.margin[0]}>
                  Digits
                </Body1>
              </Col>
              {fields.map((field) => {
                return (
                  <Digit
                    key={field.key}
                    connectTo={connectTo}
                    fields={fields}
                    promptOptions={promptOptions}
                    fetching={fetching}
                    remove={remove}
                    parentName={["voice_app", "menu"]}
                    field={field}
                  />
                );
              })}
              {fields.length < 10 && (
                <Col>
                  <Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      Add digit
                    </Button>
                  </Item>
                </Col>
              )}
            </>
          );
        }}
      </List>
      <Col span={24}>
        <Body1 marginTop="0" marginBottom={guidelines.margin[0]}>
          If no option is selected
        </Body1>
      </Col>
      <Action
        name={["voice_app", "timeout"]}
        connectTo={connectTo.filter((action) => action.key !== "prompt")}
        promptOptions={promptOptions}
        fetching={fetching}
        toField={{
          xs: {
            span: 24,
          },
          md: { span: 12 },
        }}
      />
    </>
  );
};

interface VoIPInterface {
  className?: string;
}

let VoIP = ({
  className,
  ...props
}: VoIPInterface & RowProps & React.RefAttributes<HTMLDivElement>) => {
  let audio = new Audio();

  const [promptOptions, setPromptOptions] = useState<VoipPromptsInterface[]>(
    []
  );

  const [show, setShow] = useState<{
    prompts: boolean;
  }>({
    prompts: false,
  });

  const {
    auth: { default_company },
    voip: { users, prompts, voice_app },
    windowSize,
  } = useSelector((state: StorageReducerInterface) => state);

  useEffect(() => {
    switch (voice_app.play_menu) {
      case "yes":
        setPromptOptions(
          prompts.filter(
            (prompt) =>
              ["1004"].includes(prompt.record_id) || prompt.type === "custom"
          )
        );
        break;

      case "no":
        setPromptOptions(
          prompts.filter(
            (prompt) =>
              ["1004"].includes(prompt.record_id) || prompt.type === "custom"
          )
        );
        break;

      default:
        break;
    }
    return () => {};
  }, [prompts, voice_app]);

  const [fetching, setFetching] = useState<{ prompts: boolean }>({
    prompts: false,
  });

  const handlePlayMenuChange = (value: string) => {
    setFetching({
      prompts: true,
    });

    switch (value) {
      case "yes":
        setPromptOptions(
          prompts.filter(
            (prompt) =>
              ["1004"].includes(prompt.record_id) || prompt.type === "custom"
          )
        );
        break;

      case "no":
        setPromptOptions(
          prompts.filter(
            (prompt) =>
              ["1004"].includes(prompt.record_id) || prompt.type === "custom"
          )
        );
        break;

      default:
        break;
    }

    setFetching({
      prompts: false,
    });
  };

  return (
    <Row className={className} gutter={[10, 0]} {...props}>
      <Col span={24}>
        <H2 marginTop="0" marginBottom={guidelines.margin[0]}>
          Menu
        </H2>
      </Col>
      <Col span={24}>
        <div className="input">
          <Item
            name={["voice_app", "play_menu"]}
            dependencies={[["voice_app", "menu"]]}
            rules={[
              { required: true, message: "Please select a value!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !(
                      value === "yes" &&
                      (!getFieldValue(["voice_app", "menu"]) ||
                        getFieldValue(["voice_app", "menu"])?.length === 0)
                    )
                  )
                    return Promise.resolve();

                  return Promise.reject("Please add at least one (1) digit!");
                },
              }),
            ]}
          >
            <Select
              bordered={false}
              onChange={handlePlayMenuChange}
              placeholder="Play menu"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            ></Select>
          </Item>
        </div>
      </Col>
      <Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          return (
            prevValues?.voice_app?.play_menu !== curValues?.voice_app?.play_menu
          );
        }}
      >
        {({ getFieldValue }) => {
          if (getFieldValue(["voice_app", "play_menu"]) === "yes") {
            return (
              <Menu
                users={users}
                promptOptions={promptOptions}
                audio={audio}
                fetching={fetching}
                windowSize={windowSize}
              />
            );
          } else if (getFieldValue(["voice_app", "play_menu"]) === "no") {
            return (
              <>
                <Col span={24}>
                  <Body1 marginTop="0" marginBottom={guidelines.margin[0]}>
                    Forward calls
                  </Body1>
                </Col>
                <Action
                  name={["voice_app", "final_action"]}
                  connectTo={connectTo.filter(
                    (action) => action.key !== "prompt"
                  )}
                  promptOptions={promptOptions}
                  fetching={fetching}
                  toField={{
                    xs: {
                      span: 12,
                    },
                  }}
                />
              </>
            );
          }
        }}
      </Item>
      <Col span={24}>
        <H2
          cursor="pointer"
          onClick={() => setShow({ ...show, prompts: !show.prompts })}
          marginTop="0"
          marginBottom={guidelines.margin[0]}
        >
          <span>{show.prompts ? "-" : "+"}</span> Prompts
        </H2>
      </Col>
      {show.prompts && (
        <Col span={24}>
          <Row gutter={[0, 10]}>
            {prompts
              .filter(
                (prompt) =>
                  ["1001", "1002", "1004", "1007", "1008"].includes(
                    prompt.record_id
                  ) || prompt.type === "custom"
              )
              .map((prompt, index) => (
                <Col key={prompt.record_id} span={24}>
                  <Mp3Upload
                    data={{
                      file: {
                        url: prompt.url,
                        title: prompt.label,
                        size: prompt.size,
                      },
                      request: {
                        key: "file",
                        url: `${apiClient}/companies/${default_company?.company_id}/voip/prompts/${prompt.value}`,
                      },
                    }}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default styled(VoIP)`
  .ant-form-item-control-input-content {
    display: flex;
  }
  .ant-select-selection-item {
    height: 100%;
    display: flex;
    align-content: center;
    .play {
      display: none;
    }
  }
`;
