import React, { useState } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { TabMenuItem } from "..";
import { guidelines } from "../../utils";
import { startCase } from "lodash";
const Line = styled.div`
  border-bottom: 1px solid ${guidelines.colors.blue[0]};
`;

interface TabMenuInterface extends React.RefAttributes<HTMLDivElement> {
  className?: string;
  style?: React.CSSProperties;
  onChange: (index: number) => void;
  items?: string[];
}

const TabMenu = ({
  className,
  style,
  onChange,
  items = [],
  ...props
}: TabMenuInterface) => {
  const [state, setState] = useState<{ activeIndex: number }>({
    activeIndex: 0,
  });
  const setActiveIndex = (index: number) => {
    setState({
      activeIndex: index,
    });
    if (onChange) onChange(index);
  };

  let itemList = items?.map((item, index) => {
    return (
      <TabMenuItem
        index={index}
        onClick={() => setActiveIndex(index)}
        active={items[state.activeIndex] ? state.activeIndex : 0}
        key={index}
      >
        {startCase(item)}
      </TabMenuItem>
    );
  });
  return (
    <Row style={style} className={className} {...props} align="bottom">
      {itemList}
      <Col flex="1">
        <Line />
      </Col>
    </Row>
  );
};

export default styled(TabMenu)`
  flex-wrap: nowrap;
  overflow: scroll;
  margin-top: ${guidelines.margin[4]}px;
`;
