import { SwitchCompaniesReducerInterface } from "../../utils";

const initialState: SwitchCompaniesReducerInterface = {
  closeable: true,
  loading: false,
};

export default function switchCompaniesReducer(
  state = initialState,
  {
    type,
    payload,
  }:
    | {
        type: "ACTIVATE_SWITCH_COMPANIES";
        payload?: SwitchCompaniesReducerInterface;
      }
    | {
        type: "UPDATE_SWITCH_COMPANIES";
        payload?: SwitchCompaniesReducerInterface;
      }
    | { type: "DEACTIVATE_SWITCH_COMPANIES"; payload?: null }
) {
  switch (type) {
    case "ACTIVATE_SWITCH_COMPANIES":
      return { ...initialState, active: true, ...payload };

    case "UPDATE_SWITCH_COMPANIES":
      return { ...state, ...payload };

    case "DEACTIVATE_SWITCH_COMPANIES":
      return initialState;

    default:
      return state;
  }
}
