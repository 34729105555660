import { Button, Col, ColProps, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import {
  guidelines,
  responsive,
  apiClient,
  RoleInterface,
  StorageReducerInterface,
  useShowError,
  InvitationInterface,
} from "../../utils";
import { filter, upperFirst } from "lodash";
import { Body1, Body2 } from "../typography";
import { ReactComponent as CheckIcon } from "../../img/check.svg";
import moment from "moment";
import Axios from "axios";
import { FormItemInterface } from "../form/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  activateFormModal,
  deactivateFormModal,
  updateFormModal,
} from "../../store/actions";
import { ColSize } from "antd/lib/grid";

const Label = styled(({ className, children }: ColProps) => (
  <Col className={className} xs={{ span: 8 }} lg={{ span: 0 }}>
    <Body2 textAlign="right" marginTop="0">
      {children}
    </Body2>
  </Col>
))`
  margin-bottom: 10px;
  p {
    padding: 5px 0;
    background-color: ${guidelines.colors.blue[4]};
    color: ${guidelines.colors.grey[0]};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    padding-right: 10px;
  }
`;

const Data = styled(
  ({
    lgSpan,
    children,
    className,
    style,
  }: ColProps & {
    lgSpan: ColSize["span"];
  }) => (
    <Col
      className={className}
      style={style}
      xs={{ offset: 1, span: 14 }}
      lg={{ offset: 0, span: lgSpan }}
    >
      {children}
    </Col>
  )
)`
  padding-right: 10px;
  padding-top: 5px;
  margin-bottom: ${() => responsive({ xs: 10, lg: null }, window.innerWidth)}px;
`;

const Invitation = ({
  className,
  data,
  data: { id, created_at, recipient_name, recipient_email, role, mail },
  actions,
  onUpdateFinished,
  onDeleteFinished,
}: {
  className?: string;
  data: InvitationInterface;
  actions?: boolean;
  onUpdateFinished?: Function;
  onDeleteFinished?: Function;
}) => {
  const [editLoading, setEditLoading] = useState<boolean>();
  const dispatch = useDispatch<any>();
  const default_company = useSelector(
    (state: StorageReducerInterface) => state.auth.default_company
  );
  const showError = useShowError();

  const editInvitation = () => {
    setEditLoading(true);
    Axios.get(`${apiClient}/member-roles`).then(
      ({ data: roles }: { data: RoleInterface[] }) => {
        setEditLoading(false);
        let items: FormItemInterface[] = [
          {
            type: "custom",
            data: (
              <>
                <Body1 marginTop="0">{data.recipient_name}</Body1>
                <Body2
                  style={{
                    marginTop: 0,
                    marginBottom: 10,
                    color: guidelines.colors.grey[0],
                  }}
                >
                  ({data.recipient_email})
                </Body2>
              </>
            ),
          },
          {
            name: "role_id",
            placeholder: "Role",
            type: "select",
            rules: [{ required: true }],
            data: roles?.map((role) => ({
              label: upperFirst(role.name),
              value: role.id,
            })),
          },
          {
            type: "number",
            name: "monthly_spending_limit",
            placeholder: "Monthly spending limit",
          },
        ];

        dispatch(
          activateFormModal({
            primaryAction: (values) => {
              dispatch(
                updateFormModal({
                  loadingPrimaryButton: true,
                })
              );
              Axios.put(
                `${apiClient}/companies/${default_company?.company_id}/invitations/${id}`,
                values
              )
                .then(async () => {
                  await onUpdateFinished?.();
                  dispatch(deactivateFormModal());
                })
                .catch(showError);
            },
            initialValues: { ...data, role_id: data.role?.id },
            onFieldsChange: (changedFields) => {
              const role: { value: any } | undefined = filter(changedFields, {
                name: ["role_id"],
              })?.[0];
              if (filter(roles, { id: role?.value })[0]?.name === "viewer") {
                dispatch(
                  updateFormModal({
                    items: [
                      ...items,
                      {
                        type: "checkbox",
                        name: "allow_to_seeing_all_mails",
                        data: "Allow viewing of all mails",
                      },
                    ],
                  })
                );
              } else if (role?.value) {
                dispatch(
                  updateFormModal({
                    items: items,
                  })
                );
              }
            },
            title: "Edit Invitation",
            items:
              data.role?.name === "viewer"
                ? [
                    ...items,
                    {
                      type: "checkbox",
                      name: "allow_to_seeing_all_mails",
                      data: "Allow viewing of all mails",
                    },
                  ]
                : items,
          })
        );
      }
    );
  };

  const deleteInvitation = () => {
    dispatch(
      activateFormModal({
        primaryAction: () => {
          dispatch(
            updateFormModal({
              loadingPrimaryButton: true,
            })
          );

          Axios.delete(
            `${apiClient}/companies/${default_company?.company_id}/invitations/${id}`
          )
            .then(async () => {
              await onDeleteFinished?.();
              dispatch(deactivateFormModal());
            })
            .catch(showError);
        },
        title: "Delete Invitation",
        description: "Are you sure you want to delete this invitation?",
      })
    );
  };

  return (
    <Row className={className}>
      <Col span={24}>
        <Row className="info" justify="center">
          <Col span={23}>
            <Row>
              <Label>ID</Label>
              <Data
                style={{ color: guidelines.colors.grey[0], paddingTop: 0 }}
                lgSpan={2}
              >
                <Row className="idContainer">
                  <div className={`id idActive`}>{id}</div>
                </Row>
              </Data>
              <Label>Recipient</Label>
              <Data lgSpan={6}>
                <Row>
                  <Col span={24}>
                    <Row>{recipient_name}</Row>
                    <Row>
                      <Body2
                        style={{
                          color: guidelines.colors.grey[0],
                        }}
                        marginTop="0"
                      >
                        {recipient_email}
                      </Body2>
                    </Row>
                  </Col>
                </Row>
              </Data>
              <Label>Role</Label>
              <Data lgSpan={3} style={{ paddingTop: 0 }}>
                <Row>
                  <div className="type">{upperFirst(role?.name)}</div>
                </Row>
              </Data>
              <Label>Mail</Label>
              <Data lgSpan={3}>{false && <CheckIcon />}</Data>
              <Label>Status</Label>
              <Data lgSpan={5}>Invited {moment(created_at).fromNow()}</Data>
            </Row>
          </Col>
        </Row>
        {actions && (
          <Row justify="end">
            <Button
              loading={editLoading}
              onClick={editInvitation}
              className="edit"
            >
              Edit
            </Button>
            <Button className="delete" onClick={deleteInvitation}>
              Delete
            </Button>
            {/* <Dropdown overlay={menu({ type })}>
            <Button className="actions">
              Actions <DownOutlined />
            </Button>
          </Dropdown> */}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default styled(Invitation)`
  margin-top: ${guidelines.margin[0]}px;

  .info {
    padding: ${guidelines.padding[1]}px 0;
    border-bottom: 1px solid ${guidelines.colors.grey[2]};
  }

  .edit,
  .restore {
    margin-top: ${guidelines.padding[0]}px;
    border-color: ${guidelines.colors.grey[1]};
    border-radius: 100px;
    margin-right: 10px;
  }

  .delete {
    margin-top: ${guidelines.padding[0]}px;
    border-color: transparent;
    border-radius: 100px;
  }

  .idContainer {
  }

  .id {
    width: 32px;
    height: 32px;
    background-color: ${guidelines.colors.blue[4]};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .idActive {
    background-color: ${guidelines.colors.green[2]};
    color: ${guidelines.colors.green[0]};
  }

  .type {
    border-radius: 5px;
    padding: 5px 7.5px;
    border: 1px solid ${guidelines.colors.green[1]};
    flex-shrink: 0;
  }
`;
