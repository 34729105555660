import { Col, Row, RowProps } from "antd";
import styled from "styled-components";
import { Alias, Body1, Body2 } from "../../components";
import { Space } from "../../components/layout";
import { CompanyInterface, guidelines } from "../../utils";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";

let Label = ({
  children,
  className,
  backgroundColor,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  color?: string;
  backgroundColor?: string;
} & RowProps &
  React.RefAttributes<HTMLDivElement>) => (
  <Col>
    <Row align="middle" className={className} {...props}>
      {children}
    </Row>
  </Col>
);

Label = styled(Label)`
  color: ${(props) => props.color ?? guidelines.colors.grey[4]};
  background-color: ${(props) =>
    props.backgroundColor ?? guidelines.colors.grey[3]};
  padding: 3.5px 14px;
  border-radius: 5px;
`;

const Description = ({
  customer,
  onDeleteMailPickerClick,
  ...props
}: {
  customer: CompanyInterface;
  onDeleteMailPickerClick: Function;
} & RowProps &
  React.RefAttributes<HTMLDivElement>) => {
  return (
    <Row {...props}>
      <Col>
        {customer.aliases && (
          <Row gutter={[10, 0]} align="middle" className="attribute">
            <Col className="attribute-name">
              <Body2 marginTop="0">Alias(es):</Body2>{" "}
            </Col>
            {customer?.aliases?.map((alias, index) => (
              <Alias key={index}>{alias}</Alias>
            ))}
          </Row>
        )}
        <Row gutter={[10, 10]} align="middle" className="attribute">
          <Col className="attribute-name">
            <Body2 marginTop="0">Subscription(s):</Body2>{" "}
          </Col>
          {customer?.subscriptions?.map((subscription, index) =>
            index % 2 === 0 || index === 0 ? (
              <Label
                key={index}
                color="#599AF8"
                backgroundColor={guidelines.colors.blue[4]}
              >
                {subscription}
              </Label>
            ) : (
              <Label
                key={index}
                color="#9C8FF8"
                backgroundColor={guidelines.colors.purple[0]}
              >
                {subscription}
              </Label>
            )
          )}
        </Row>
        {Boolean(customer.mail_pickers?.length) && (
          <Row gutter={[10, 10]} className="attribute">
            <Col className="attribute-name">
              <Body2 marginTop="0">Mail picker(s):</Body2>{" "}
            </Col>
            <Col flex="1">
              <Row gutter={[10, 10]} align="middle">
                {customer?.mail_pickers?.map(({ id, name }) => (
                  <Label
                    key={id}
                    color={guidelines.colors.green[0]}
                    backgroundColor={guidelines.colors.green[2]}
                    wrap={false}
                  >
                    <Col>{name}</Col>
                    <Space width={5} />
                    <Col>
                      <CloseOutlined
                        onClick={() => onDeleteMailPickerClick({ id })}
                        style={{ cursor: "pointer" }}
                      />
                    </Col>
                  </Label>
                ))}
              </Row>
            </Col>
          </Row>
        )}
        <Row gutter={[0, 10]} align="middle" className="attribute">
          <Col className="attribute-name">
            <Body2 marginTop="0">Last request:</Body2>
          </Col>
          <Col>
            {customer?.last_requested_service ? (
              <Body1 marginTop="0" color={guidelines.colors.green[0]}>
                {moment(customer.last_requested_service.created_at).fromNow()}
              </Body1>
            ) : (
              <Body1 marginTop="0" color="#747270">
                No request made so far
              </Body1>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default styled(Description)`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 25px 20px;
  border: 1px solid rgba(177, 177, 177, 0.8);
  .attribute {
    &:not(:first-child) {
      margin-top: 10px;
    }
    .attribute-name {
      min-width: 125px;
    }

    .alias {
      p {
        color: ${guidelines.colors.grey[0]};
      }
      .dot {
        width: 3px;
        height: 3px;
        border-radius: 100px;
        margin-right: 5px;
        background-color: ${guidelines.colors.grey[4]};
      }
    }
  }
`;
