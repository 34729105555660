import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateHeaderData } from "../../store/actions";

const useBackToHomeHeader = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  return () =>
    dispatch(
      updateHeaderData({
        mode: "light",
        backButton: {
          active: true,
          onClick: async () => {
            await dispatch(
              updateHeaderData({
                mode: "transparent",
                backButton: {
                  active: false,
                },
              })
            );
            navigate("/");
          },
        },
      })
    );
};

export default useBackToHomeHeader;
