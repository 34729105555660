import { FormModalReducerInterface } from "../../utils";

const initialState: FormModalReducerInterface = {};

export default function formModalReducer(
  state = initialState,
  { type, payload }: { type: string; payload: FormModalReducerInterface }
) {
  switch (type) {
    case "ACTIVATE_FORM_MODAL":
      return { ...payload, activeModal: true };

    case "UPDATE_FORM_MODAL":
      return { ...state, ...payload };

    case "DEACTIVATE_FORM_MODAL":
      return initialState;

    default:
      return state;
  }
}
