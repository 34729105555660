import React from "react";
import Axios from "axios";
import { deactivateFormModal, updateFormModal } from "../../../store/actions";
import { apiAdmin } from "../../config";
import {
  FormModalReducerInterface,
  MailAdminActionInterface,
} from "../../interfaces";

export const pickUpMailModal = ({
  data,
  dispatch,
  onActionFinished,
  showError,
}: MailAdminActionInterface): FormModalReducerInterface => ({
  title: "Mail Picked Up",
  description: "Are you sure that the customer picked up the mail?",
  items: [
    {
      type: "paragraph",
      data: (
        <span style={{ fontWeight: 500 }}>
          <i>This action can't be undone.</i>
        </span>
      ),
    },
    {
      type: "datepicker",
      name: "date",
      placeholder: "Select pick-up date",
      rules: [
        {
          type: "date",
          required: true,
        },
      ],
    },
  ],
  primaryAction: (values) => {
    dispatch(updateFormModal({ loadingPrimaryButton: true }));
    Axios.post(`${apiAdmin}/mail-items/${data.id}/picked-up`, values)
      .then(async (res) => {
        await onActionFinished?.();
        dispatch(deactivateFormModal());
      })
      .catch(showError);
  },
});
