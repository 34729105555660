import { Row, RowProps } from "antd";
import React from "react";
import styled from "styled-components";
import { guidelines } from "../../utils";

let LineScale: any = ({
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => {
  return (
    <Row justify="center" align="middle" {...props}>
      <div className="line-scale">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Row>
  );
};

LineScale = styled(LineScale)`
  height: 60%;
  > * > div {
    width: 3.5px;
    background-color: ${guidelines.colors.red[0]};
  }
`;

export default LineScale;
