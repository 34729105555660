import React, { useEffect } from "react";
import { Row, Col, RowProps } from "antd";
import styled from "styled-components";
import { MenuClient, MenuAdmin } from "./";
import {
  apiClient,
  guidelines,
  responsive,
  StorageReducerInterface,
  WindowSizeReducerInterface,
} from "../../utils";
import { Body1 } from "..";
import { useSelector } from "react-redux";
import { Overview } from "./";
import { useQueryClient } from "@tanstack/react-query";
import Axios from "axios";

let LegalTerms = ({ className }: { className?: string }) => {
  const { width } = useSelector(
    (state: { windowSize: WindowSizeReducerInterface }) => state.windowSize
  );
  return (
    <Row
      className={className}
      justify="space-between"
      gutter={[guidelines.spacing[0], 10]}
    >
      <Col flex="none">
        <Row gutter={[guidelines.spacing[0], 10]} justify="center">
          <Col flex="none">
            <a
              href="https://drive.google.com/file/d/1IpsY8uzuAaZobZmLLpP8Q5Gf5ozDLboN/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Body1 textAlign="center" marginTop="0" cursor="pointer">
                terms and conditions
              </Body1>
            </a>
          </Col>
          <Col flex="none">
            <a
              href="https://drive.google.com/file/d/1S94pkUuhGgdapnil90xuMKREfIo6uDSj/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Body1 textAlign="center" marginTop="0" cursor="pointer">
                privacy policy
              </Body1>
            </a>
          </Col>
        </Row>
      </Col>
      <Col flex={responsive({ xs: "auto", md: "none" }, width)}>
        <Row
          gutter={[guidelines.spacing[0], guidelines.spacing[0]]}
          justify="center"
        >
          <Col flex="none">
            <Body1 textAlign="center" marginTop="0">
              &copy; all rights reserved
            </Body1>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

LegalTerms = styled(LegalTerms)`
  margin: 50px 0 0 0 !important;
  padding: 15px ${guidelines.padding[3]}px;
  border-radius: ${guidelines.borderRadius[1]}px;
  background: ${guidelines.colors.yellow[1]};
`;

interface MenuInterface extends RowProps, React.RefAttributes<HTMLDivElement> {}

const Menu = ({ className, ...props }: MenuInterface) => {
  const { auth, windowSize } = useSelector(
    (store: StorageReducerInterface) => ({
      auth: store.auth,
      windowSize: store.windowSize,
    })
  );

  const client = useQueryClient();

  const { default_company } = auth;

  useEffect(() => {
    const init = async () =>
      default_company &&
      (await client.prefetchQuery({
        queryKey: ["subscriptions"],
        queryFn: () =>
          Axios.get(
            `${apiClient}/companies/${default_company?.company.id}/subscriptions`
          ).then(({ data }) => {
            return data;
          }),
      }));

    init();
    // eslint-disable-next-line
  }, [default_company]);

  const contentSpan = responsive({ xs: 21, md: 19, lg: 22 }, windowSize.width);
  return (
    <Row justify="center" className={className} {...props}>
      <Col span={contentSpan}>
        <Row justify="center">
          <Col xs={{ span: 22, order: 2 }} lg={{ order: 1, span: 16 }}>
            <MenuClient />
            {auth.roles?.filter((role) => role.name === "admin")[0] && (
              <MenuAdmin />
            )}
          </Col>
          <Col xs={{ order: 1, span: 24 }} lg={{ order: 2, span: 8 }}>
            <Overview />
          </Col>
        </Row>
        <LegalTerms />
      </Col>
    </Row>
  );
};

export default styled(Menu)`
  background: ${guidelines.colors.blue[0]};
  padding-top: ${guidelines.headerPadding}px;
  padding-bottom: 50px;
  border-bottom-left-radius: ${guidelines.borderRadius[2]}px;
  border-bottom-right-radius: ${guidelines.borderRadius[2]}px;

  > .ant-col {
    max-width: 1240px;
  }
`;
