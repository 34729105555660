import { useEffect, useState } from "react";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

const useSegment = () => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const [writeKey] = useState("6BKJxRB0GYOlGwQFXJtOXJW2iSsKgxdG");

  useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [writeKey]);

  return analytics;
};

export default useSegment;
