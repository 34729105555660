import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { H2, Body1, Body2 } from "..";
import {
  guidelines,
  responsive,
  WindowSizeReducerInterface,
} from "../../utils";
import { ColProps } from "antd/lib/col";
import { Dispatch } from "redux";

const style = ({
  cardWidth,
  type,
}: {
  cardWidth: number;
  type?: "vertical" | "horizontal";
}) => {
  switch (type) {
    case "vertical":
      return `
                width: ${cardWidth}px;
                .content {
                    min-height: ${cardWidth * 1.35}px;
                }
                .corner {
                    height: ${cardWidth * 1.15}px;
                }
            `;

    case "horizontal":
    default:
      return `
                width: ${cardWidth}px;
                .content {
                    min-height: ${cardWidth * 0.65}px;
                }
                .corner {
                    height: ${cardWidth * 0.55}px;
                }
            `;
  }
};

export interface CardInterface
  extends ColProps,
    React.RefAttributes<HTMLDivElement> {
  type: "vertical" | "horizontal";
  cardWidth: number;
  windowSize: WindowSizeReducerInterface;
  dispatch: Dispatch;
  marginTop?: number | string;
  suffix?: React.ReactNode;
  subtitle?: React.ReactNode;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  abstract?: string[] | string;
  message?: React.ReactNode;
  disabled?: boolean;
}

let Card = ({
  className,
  prefix,
  title,
  dispatch,
  cardWidth,
  marginTop,
  suffix,
  subtitle,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  windowSize,
  abstract = [],
  message,
  disabled,
  ...props
}: CardInterface) => {
  let abstractList;

  if (Array.isArray(abstract))
    abstractList = abstract.map((line: string, index: number) => {
      return <Body2 key={index}>{line}</Body2>;
    });
  return (
    <Col
      onClick={() =>
        onPrimaryButtonClick && !disabled && onPrimaryButtonClick()
      }
      flex="0 1 auto"
      className={className}
      {...props}
    >
      <Row justify="end" align="bottom">
        <Col flex="1" className="container">
          <Row className="content">
            <div className="border" />
            <Col span={24}>
              <div className="title" color={guidelines.colors.blue[0]}>
                <span>{prefix}</span> {title} <span>{suffix}</span>
              </div>
              <H2>{subtitle}</H2>
              <div className="line"></div>
              {abstractList ?? abstract}
            </Col>
          </Row>
        </Col>
        <Col flex="none" className="corner"></Col>
        <Col flex="none" className="footer">
          <Row>
            {!message && onPrimaryButtonClick && (
              <Body1
                cursor="pointer"
                onClick={onPrimaryButtonClick}
                marginTop="0"
              >
                Activate
              </Body1>
            )}
            {!message && onSecondaryButtonClick && (
              <Body1
                color="white"
                cursor="pointer"
                onClick={onSecondaryButtonClick}
                marginTop="0"
              >
                Cancel
              </Body1>
            )}
          </Row>
        </Col>
        {message && (
          <Col flex="none" className="message">
            <Body1 marginTop="0">{message}</Body1>
          </Col>
        )}
      </Row>
    </Col>
  );
};

Card = styled(Card)`
  cursor: ${(props) =>
    props.onPrimaryButtonClick && !props.disabled ? "pointer" : null};
  .title {
    font-size: ${(props) =>
      responsive(guidelines.fontSize.title, props.windowSize.width)}px;

    span {
      font-size: ${(props) =>
        responsive(guidelines.fontSize.body2, props.windowSize.width)}px;
    }
  }
  .container {
    border-top-right-radius: ${guidelines.borderRadius[0]}px;
    border-top-left-radius: ${guidelines.borderRadius[0]}px;
    border-bottom-left-radius: ${guidelines.borderRadius[0]}px;
    background: ${guidelines.colors.yellow[0]};
    width: calc(100% - 25px);
  }
  .content {
    position: relative;
    background: white;
    padding: 30px;
    border-radius: ${guidelines.borderRadius[0]}px;
    border: 1px solid ${guidelines.colors.blue[0]};
    // .border {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 90%;
    //   height: 90%;
    //   border-top: 1px solid ${guidelines.colors.blue[0]};
    //   border-left: 1px solid ${guidelines.colors.blue[0]};
    //   border-top-left-radius: ${guidelines.borderRadius[0]}px;
    // }
  }
  .corner {
    width: 25px;
    background: ${guidelines.colors.yellow[0]};
    border-top-right-radius: ${guidelines.borderRadius[0]}px;
  }
  .footer {
    width: 85%;
    padding: ${guidelines.padding[0]}px ${guidelines.padding[3]}px;
    background: ${guidelines.colors.yellow[0]};
    border-bottom-left-radius: ${guidelines.borderRadius[0]}px;
    border-bottom-right-radius: ${guidelines.borderRadius[0]}px;
  }
  .message {
    width: 85%;
    padding: ${guidelines.padding[0]}px ${guidelines.padding[3]}px;
    border-bottom: 1px solid ${guidelines.colors.blue[0]};
  }
  .line {
    width: 100%;
    max-width: 150px;
    border-bottom: 1px solid #d3d3d2;
    padding-top: 12px;
  }
  ${(props) =>
    props.type && props.cardWidth
      ? style({ type: props.type, cardWidth: props.cardWidth })
      : null}
`;

export { Card };
