export const guidelines = {
  margin: [10, 12, 15, 19, 24],
  padding: [15, 19, 24, 30, 38, 48],
  headerPadding: 100,
  fontSize: {
    body1: { xs: 15 },
    body2: { xs: 14 },
    body3: { xs: 12 },
    h1: { xs: 35, md: 35 },
    h2: { xs: 22, md: 25 },
    h3: { xs: 16.5, md: 22 },
    title: { xs: 22, md: 25 },
  },
  sizes: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
  spacing: [20, 24],
  borderRadius: [20, 30, 40],
  colors: {
    blue: {
      0: "#020C42",
      1: "#8085A0",
      2: "#2593FC",
      3: "#E7F7FF",
      4: "#F5F7F9",
      5: "#396CB9",
      6: "#599AF8",
      7: "#F6F8FF",
      8: "#6182F5",
      9: "#E5EEFE",
      10: "#7499C6",
    },
    red: { 0: "#FC3366", 1: "#FF4848", 2: "#FFF2F0", 3: "#FBB7B7" },
    yellow: { 0: "#FD9F28", 1: "#FEF7EF" },
    grey: {
      0: "#747270",
      1: "#CCCCCC",
      2: "#D9D9D9",
      3: "#F2F2F2",
      4: "#B4B4B4",
      5: "#FAFAFA",
      6: "#505050",
    },
    green: { 0: "#6EAF2C", 1: "#BBE5D1", 2: "#EAF9F6" },
    purple: { 0: "#F8F6FF", 1: "#9C8FF8" },
  },
};
