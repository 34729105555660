import {
  SubscriptionInterface,
  SubscriptionsReducerInterface,
} from "./../../utils/interfaces";

const initialState: SubscriptionsReducerInterface = {
  all: [],
  active: [],
  active_ids: [],
};

export default function subscriptionsReducer(
  state = initialState,
  {
    type,
    payload,
  }:
    | {
        type: "SET_SUBSCRIPTIONS";
        payload: {
          all: SubscriptionInterface[];
          active: SubscriptionInterface[];
        };
      }
    | { type: "RESET_SUBSCRIPTIONS"; payload?: null }
) {
  switch (type) {
    case "SET_SUBSCRIPTIONS":
      return {
        ...state,
        ...payload,
        active_ids: payload?.active.map((subscription) => subscription.id),
      };

    case "RESET_SUBSCRIPTIONS":
      return initialState;

    default:
      return state;
  }
}
