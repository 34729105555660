/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement, CardElementProps } from "@stripe/react-stripe-js";
import styled from "styled-components";
import { guidelines } from "../../utils";
import { StripeCardElementOptions } from "@stripe/stripe-js";

const CARD_ELEMENT_OPTIONS: { [key: string]: StripeCardElementOptions } = {
  white: {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "white",
        color: "white",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  },
  dark: {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  },
};

interface CardSectionInterface extends CardElementProps {
  className?: string;
  type?: "white" | "dark";
}

const CardSection = ({
  className,
  onChange,
  type = "dark",
}: CardSectionInterface) => {
  return (
    <>
      <CardElement
        className={className}
        onChange={onChange}
        options={CARD_ELEMENT_OPTIONS[type]}
      />
    </>
  );
};

export default styled(CardSection)`
  margin-top: ${guidelines.margin[2]}px;
  border-radius: 5px;
  border: 1px solid ${guidelines.colors.grey[2]};
  padding: 10px 15px;
`;
