import React, { useState } from "react";
import {
  guidelines,
  MailAdminActionInterface,
  // useShowError,
} from "../../utils";
// import { useDispatch } from "react-redux";
import styled from "styled-components";
import DropdownButton from "../buttons/DropdownButton";
import {
  DownOutlined,
  SendOutlined,
  ScanOutlined,
  DeleteOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { Space } from "../layout";
import { Row } from "antd";

const StyledDropdownButton = styled(DropdownButton)`
  margin-top: ${guidelines.padding[0]}px;
  border-color: ${guidelines.colors.grey[1]};
  border-radius: 100px;
  color: black;
`;

function UserActionsButton({
  ...props
}: Omit<
  MailAdminActionInterface,
  "showError" | "dispatch" | "setStatus"
> & {}) {
  // const showError = useShowError();
  // const dispatch = useDispatch();
  const [
    status,
    // setStatus
  ] = useState<"loading">();

  const { data } = props;

  let actions: {
    name: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  }[] = [];

  // const action = async (callback: () => void) => {
  //   await props.onActionBegin?.();
  //   callback();
  // };

  switch (data.type?.name) {
    case "envelope":
      actions = [
        {
          name: (
            <Row align="middle">
              <SendOutlined />
              <Space width={7.5} />
              Ship
            </Row>
          ),
          onClick: async () => {},
        },
        {
          name: (
            <Row align="middle">
              <ScanOutlined />
              <Space width={7.5} />
              Scan
            </Row>
          ),
          onClick: async () => {},
        },
        {
          name: (
            <Row align="middle">
              <DeleteOutlined />
              <Space width={7.5} />
              Shred
            </Row>
          ),
          onClick: async () => {},
        },
      ];
      break;

    case "package":
      actions = [
        {
          name: (
            <Row align="middle">
              <SendOutlined />
              <Space width={7.5} />
              Ship
            </Row>
          ),
          onClick: async () => {},
        },
        {
          name: (
            <Row align="middle">
              <DeleteOutlined />
              <Space width={7.5} />
              Discard
            </Row>
          ),
          onClick: async () => {},
        },
      ];
      break;

    case "check":
      actions = [
        {
          name: (
            <Row align="middle">
              <BankOutlined />
              <Space width={7.5} />
              Deposit
            </Row>
          ),
          onClick: async () => {},
        },
      ];
      break;

    default:
      break;
  }

  return data.statuses?.[0].name === "available" ? (
    <StyledDropdownButton options={{ type: "outline", status, items: actions }}>
      Actions <DownOutlined style={{ marginLeft: 5, fontSize: 10 }} />
    </StyledDropdownButton>
  ) : (
    <></>
  );
}

export default UserActionsButton;
