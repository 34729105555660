import { StorageReducerInterface } from "./interfaces";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

export const useHasActiveVoPlan = () => {
  const activePlan = useSelector(
    (state: StorageReducerInterface) =>
      state.auth.default_company?.company.active_plan
  );

  const hasActiveVoPlan = () => {
    return !isEmpty(activePlan);
  };

  return hasActiveVoPlan;
};
