import React from "react";
import styled from "styled-components";
import { Col, Row, Button, ButtonProps, ColProps } from "antd";
import { H2, Body1, Body2 } from "..";
import { CloseOutlined } from "@ant-design/icons";
import { guidelines, SourceInterface } from "../../utils";
import { useDispatch } from "react-redux";
import { setDefaultSource, destroySource } from "../../store/actions";
import { useState } from "react";

let DefaultLabel = ({
  className,
  ...props
}: ColProps & React.RefAttributes<HTMLDivElement>) => (
  <Col className={className} {...props}>
    default
  </Col>
);

DefaultLabel = styled(DefaultLabel)`
  padding: 5px 20px;
  color: ${guidelines.colors.red[0]};
  background: white;
  border-radius: ${guidelines.borderRadius[0]}px;
`;

let DefaultButton = ({
  className,
  ...props
}: ButtonProps & React.RefAttributes<HTMLElement>) => (
  <Button type="link" className={className} {...props}>
    set as default
  </Button>
);

DefaultButton = styled(DefaultButton)`
  ::before {
    background: transparent;
  }
  color: white !important;
`;

let DeleteButton = ({
  className,
  ...props
}: ButtonProps & React.RefAttributes<HTMLElement>) => (
  <Button
    shape="circle"
    icon={<CloseOutlined />}
    className={className}
    {...props}
  ></Button>
);

DeleteButton = styled(DeleteButton)`
  background: transparent !important;
  border-color: white !important;
  font-size: 19px;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    line-height: 0;
  }
`;

const Source = ({
  className,
  last4,
  exp_month,
  exp_year,
  is_default = false,
  id,
}: { className?: string } & SourceInterface) => {
  const dispatch = useDispatch<any>();
  const [defaultButtonLoading, setDefaultButtonLoading] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

  const handleClickDefaultButton = () => {
    setDefaultButtonLoading(true);
    dispatch(setDefaultSource(id)).then(() => setDefaultButtonLoading(false));
  };

  const handleClickDeleteButton = () => {
    setDeleteButtonLoading(true);
    dispatch(destroySource(id));
  };

  return (
    <Col className={className} span="24">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <H2 color="white">**** **** **** {last4}</H2>
            </Col>
            {!is_default && (
              <DeleteButton
                loading={deleteButtonLoading}
                onClick={handleClickDeleteButton}
              />
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row align="bottom" justify="space-between">
            <Col>
              <Row>
                <Body2 color="white">Expires</Body2>
              </Row>
              <Row>
                <Body1 color="white">
                  {exp_month}/{exp_year}
                </Body1>
              </Row>
            </Col>
            {is_default && <DefaultLabel />}
            {!is_default && (
              <DefaultButton
                loading={defaultButtonLoading}
                onClick={handleClickDefaultButton}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default styled(Source)`
  margin-top: ${guidelines.margin[2]}px;
  max-width: 310px;
  color: white;
  padding: ${guidelines.padding[2]}px;
  border-radius: ${guidelines.borderRadius[0]}px;
  background: ${guidelines.colors.red[0]};
`;
