import { Col, ColProps, Form, Input, Row, Select, Spin } from "antd";
import React, { useState } from "react";
import { ReactComponent as PlayIcon } from "../../icons/play.svg";
import { ReactComponent as PauseIcon } from "../../icons/pause.svg";
import { NamePath } from "rc-field-form/lib/interface";
import { capitalize, get, set } from "lodash";
import { useSelector } from "react-redux";
import { StorageReducerInterface, VoipPromptsInterface } from "../../utils";
import { Body2 } from "../typography";
import styled from "styled-components";
import Phone from "../inputs/Phone";
import { isValidPhoneNumber } from "libphonenumber-js";

let Action = ({
  parentName,
  menu,
  name,
  fieldKey,
  connectTo,
  fetching,
  promptOptions,
  toField,
  ...props
}: {
  menu?: boolean;
  name: NamePath;
  fetching: {
    prompts: boolean;
  };
  connectTo: JSX.Element[];
  promptOptions: VoipPromptsInterface[];
  parentName?: NamePath;
  fieldKey?: React.Key | React.Key[];
  toField?: Pick<ColProps, "xs" | "md">;
} & ColProps &
  React.RefAttributes<HTMLDivElement>) => {
  const { Item } = Form;
  const { Option } = Select;
  let fullName: (string | number)[];
  const { users } = useSelector((state: StorageReducerInterface) => state.voip);

  const [promptPlaying, setPromptPlaying] = useState<string>();

  const [audio] = useState(new Audio());

  if (parentName) {
    let tempParentName = Array.isArray(parentName) ? parentName : [parentName];
    let tempName = Array.isArray(name) ? name : [name];

    fullName = [...tempParentName, ...tempName];
  } else {
    fullName = Array.isArray(name) ? name : [name];
  }

  return (
    <>
      <Col hidden>
        <Item
          name={[...(Array.isArray(name) ? name : [name]), "action"]}
          fieldKey={
            fieldKey !== undefined
              ? [...(Array.isArray(fieldKey) ? fieldKey : [fieldKey]), "action"]
              : undefined
          }
          hidden
          shouldUpdate={(prevValues, curValues) => {
            if (
              (menu && parentName
                ? get(prevValues, parentName)?.length ===
                  get(curValues, parentName)?.length
                : true) &&
              get(prevValues, fullName)?.to !== get(curValues, fullName)?.to &&
              get(curValues, fullName)
            ) {
              if (get(curValues, fullName)?.to === "prompt") {
                set(curValues, [...fullName, "action"], "play");
              } else {
                set(curValues, [...fullName, "action"], "call");
              }
              return true;
            }
            return false;
          }}
        >
          <Input />
        </Item>
      </Col>
      <Col xs={toField?.xs} md={toField?.md}>
        <div className="input">
          <Item
            name={[...(Array.isArray(name) ? name : [name]), "to"]}
            fieldKey={
              fieldKey !== undefined
                ? [...(Array.isArray(fieldKey) ? fieldKey : [fieldKey]), "to"]
                : undefined
            }
            rules={[
              {
                required: true,
                message: "Please select a value!",
              },
            ]}
          >
            <Select bordered={false} placeholder="Connect to">
              {connectTo}
            </Select>
          </Item>
        </div>
      </Col>
      <Item
        noStyle
        shouldUpdate={(prevValues, curValues, ...args) => {
          if (
            (menu && parentName
              ? get(prevValues, parentName)?.length ===
                get(curValues, parentName)?.length
              : true) &&
            get(prevValues, fullName)?.to !== get(curValues, fullName)?.to &&
            get(curValues, fullName)
          ) {
            set(curValues, [...fullName, "id"], null);
            return true;
          }
          return false;
        }}
      >
        {({ getFieldValue }) => {
          return (
            <Col flex="1">
              {(getFieldValue([...fullName, "to"]) === "user" && (
                <div className="input">
                  <Item
                    name={[...(Array.isArray(name) ? name : [name]), "id"]}
                    fieldKey={
                      fieldKey !== undefined
                        ? [
                            ...(Array.isArray(fieldKey)
                              ? fieldKey
                              : [fieldKey]),
                            "id",
                          ]
                        : undefined
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please select a User!",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      showSearch
                      optionFilterProp="children"
                      placeholder="Name"
                      options={users}
                    ></Select>
                  </Item>
                </div>
              )) ||
                (getFieldValue([...fullName, "to"]) === "other" && (
                  <div className="input">
                    <Item
                      name={[...(Array.isArray(name) ? name : [name]), "id"]}
                      fieldKey={
                        fieldKey !== undefined
                          ? [
                              ...(Array.isArray(fieldKey)
                                ? fieldKey
                                : [fieldKey]),
                              "id",
                            ]
                          : undefined
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please input a Number!",
                        },
                        {
                          validator: (_, value) => {
                            return isValidPhoneNumber(value)
                              ? Promise.resolve()
                              : Promise.reject(
                                  "Please input a valid phone number."
                                );
                          },
                        },
                        {
                          max: 15,
                          transform: (value) => String(parseInt(value)),
                          message: "Please input a maximum of 15 characters!",
                        },
                      ]}
                    >
                      <Phone style={{ columnGap: 5 }} />
                      {/* <Input bordered={false} prefix="+" placeholder="Number" /> */}
                    </Item>
                  </div>
                )) ||
                (getFieldValue([...fullName, "to"]) === "prompt" && (
                  <div className="input">
                    <Item
                      name={[...(Array.isArray(name) ? name : [name]), "id"]}
                      fieldKey={
                        fieldKey !== undefined
                          ? [
                              ...(Array.isArray(fieldKey)
                                ? fieldKey
                                : [fieldKey]),
                              "id",
                            ]
                          : undefined
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select a Message!",
                        },
                      ]}
                    >
                      <Select
                        bordered={false}
                        notFoundContent={
                          fetching.prompts ? <Spin size="small" /> : null
                        }
                        showSearch
                        optionFilterProp="children"
                        placeholder="Name"
                      >
                        {!fetching.prompts &&
                          promptOptions &&
                          promptOptions.map((prompt) => {
                            return (
                              <Option
                                key={prompt.record_id}
                                value={prompt.value}
                              >
                                <Row
                                  gutter={[5, 0]}
                                  wrap={false}
                                  align="middle"
                                  onClick={() => {
                                    setPromptPlaying("");
                                    audio.pause();
                                    audio.currentTime = 0;
                                  }}
                                >
                                  <Col flex="auto">
                                    <Row>
                                      <Body2
                                        marginTop="0"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {capitalize(prompt.label)}
                                      </Body2>
                                    </Row>
                                  </Col>
                                  <Col
                                    className="play"
                                    style={{
                                      cursor: "pointer",
                                      alignSelf: "center",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        !audio.paused &&
                                        promptPlaying === prompt.label
                                      ) {
                                        setPromptPlaying("");
                                        audio.pause();
                                        audio.currentTime = 0;
                                      } else {
                                        setPromptPlaying(prompt.label);
                                        audio.src = prompt.url;
                                        audio.play();
                                        audio.onended = () => {
                                          setPromptPlaying("");
                                        };
                                      }
                                    }}
                                  >
                                    <Row align="middle">
                                      {!audio.paused &&
                                      promptPlaying === prompt.label ? (
                                        <PauseIcon style={{ width: 12.5 }} />
                                      ) : (
                                        <PlayIcon style={{ width: 12.5 }} />
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                              </Option>
                            );
                          })}
                      </Select>
                    </Item>
                  </div>
                ))}
            </Col>
          );
        }}
      </Item>
    </>
  );
};

Action = styled(Action)``;

export default Action;
