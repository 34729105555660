import { Col, ColProps, Divider, Form, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  responsive,
  StorageReducerInterface,
  VoipPromptsInterface,
} from "../../utils";
import Action from "./Action";
import { NamePath } from "antd/lib/form/interface";
import { range } from "lodash";
import { FormListFieldData } from "antd/lib/form/FormList";
import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import SelectV2 from "../inputs/Select";

let Digit = ({
  parentName,
  fetching,
  connectTo,
  promptOptions,
  field,
  fields,
  remove,
  ...props
}: {
  parentName: NamePath;
  fetching: {
    prompts: boolean;
  };
  connectTo: JSX.Element[];
  promptOptions: VoipPromptsInterface[];
  field: FormListFieldData;
  fields: FormListFieldData[];
  remove: (key: number) => void;
} & ColProps &
  React.RefAttributes<HTMLDivElement>) => {
  const windowSize = useSelector(
    (state: StorageReducerInterface) => state.windowSize
  );

  const { Item } = Form;

  return (
    <Col span={24} {...props}>
      <Row
        gutter={[10, 0]}
        align="top"
        wrap={responsive({ xs: true, md: false }, windowSize.width)}
      >
        <Col flex="1">
          <Row
            gutter={[10, 0]}
            wrap={responsive({ xs: true, md: false }, windowSize.width)}
            align="middle"
            justify="center"
          >
            <Col xs={{ span: 5 }} md={{ span: 4 }}>
              <Row align="middle" justify="center">
                <Item
                  name={[field.name, "key"]}
                  fieldKey={[field.key, "key"]}
                  style={{ marginBottom: 0 }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let reps = getFieldValue(parentName)?.filter(
                          (digit: { key: number }) => digit?.key === value
                        ).length;

                        if (reps === 1) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The digit has already been used!"
                        );
                      },
                    }),
                    {
                      required: true,
                      message: "Please select a Digit!",
                    },
                  ]}
                >
                  <SelectV2
                    placeholder="?"
                    placement="bottom"
                    style={{
                      width: 50,
                      height: 50,
                      backgroundColor: "#FAFAFF",
                      border: "1px solid #AAA4D0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginTop: 10,
                      borderRadius: 10,
                      alignSelf: "center",
                      fontSize: 16,
                      marginBottom: 10,
                    }}
                    options={range(10).map((digit) => ({
                      value: `digit_${digit}`,
                      label: digit,
                    }))}
                  />
                  {/* <div
                    style={{
                      width: 50,
                      height: 50,
                      backgroundColor: "#FAFAFF",
                      border: "1px solid #AAA4D0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginTop: 10,
                      borderRadius: 10,
                      alignSelf: "center",
                      fontSize: 16,
                      marginBottom: 10,
                    }}
                  >
                    {Math.round(Math.random() * 10)}
                  </div> */}
                  {/* <Select
                    bordered={false}
                    placeholder="Digit"
                    // options={range(10).map((value) => ({
                    //   value: `digit_${value}`,
                    //   label: `${value} }`,
                    // }))}
                  >
                    {range(10).map((value) => (
                      <Select.Option value={`digit_${value}`}>
                        <span style={{ fontWeight: 500 }}>{`${value} )`}</span>
                      </Select.Option>
                    ))}
                  </Select> */}
                </Item>
              </Row>
            </Col>
            <Action
              menu={true}
              promptOptions={promptOptions}
              connectTo={connectTo}
              fetching={fetching}
              parentName={parentName}
              name={field.name}
              fieldKey={field.key}
              toField={{
                xs: { span: 19 },
                md: { span: 10 },
              }}
            />
          </Row>
        </Col>
        {fields.length > 1 && (
          <Col className="remove">
            <DeleteIcon
              style={{ width: 18, height: 18, cursor: "pointer" }}
              onClick={() => {
                remove(field.name);
              }}
            />
          </Col>
        )}
      </Row>
      <Divider style={{ margin: "0 0 24px 0" }} />
    </Col>
  );
};

Digit = styled(Digit)`
  .remove {
    margin-bottom: 10px;
    align-self: center;
  }
`;

export default Digit;
