import {
  formatError,
  FormModalReducerInterface,
  StorageReducerInterface,
  VoipInterface,
} from "../../utils";
import {
  activateFormModal,
  updateFormModal,
  deactivateFormModal,
} from "./formModalActions";
import { message } from "antd";
import { isEmpty } from "lodash";
import { createRef } from "react";
import { AxiosStatic } from "axios";
import { Dispatch } from "redux";
import { FormInstance } from "antd/lib/form";
import {
  SelectInputInterface,
  InputInterface,
} from "../../components/form/Form";
import { isValidPhoneNumber } from "libphonenumber-js";

export const storeVoip = ({
  user_phone,
  phnum,
}: {
  user_phone: string;
  phnum: string;
}) => {
  return (
    dispatch: Dispatch<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.post(
      `${apiClient}/companies/${default_company?.company.id}/voip`,
      {
        user_phone,
        phnum,
      }
    ).then(({ data }) => {
      dispatch(setVoip(data));
    });
  };
};

export const getVoip = () => {
  return (
    dispatch: Dispatch<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.get(
      `${apiClient}/companies/${default_company?.company.id}/voip`,
      {
        headers: {},
      }
    ).then(({ data }) => dispatch(setVoip(data)));
  };
};

export const updateVoip = (data: Partial<VoipInterface>) => {
  return (
    dispatch: Dispatch<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.put(
      `${apiClient}/companies/${default_company?.company.id}/voip`,
      data,
      {
        headers: {},
      }
    ).then(({ data }) => dispatch(setVoip(data)));
  };
};

export const setVoip = ({
  phone_number,
  balance,
  voice_app,
  users,
  prompts,
}: VoipInterface) => {
  return (dispatch: Dispatch<any>) => {
    return new Promise(async (resolve) => {
      await dispatch({
        type: "SET_VOIP",
        payload: {
          phone_number,
          balance,
          voice_app,
          users,
          prompts,
        },
      });
      resolve(0);
    });
  };
};

export const voipSetup = () => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      formModal: { activeModal: formModalActive },
      auth: { token, default_company },
      voip,
    }: StorageReducerInterface = getState();

    if (
      ["1", "true"].includes(
        String(default_company?.company.active_plan?.metadata?.voip)
      ) &&
      !formModalActive &&
      isEmpty(voip)
    ) {
      Axios.get(`${apiClient}/number-stock-summary`, {
        headers: {},
      }).then(({ data }) => {
        const formRef = createRef<FormInstance>();
        if (token && !formModalActive && isEmpty(voip)) {
          let params: FormModalReducerInterface = {
            formRef: formRef,
            title: "Information",
            description:
              "Please fill in the following information to activate your Phone:",
            closeable: true,
            items: [
              {
                type: "subtitle",
                data: "Phone",
              },
              {
                name: "state",
                placeholder: "State",
                type: "select",
                data: data.map((state: { name: string }) => ({
                  value: state.name,
                })),
                rules: [
                  {
                    required: true,
                    message: "Please input a State!",
                  },
                ],
              },
              {
                name: "city",
                placeholder: "City",
                type: "select",
                data: [],
                rules: [
                  {
                    required: true,
                    message: "Please input a City!",
                  },
                ],
              },
              {
                name: "phnum",
                placeholder: "Phone number",
                type: "select",
                data: [],
                rules: [
                  {
                    required: true,
                    message: "Please input a Phone Number!",
                  },
                ],
              },
              {
                type: "subtitle",
                data: "Personal information",
                marginBottom: 0,
              },
              {
                type: "paragraph",
                data: "Please enter your phone number to redirect incoming calls.",
              },
              {
                type: "phone",
                name: "phone_number",
                // label: "Phone number",
                placeholder: "Phone number",
                xs: {
                  span: 24,
                },
                // md: {
                //   span: 18,
                // },
                // span: responsive({ xs: 16, md: 19 }, width),
                // offset: 1,
                rules: [
                  {
                    validator: (_, value) => {
                      return isValidPhoneNumber(value)
                        ? Promise.resolve()
                        : Promise.reject("Please input a valid phone number.");
                    },
                  },
                  {
                    transform: (value) => String(parseInt(value)),
                    max: 15,
                    message: "Please input a maximum of 15 characters!",
                  },
                  {
                    required: true,
                    message: "Please input your Phone Number!",
                  },
                ],
              },
              {
                name: "user_phone",
                hidden: true,
              },
            ],
          };

          dispatch(
            activateFormModal({
              ...params,
              onValuesChange: (changedValues, allValues) => {
                if (changedValues.phone_number && allValues.phone_number) {
                  formRef?.current?.setFieldsValue({
                    user_phone: allValues.phone_number,
                  });
                } else if (changedValues.state) {
                  params.items = params.items?.map((item) => {
                    return (item as SelectInputInterface).name === "city"
                      ? ({
                          ...item,
                          data: (
                            data.filter(
                              (state: { name: string }) =>
                                state.name === changedValues.state
                            )[0]?.cities as {
                              name: string;
                              area_code: string;
                            }[]
                          ).map((city, index) => ({
                            value: index,
                            label: `${city.name} (${city.area_code})`,
                          })),
                        } as SelectInputInterface)
                      : (item as SelectInputInterface).name === "phnum"
                      ? {
                          ...item,
                          fetching: false,
                          data: [],
                        }
                      : item;
                  });

                  dispatch(
                    updateFormModal({
                      items: params.items,
                    })
                  );

                  formRef?.current?.setFieldsValue({
                    city: null,
                    phnum: null,
                  });
                } else if (changedValues.city >= 0) {
                  params.items = params.items?.map((input) => {
                    return (input as InputInterface).name === "phnum"
                      ? {
                          ...input,
                          fetching: true,
                          data: [],
                        }
                      : input;
                  });

                  dispatch(
                    updateFormModal({
                      items: params.items,
                    })
                  );

                  formRef?.current?.setFieldsValue({
                    phnum: null,
                  });

                  let city = data.filter(
                    (state: { name: string }) => state.name === allValues.state
                  )[0]?.cities[changedValues.city];
                  Axios.get(
                    `${apiClient}/available-phone-numbers?city=${city.name}&area_code=${city.area_code}`,
                    {
                      headers: {},
                    }
                  ).then(({ data: phoneNumbers }) => {
                    params.items = params.items?.map((input) => {
                      return (input as InputInterface).name === "phnum" &&
                        (input as SelectInputInterface).fetching
                        ? {
                            ...input,
                            fetching: false,
                            data: phoneNumbers.map(
                              (phoneNumber: { phnum: string }) => ({
                                value: phoneNumber.phnum,
                                label: `+${phoneNumber.phnum.slice(
                                  0,
                                  1
                                )} (${phoneNumber.phnum.slice(
                                  1,
                                  4
                                )}) ${phoneNumber.phnum.slice(
                                  4,
                                  7
                                )}-${phoneNumber.phnum.slice(7, 11)}`,
                              })
                            ),
                          }
                        : input;
                    });
                    dispatch(
                      updateFormModal({
                        items: params.items,
                      })
                    );
                  });
                }
              },
              primaryAction: (data: { user_phone: string; phnum: string }) => {
                dispatch(
                  updateFormModal({
                    alert: null,
                    loadingPrimaryButton: true,
                  })
                );
                dispatch(storeVoip(data))
                  .then(() => {
                    dispatch(
                      updateFormModal({
                        loadingPrimaryButton: false,
                      })
                    );
                    dispatch(deactivateFormModal()).then(() =>
                      message.success("VoIp was successfully activated.")
                    );
                  })
                  .catch((e) => {
                    dispatch(
                      updateFormModal({
                        loadingPrimaryButton: false,
                        alert: { ...formatError(e), type: "warning" },
                      })
                    );
                  });
              },
            })
          );
        }
      });
    }
  };
};

export const resetVoip = () => {
  return (dispatch: Dispatch<any>) => {
    return dispatch({
      type: "RESET_VOIP",
    });
  };
};
