import React, { Component } from "react";
import { Row, Col } from "antd";
import {
  PageDescription,
  TabMenu,
  LeftMenu,
  Cards,
  CardHorizontal,
} from "../../components";
import { guidelines, responsive } from "../../utils";
import { connect } from "react-redux";

class ServicesShop extends Component {
  state = {
    cards: [
      {
        title: "4",
        subtitle: "Mail Scan",
        suffix: "growcredits",
        abstract: ["Get your information on time"],
      },
      {
        title: "4",
        subtitle: "B/W Printing",
        suffix: "growcredits",
      },
    ],
  };
  render() {
    const { cards } = this.state;
    const {
      windowSize: { width },
    } = this.props;
    const leftContentFlex = responsive({ xs: 1, md: "0" }, width);
    const rightContentStyle = responsive(
      {
        xs: {},
        md: {
          paddingTop: guidelines.headerPadding + "px",
          height: "100vh",
          overflow: "scroll",
        },
      },
      width
    );

    return (
      <>
        <Row style={{ height: "100%", overflow: "hidden" }} justify="center">
          <Col span={responsive({ xs: 20 }, width)}>
            <Row
              justify="start"
              gutter={[guidelines.spacing[0], guidelines.spacing[0]]}
              style={{ margin: "0 -10px" }}
            >
              <Col
                style={{ paddingTop: guidelines.headerPadding + "px" }}
                flex={leftContentFlex}
              >
                <LeftMenu
                  title="Services"
                  items={["Shop", "Active"]}
                ></LeftMenu>
              </Col>
              <Col style={rightContentStyle} flex="1">
                <PageDescription
                  title="Shop"
                  description="Tailored services redeemable by credits."
                ></PageDescription>
                <TabMenu items={["Virtual office", "Meeting rooms"]} />
                <Cards cards={cards}>
                  <CardHorizontal
                    title="4"
                    subtitle="Mail Scan"
                    suffix="growcredits"
                    description={["Get your information on time"]}
                  />
                  <CardHorizontal
                    title="4"
                    suffix="growcredits"
                    subtitle="B/W Printing"
                  />
                </Cards>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  windowSize: state.windowSize,
});

export default connect(mapStateToProps)(ServicesShop);
