import { Col, InputNumber, Row, RowProps, Select, Input } from "antd";
import React, { forwardRef, Ref, useEffect, useState } from "react";
import styled from "styled-components";
import examples from "libphonenumber-js/examples.mobile.json";
import {
  getExampleNumber,
  getCountryCallingCode,
  CountryCode,
  AsYouType,
  getCountries,
  validatePhoneNumberLength,
  formatIncompletePhoneNumber,
} from "libphonenumber-js";
import { valueType } from "antd/lib/statistic/utils";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Phone = forwardRef(
  (
    {
      name,
      value,
      onChange,
      style,
      ...props
    }: RowProps &
      React.RefAttributes<HTMLDivElement> & {
        name?: any;
        value?: valueType;
        onChange?: (value?: valueType) => void;
      },
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    const [countryCode, setCountryCode] = useState<CountryCode>();
    const [valid, setValid] = useState<valueType>();
    const [formatted, setFormatted] = useState<valueType | undefined>(value);

    const updateFormatted = (value?: valueType) => {
      const asYouType = new AsYouType(countryCode);

      asYouType.input(String(value));

      const country = countryCode || asYouType.getCountry();

      if (!countryCode) setCountryCode(country || "US");
      if (!valid) setValid(value);

      setFormatted(
        formatIncompletePhoneNumber(asYouType.getNationalNumber(), country)
      );
    };

    useEffect(() => {
      updateFormatted(value);
      // eslint-disable-next-line
    }, [value]);

    const handleValueChange = (value?: valueType, country?: CountryCode) => {
      const asYouType = new AsYouType(country || countryCode);

      asYouType.input(String(value));

      const number = asYouType.getNumberValue() || "";

      setValid(number);

      if (onChange) {
        onChange?.(number);
      } else {
        updateFormatted(number);
      }
    };

    return (
      <Input.Group {...props}>
        <Row align="middle" wrap={false} style={style} ref={ref}>
          <Select
            bordered={false}
            dropdownMatchSelectWidth={false}
            optionLabelProp="label"
            showSearch
            optionFilterProp="data-country-name"
            value={countryCode}
            onChange={(value) => {
              setCountryCode(value as CountryCode);

              const asYouType = new AsYouType(countryCode);

              asYouType.input(String(valid));

              const number = asYouType.getNationalNumber() || "";

              handleValueChange(number, value);
            }}
            defaultValue={countryCode}
          >
            {getCountries().map((country, index) => {
              const value = getCountryCallingCode(country);

              const flag = (
                <span
                  style={{ width: 19 }}
                  className={`fi fi-${country.toLowerCase()}`}
                />
              );

              const name = countries.getName(country, "en");

              return (
                <Select.Option
                  key={index}
                  value={country}
                  data-country-name={name}
                  label={flag}
                >
                  {flag} {name} <span style={{ color: "grey" }}>+{value}</span>
                </Select.Option>
              );
            })}
          </Select>
          <Col span={"auto"}>
            <InputNumber
              prefix={"+" + getCountryCallingCode(countryCode || "US")}
              style={{ width: "100%" }}
              controls={false}
              placeholder={getExampleNumber(
                countryCode || "US",
                examples
              )?.formatNational()}
              name={name}
              maxLength={
                valid &&
                validatePhoneNumberLength(valid + "1", countryCode) ===
                  "TOO_LONG"
                  ? String(formatted).length
                  : undefined
              }
              value={formatted}
              onChange={(value) => handleValueChange(value as string)}
              onBlur={(element) => {
                const value = element.currentTarget.value;
                handleValueChange(value);
              }}
              //   formatter={(value) =>
              //     new AsYouType(countryCode).input(value as string)
              //   }
              parser={(value) => {
                return value?.replace(/\D/g, "") as valueType;
              }}
              type="tel"
              bordered={false}
            />
          </Col>
        </Row>
      </Input.Group>
    );
  }
);

export default styled(Phone)`
  .ant-input-number-affix-wrapper {
    padding-left: 0;
  }
`;
