import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { Menu, H2 } from "../../components";
import { guidelines, HeaderReducerInterface } from "../../utils";
import styled from "styled-components";
import { connect } from "react-redux";
import { updateHeaderData } from "../../store/actions";

let Footer = ({ className }: { className?: string }) => (
  <Row justify="center" className={className}>
    <Col span={18}>
      <H2 fontWeight={500} marginTop="0">
        The next <span>big thing starts here</span>
      </H2>
    </Col>
  </Row>
);

Footer = styled(Footer)`
  padding: ${guidelines.padding[4]}px;
  h2 {
    text-align: center;
    span {
      font-weight: 400;
    }
  }
`;

const Home = (props: {
  updateHeaderData: (data: HeaderReducerInterface) => Promise<any>;
}) => {
  useEffect(() => {
    const { updateHeaderData } = props;
    updateHeaderData({
      mode: "dark",
      backButton: {
        active: false,
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Menu />
          <Footer />
        </Col>
      </Row>
    </>
  );
};

const mapDispatchToProps = (dispatch: (dispatch: any) => Promise<any>) => ({
  updateHeaderData: (data: HeaderReducerInterface) =>
    dispatch(updateHeaderData(data)),
});

export default connect(null, mapDispatchToProps)(Home);
