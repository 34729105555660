import React, { useState } from "react";
import { Row, Col } from "antd";
import { TabMenu, LeftMenu, Search } from "../../components";
import { guidelines, responsive } from "../../utils";
import styled from "styled-components";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { PageDescription, PageDescriptionInterface } from "../page-description";
import { RowProps } from "antd/lib";

const Container = styled(({ className, children, ...props }: RowProps) => (
  <Row className={className} {...props}>
    {children}
  </Row>
))`
  > .ant-col {
    max-width: 1240px;
  }
`;

function TwoColumns({
  tabMenu,
  leftMenu,
  className,
  pageDescription,
  windowSize: { width },
  children,
  onSearchChange,
  onSearchInput,
  rightColumn,
  leftColumn,
}: {
  className?: string;
  windowSize: { width: number };
  pageDescription?: PageDescriptionInterface;
  leftMenu?: {
    title: string;
    items: string[];
    onLeftMenuChange: (section: number) => void;
  };
  tabMenu?: {
    items: string[];
    onLeftMenuChange: (subsection: number) => void;
  };
  onSearchChange?: (value: string) => void;
  onSearchInput?: (value: string) => void;
  children?: React.ReactNode;
  rightColumn?: {
    style?: React.CSSProperties;
  };
  leftColumn?: {
    style?: React.CSSProperties;
    content?: React.ReactNode;
  };
}) {
  const [state, setState] = useState<any>({
    activeSection: 0,
    activeSubsection: 0,
    search: "",
    leftMenuItems: [],
    tabMenuItems: [],
  });

  const handleLeftMenuChange = (section: number) => {
    setState({
      ...state,
      activeSection: section,
    });

    leftMenu?.onLeftMenuChange(section);
  };

  const handleTabMenuChange = (subsection: number) => {
    setState({
      ...state,
      activeSubsection: subsection,
    });

    tabMenu?.onLeftMenuChange(subsection);
  };

  const { activeSection } = state;
  const leftColumnFlex = responsive({ xs: 1, md: "0" }, width);
  const rightColumnInitialStyle: any | undefined = responsive(
    {
      xs: {
        width: "100%",
        paddingBottom: 150,
      },
      md: {
        paddingTop: guidelines.headerPadding + "px",
        height: "100vh",
        overflow: "scroll",
        flex: 1,
        paddingBottom: 150,
      },
    },
    width
  );

  return (
    <>
      <Container className={className} justify="center">
        <Col span={responsive({ xs: 21 }, width)}>
          <Row
            justify="start"
            gutter={[guidelines.spacing[0], guidelines.spacing[0]]}
            // style={{ margin: "0 -10px" }}
          >
            <Col
              className="leftColumn"
              style={leftColumn?.style}
              flex={leftColumnFlex}
            >
              {leftMenu && !isEmpty(leftMenu?.items) && (
                <LeftMenu
                  title={leftMenu.title}
                  items={leftMenu.items}
                  onChange={handleLeftMenuChange}
                  activeItem={activeSection}
                ></LeftMenu>
              )}
              {leftColumn?.content}
            </Col>
            <Col style={{ ...rightColumnInitialStyle, ...rightColumn?.style }}>
              {pageDescription && (
                <PageDescription {...pageDescription}></PageDescription>
              )}
              <Row>
                <Col flex="1">
                  {!isEmpty(tabMenu?.items) && (
                    <TabMenu
                      onChange={handleTabMenuChange}
                      items={tabMenu?.items}
                    />
                  )}
                </Col>
                {(onSearchInput || onSearchChange) && (
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Search
                      onInput={onSearchInput && onSearchInput}
                      onChange={onSearchChange && onSearchChange}
                      style={{ marginTop: guidelines.margin[4] }}
                    />
                  </Col>
                )}
              </Row>
              {children}
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  windowSize: state.windowSize,
  auth: state.auth,
});

export default connect(mapStateToProps)(styled(TwoColumns)`
  .leftColumn {
    padding-top: ${guidelines.headerPadding + "px"} !important;
    /* min-width: 220px; */
  }
`);
