import { Dispatch } from "redux";
import {
  FormModalReducerInterface,
  StorageReducerInterface,
} from "../../utils";
import { updateHeaderData } from "./headerActions";

export const activateFormModal = ({
  closeable = true,
  form,
  formRef,
  title,
  description,
  initialValues,
  items,
  alert,
  requiredDefaultCard,
  loadingPrimaryButton,
  loadingSecondaryButton,
  price,
  onFieldsChange,
  onValuesChange,
  primaryAction,
  secondaryAction,
}: FormModalReducerInterface) => {
  return (dispatch: Dispatch, getState: () => StorageReducerInterface) => {
    const {
      header: { mode, backButton },
    }: StorageReducerInterface = getState();
    return new Promise((resolve) => {
      dispatch({
        type: "ACTIVATE_FORM_MODAL",
        payload: {
          form,
          formRef,
          closeable,
          title,
          description,
          initialValues,
          items,
          alert,
          requiredDefaultCard,
          loadingPrimaryButton,
          loadingSecondaryButton,
          price,
          onFieldsChange,
          onValuesChange,
          primaryAction,
          secondaryAction,
          prevHeader: {
            mode,
            backButton,
          },
        },
      });
      resolve(null);
    });
  };
};

export const updateFormModal = (data: FormModalReducerInterface) => {
  return (dispatch: Dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "UPDATE_FORM_MODAL",
        payload: data,
      });
      resolve(null);
    });
};

export const deactivateFormModal = () => {
  return (dispatch: any, getState: () => any) => {
    const { formModal }: StorageReducerInterface = getState();
    return new Promise((resolve) => {
      dispatch({
        type: "DEACTIVATE_FORM_MODAL",
      });
      formModal.prevHeader && dispatch(updateHeaderData(formModal.prevHeader));
      resolve(null);
    });
  };
};
