import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import {
  apiAdmin,
  guidelines,
  MailItemInterface,
  responsive,
  useShowError,
} from "../../utils";
import { startCase, upperFirst } from "lodash";
import { Body1, Body2 } from "../typography";
import { RowProps } from "antd/lib/row";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  activateFormModal,
  deactivateFormModal,
  updateFormModal,
} from "../../store/actions";
import Axios from "axios";
import AdminActionsButton from "./AdminActionsButton";
import UserActionsButton from "./UserActionsButton";
import { ColSize } from "antd/es/grid";

let Label = styled(
  ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => (
    <Col className={className} xs={{ span: 8 }} lg={{ span: 0 }}>
      <Body2 textAlign="right" marginTop="0">
        {children}
      </Body2>
    </Col>
  )
)`
  margin-bottom: 10px;
  p {
    padding: 5px 0;
    background-color: ${guidelines.colors.blue[4]};
    color: ${guidelines.colors.grey[0]};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    padding-right: 10px;
  }
`;

const Data = styled(
  ({
    lgSpan,
    children,
    className,
    style,
  }: React.HTMLAttributes<HTMLDivElement> & { lgSpan: ColSize["span"] }) => (
    <Col
      className={className}
      style={style}
      xs={{ offset: 1, span: 14 }}
      lg={{ offset: 0, span: lgSpan }}
    >
      {children}
    </Col>
  )
)`
  padding-right: 10px;
  padding-top: 5px;
  margin-bottom: ${() => responsive({ xs: 10, lg: null }, window.innerWidth)}px;
`;

const MailItem = ({
  className,
  data,
  data: {
    id,
    date,
    created_at,
    from,
    type,
    location,
    weight,
    dimensions,
    company_id,
    member,
    statuses,
    available,
  },
  userActions,
  adminActions,
  onAdminActionsFinished,
  deleteAction,
  onDeleteActionFinished,
}: {
  data: MailItemInterface;
  userActions?: boolean;
  adminActions?: boolean;
  onAdminActionsFinished?: () => void;
  deleteAction?: boolean;
  onDeleteActionFinished?: () => void;
} & RowProps &
  React.RefAttributes<HTMLDivElement>) => {
  const dispatch = useDispatch<any>();
  const showError = useShowError();

  const deleteEmail = (id: number) => {
    dispatch(
      activateFormModal({
        title: "Delete email",
        description: "Are you sure you want to delete this email?",
        primaryAction: () => {
          dispatch(updateFormModal({ loadingPrimaryButton: true }));
          Axios.delete(`${apiAdmin}/mail-items/${id}`)
            .then(async () => {
              if (onDeleteActionFinished) {
                await onDeleteActionFinished();
              }
              dispatch(deactivateFormModal());
            })
            .catch((err) => showError({ err, updateFormModal, dispatch }));
        },
      })
    );
  };

  return (
    <Row className={className}>
      <Col span={24}>
        <Row className="info" justify="center">
          <Col span={23}>
            <Row>
              <Label>ID</Label>
              <Data
                style={{ color: guidelines.colors.grey[0], paddingTop: 0 }}
                lgSpan={2}
              >
                <Row className="id-container">
                  <div className={`id ${available ? "id-active" : null}`}>
                    {id}
                  </div>
                </Row>
              </Data>
              <Label>Date</Label>
              <Data lgSpan={4}>{moment(date).format("MMM D, YYYY")}</Data>
              <Label>From</Label>
              <Data lgSpan={4}>{upperFirst(from)}</Data>
              <Label>Type</Label>
              <Data lgSpan={4} style={{ paddingTop: 0 }}>
                <Row>
                  <div className="type">{upperFirst(type?.name)}</div>
                </Row>
              </Data>
              <Label>History</Label>
              <Data lgSpan={5}>
                {statuses
                  ?.filter((status) => status.name !== "available")
                  .map((status) => (
                    <Row key={status.id}>
                      <Col span={24}>
                        <Row> {startCase(status.name)}</Row>
                        <Row>
                          <Body2
                            style={{
                              color: guidelines.colors.grey[0],
                            }}
                            marginTop="0"
                          >
                            {status.pivot?.created_at &&
                              moment(status.pivot?.created_at).format(
                                "MMM D, YYYY h a"
                              )}
                          </Body2>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                <Row>
                  <Col span={24}>
                    <Row>Stored - {location}</Row>
                    <Row>
                      <Body2
                        style={{
                          color: guidelines.colors.grey[0],
                        }}
                        marginTop="0"
                      >
                        {moment(created_at).format("MMM D, YYYY h a")}
                      </Body2>
                    </Row>
                  </Col>
                </Row>
              </Data>
              <Label>Description</Label>
              <Data lgSpan={5}>
                {" "}
                {member && (
                  <Row>
                    <Body2 marginTop="0">
                      Member?{" "}
                      <span style={{ color: guidelines.colors.grey[0] }}>
                        {member.user.name}
                      </span>
                    </Body2>
                  </Row>
                )}
                {weight && (
                  <Row>
                    <Body2 marginTop="0">
                      Weight?{" "}
                      <span style={{ color: guidelines.colors.grey[0] }}>
                        {weight} lb
                      </span>
                    </Body2>
                  </Row>
                )}
                {dimensions && (
                  <Row>
                    <Body2 marginTop="0">
                      Dimensions?{" "}
                      <span style={{ color: guidelines.colors.grey[0] }}>
                        {dimensions.width}x{dimensions.length}x
                        {dimensions.height} inches
                      </span>
                    </Body2>
                  </Row>
                )}
              </Data>
            </Row>
          </Col>
        </Row>
        <Row className="actions-container" justify="end" align="middle">
          {userActions && <UserActionsButton data={data} />}
          {Boolean(adminActions) && (
            <AdminActionsButton
              onActionFinished={onAdminActionsFinished}
              data={data}
            />
          )}
          {Boolean(deleteAction) && (
            <Body1
              className="delete-action"
              marginTop="0"
              cursor="pointer"
              onClick={() => deleteEmail(id)}
            >
              Delete
            </Body1>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default styled(MailItem)`
  margin-top: ${guidelines.margin[0]}px;

  .info {
    padding: ${guidelines.padding[1]}px 0;
    border-bottom: 1px solid ${guidelines.colors.grey[2]};
  }

  .actions-container {
    > *:not(:last-child) {
      margin-right: 15px;
    }
    .actions {
      margin-top: ${guidelines.padding[0]}px;
      border-color: ${guidelines.colors.grey[1]};
      border-radius: 100px;
    }
    .delete-action {
      margin-top: ${guidelines.padding[0]}px;
    }
  }

  .id-container {
  }

  .id {
    width: 32px;
    height: 32px;
    background-color: ${guidelines.colors.blue[4]};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .id-active {
    background-color: ${guidelines.colors.green[2]};
    color: ${guidelines.colors.green[0]};
  }

  .type {
    border-radius: 5px;
    padding: 5px 7.5px;
    border: 1px solid ${guidelines.colors.green[1]};
    flex-shrink: 0;
  }
`;
