import { MemberInterface } from "./../../utils/interfaces";
import { AuthReducerInterface } from "../../utils";
import reactQueryClient from "../../reactQueryClient";

const initialState: AuthReducerInterface = {
  token: localStorage.getItem("token"),
  loaded: false,
};

export default function authReducer(
  state = initialState,
  {
    type,
    payload,
  }:
    | { type: "SET_USER_INFO"; payload: AuthReducerInterface }
    | { type: "SET_USER_COMPANIES"; payload: MemberInterface[] }
    | { type: "SET_USER_DEFAULT_COMPANY"; payload: MemberInterface }
    | { type: "LOGOUT"; payload: null }
) {
  switch (type) {
    case "SET_USER_INFO":
      payload = payload as AuthReducerInterface;

      if (payload?.token) {
        localStorage.setItem("token", payload.token);
      }

      return { ...state, ...payload };

    case "SET_USER_COMPANIES":
      payload = payload as MemberInterface[];

      return { ...state, companies: payload };

    case "SET_USER_DEFAULT_COMPANY":
      payload = payload as MemberInterface;

      return { ...state, default_company: payload };

    case "LOGOUT":
      localStorage.removeItem("token");
      reactQueryClient.clear();
      return initialState;

    default:
      return state;
  }
}
