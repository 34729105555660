import React, { useEffect, useState } from "react";
import {
  BallPulse,
  Button1,
  InvitationsTable,
  MembersTable,
} from "../../components";
import {
  apiClient,
  guidelines,
  responsive,
  MemberInterface,
  AuthReducerInterface,
  InvitationInterface,
} from "../../utils";
import { connect } from "react-redux";
import { TwoColumns } from "../../components/layout";
import Axios from "axios";

import useBackToHomeHeader from "../../utils/hooks/useBackToHomeHeader";
import useNewMemberModal from "../../utils/hooks/modals/useNewMemberModal";
import { useQuery } from "@tanstack/react-query";

const Team = (props: {
  windowSize: { width: number };
  auth: AuthReducerInterface;
}) => {
  const { auth } = props;
  const [members, setMembers] = useState<{
    [key: string]: MemberInterface[] | InvitationInterface[] | undefined;
  }>();
  const [newMemberButtonLoading, setNewMemberButtonLoading] = useState(false);
  const [activeSubsection, setActiveSubsection] = useState<number>(0);

  const { default_company } = auth;

  const getMembers = () => {
    return Axios.get(
      `${apiClient}/companies/${auth.default_company?.company_id}/members`
    ).then(({ data }) => data);
  };

  const { data, refetch } = useQuery<{
    [key: string]: MemberInterface[] | InvitationInterface[] | undefined;
  }>({
    queryKey: ["members"],
    queryFn: getMembers,
    enabled: !!default_company,
  });

  useEffect(() => {
    data && setMembers(data);
  }, [data]);

  const memberKeys = members && Object.keys(members as object);

  const memberKeysFiltered = memberKeys?.filter(
    (memberKey) => members?.[memberKey]?.length
  );

  const backToHomeHeader = useBackToHomeHeader();

  useEffect(() => {
    backToHomeHeader();
    // eslint-disable-next-line
  }, []);

  const newMemberModal = useNewMemberModal({
    onMemberCreation: refetch,
    setLoading: setNewMemberButtonLoading,
  });

  const handleTabMenuChange = (subsection: number) => {
    setActiveSubsection(subsection);
  };

  const {
    windowSize: { width },
  } = props;

  const activeSectionName =
    memberKeysFiltered?.[activeSubsection] ?? memberKeysFiltered?.[0];

  if (true) {
    return (
      <>
        <TwoColumns
          tabMenu={
            memberKeysFiltered && {
              onLeftMenuChange: handleTabMenuChange,
              items: memberKeysFiltered,
            }
          }
          pageDescription={{
            title: "Team",
            description: "Roles and permissions of your company members.",
            showPaymentSources: false,
            rightContent: (
              <Button1
                loading={newMemberButtonLoading}
                onClick={newMemberModal}
              >
                Add member
              </Button1>
            ),
          }}
          leftColumn={{
            style: {
              paddingTop: guidelines.headerPadding + "px",
              minHeight: responsive({ md: "450px" }, width),
              minWidth: "220px",
            },
          }}
        >
          {!members ? (
            <BallPulse />
          ) : (
            <>
              {activeSectionName && activeSectionName !== "invited" && (
                <MembersTable
                  onMemberUpdateFinished={refetch}
                  onMemberDeleteFinished={refetch}
                  actions={true}
                  members={members?.[activeSectionName] as MemberInterface[]}
                />
              )}
              {activeSectionName && activeSectionName === "invited" && (
                <InvitationsTable
                  onMemberUpdateFinished={refetch}
                  onMemberDeleteFinished={refetch}
                  actions={true}
                  invitations={
                    members?.[activeSectionName] as InvitationInterface[]
                  }
                />
              )}
            </>
          )}
        </TwoColumns>
      </>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: any) => ({
  windowSize: state.windowSize,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
