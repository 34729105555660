import { CompaniesReducerInterface } from "../../utils";

const initialState: CompaniesReducerInterface = {};

export default function companiesReducer(
  state = initialState,
  {
    type,
    payload,
  }: {
    type: "SET_COMPANIES" | "RESET_COMPANIES";
    payload: CompaniesReducerInterface;
  }
) {
  switch (type) {
    case "SET_COMPANIES":
      return payload;

    case "RESET_COMPANIES":
      return initialState;

    default:
      return state;
  }
}
