import React, { ReactText } from "react";
import styled from "styled-components";
import {
  guidelines,
  responsive,
  WindowSizeReducerInterface,
} from "../../utils";
import { connect } from "react-redux";
import { Dispatch } from "redux";

interface H2Interface
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  className?: string;
  children?: React.ReactNode;
  color?: string;
  marginTop?: number | string | ReactText;
  marginBottom?: number | string | ReactText;
  notera?: boolean;
  varela?: boolean;
  exo?: boolean;
  fontWeight?: number | string;
  textAlign?: string;
  cursor?: string;
  windowSize?: WindowSizeReducerInterface;
  dispatch: Dispatch;
}

const H2 = ({
  className,
  children,
  color,
  marginTop,
  marginBottom,
  notera,
  fontWeight,
  cursor,
  textAlign,
  varela,
  exo,
  windowSize,
  dispatch,
  ...props
}: H2Interface) => {
  return (
    <h2 className={className} {...props}>
      {children}
    </h2>
  );
};

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
}) => ({
  windowSize: state.windowSize,
});

export default connect(mapStateToProps)(styled(H2)`
  color: ${(props) => (props.color ? props.color : guidelines.colors.blue[0])};
  margin-top: ${(props) =>
    props.marginTop ? props.marginTop : guidelines.margin[2]}px;
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : "unset"};
  font-family: ${(props) =>
    props.notera
      ? "Notera"
      : props.varela
      ? "'Varela Round', sans-serif"
      : props.exo
      ? "'Exo', sans-serif"
      : null};
  font-size: ${(props) =>
    responsive(guidelines.fontSize.h2, props.windowSize?.width as number)}px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  cursor: ${(props) => (props.cursor ? props.cursor : null)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : null)};
`);
