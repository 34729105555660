import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Col, FormInstance, Input, InputRef, Row, RowProps } from "antd";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Body2 } from "../typography";
import { guidelines } from "../../utils";
import { CloseOutlined } from "@ant-design/icons";

let Tags = React.forwardRef(
  (
    {
      form,
      placeholder,
      name,
      disabled,
      ...props
    }: {
      form?: FormInstance<any>;
      placeholder?: string;
      name: string | number | InternalNamePath;
      disabled?: boolean;
    } & RowProps &
      React.RefAttributes<HTMLDivElement>,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const [state, setState] = useState(
      (form?.getFieldValue(name) as string[]) || []
    );
    const [value, setValue] = useState<string>();
    const [input, setInput] = useState<boolean>(false);

    const handleInputConfirm = (e: any) => {
      if (e.currentTarget.value) {
        setState([...state, e.currentTarget.value]);
        form?.setFieldsValue({
          [String(name)]: [...state, e.currentTarget.value],
        });
      }
      setValue("");
      setInput(false);
      return null;
    };

    const inputRef = useRef<InputRef>(null);

    return (
      <Row ref={ref} gutter={[5, 5]} {...props}>
        {Array.isArray(state) &&
          state.map((tag: string, index) => (
            <Col key={index}>
              <Row className="tag" align="middle">
                <Body2 marginTop="0">{tag}</Body2>
                {!disabled && (
                  <CloseOutlined
                    onClick={() => {
                      let value = state.filter(
                        (tag, tagIndex) => tagIndex !== index
                      );

                      setState(value);

                      form?.setFieldsValue({
                        [String(name)]: value,
                      });
                    }}
                    className="close"
                  />
                )}
              </Row>
            </Col>
          ))}
        {!disabled && input && (
          <Col>
            <Input
              ref={inputRef}
              value={value}
              className="tag_input"
              prefix="+"
              onChange={(e) => setValue(e.currentTarget.value)}
              placeholder={placeholder}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          </Col>
        )}
        {!disabled && !input && (
          <Col>
            <Row
              className="new_tag"
              align="middle"
              onClick={() => {
                setInput(true);
                setTimeout(() => inputRef.current?.focus(), 100);
              }}
            >
              <Body2 marginTop="0">+ {placeholder}</Body2>
            </Row>
          </Col>
        )}
      </Row>
    );
  }
);

Tags = styled(Tags)`
  padding: 5px 0 !important;
  .tag_input {
    border-radius: 2.5px;
    padding-left: 5px !important;
    height: 26px;
  }

  .new_tag {
    cursor: pointer;
    padding: 1px 7.5px !important;
    border-radius: 2.5px;
    border: 1px dashed ${guidelines.colors.grey[4]};
    p {
      color: ${guidelines.colors.grey[6]};
    }
  }

  .tag {
    padding: 1px 7.5px !important;
    border-radius: 2.5px;
    background-color: ${guidelines.colors.grey[5]};
    border: 1px solid ${guidelines.colors.grey[4]};
    .close {
      margin-left: 2px;
      color: ${guidelines.colors.grey[4]};
    }
    p {
      color: ${guidelines.colors.grey[0]};
    }
  }
`;

export default Tags;
