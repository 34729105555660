import { useDispatch } from "react-redux";
import { updateFormModal } from "../store/actions";

export const defaultErrorMessage =
  "Something went wrong, please try again later or send us an email to support@funthriving.freshdesk.com.";

export const formatError = (err: { response: { data: any } }) => {
  let { response: { data } = {} } = err;

  if (!data?.message) {
    data = {
      message: "Error",
      description: defaultErrorMessage,
    };
  }

  return data;
};

export const useShowError = () => {
  const dispatch = useDispatch<any>();

  const showError = (err: any) => {
    dispatch(
      updateFormModal({
        loadingPrimaryButton: false,
        alert: { ...formatError(err), type: "warning" },
      })
    );
  };

  return showError;
};
