import { useDispatch } from "react-redux";
import {
  cancelSubscription,
  activateFormModal,
  deactivateFormModal,
  updateFormModal,
} from "../../../store/actions";
import {
  ActiveSubscriptionInterface,
  PlanInterface,
  SubscriptionInterface,
} from "../../interfaces";
import { useShowError } from "../../useShowError";
import { message } from "antd";

const useCancelSubscriptionModal = (callback: () => void) => {
  const dispatch = useDispatch<any>();
  const showError = useShowError();

  const cancelSubscriptionModal = ({
    plan,
    isActive,
    subscription,
    activeSubscription,
  }: {
    plan: PlanInterface;
    isActive: boolean;
    subscription: SubscriptionInterface;
    activeSubscription?: ActiveSubscriptionInterface;
  }) =>
    !plan.message &&
    isActive &&
    (() => {
      dispatch(
        activateFormModal({
          title: subscription.name,
          description: (
            <>
              You're about to cancel you{" "}
              <span style={{ fontWeight: 500 }}>{plan.nickname}</span> plan.{" "}
              <i>
                This change will apply at the{" "}
                <span style={{ fontWeight: 500 }}>
                  end of your billing period
                </span>
              </i>
              .
            </>
          ),
          price: plan.amount / 100,
          primaryAction: () => {
            dispatch(
              updateFormModal({
                loadingPrimaryButton: true,
              })
            );
            dispatch(
              cancelSubscription(activeSubscription?.id as string, callback)
            )
              .then(() => {
                dispatch(
                  updateFormModal({
                    loadingPrimaryButton: false,
                  })
                );
                dispatch(deactivateFormModal()).then(() =>
                  message.success(
                    "Process completed successfully. This change will apply at the end of your billing period."
                  )
                );
              })
              .catch(showError);
          },
        })
      );
    });

  return cancelSubscriptionModal;
};

export default useCancelSubscriptionModal;
