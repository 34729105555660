import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { Body1, Body2 } from "../typography";
import { guidelines } from "../../utils";
import { ExclamationOutlined, CloseOutlined } from "@ant-design/icons";
import { ColProps } from "antd/lib";

const getStyle = (type?: "warning" | "info") => {
  switch (type) {
    case "warning":
      return `
                background: ${guidelines.colors.red[2]};
                .icon {
                    background: ${guidelines.colors.red[1]};
                }
            `;
    case "info":
    default:
      return `
                background: ${guidelines.colors.blue[3]};
                .icon {
                    background: ${guidelines.colors.blue[2]};
                }
            `;
  }
};

const getSymbol = (type?: "warning" | "info") => {
  switch (type) {
    case "warning":
      return <CloseOutlined />;

    case "info":
    default:
      return <ExclamationOutlined />;
  }
};

export interface AlertInterface extends ColProps {
  type?: "warning" | "info";
  message: React.ReactNode;
  description?: React.ReactNode;
  errors?: Object;
  marginTop?: number;
}

let Alert = ({
  className,
  type,
  message,
  description,
  errors,
  ...props
}: AlertInterface) => {
  const errorList =
    errors &&
    Object.entries(errors).map(([key, values], index) => {
      return (
        <Col span={24} key={index}>
          <Body2>{key.charAt(0).toUpperCase() + key.slice(1)}:</Body2>

          {values &&
            (values as string[]).map((value, index) => (
              <Body2 color={guidelines.colors.grey[0]} key={index}>
                - {value}
              </Body2>
            ))}
        </Col>
      );
    });
  return (
    <Row className={className} {...props}>
      <Col flex="auto">
        <Row align="middle">
          <div className="icon">{getSymbol(type)}</div>
          <Col flex="1 1">
            <Body1 marginTop="0" fontWeight={400}>
              {message}
            </Body1>
          </Col>
        </Row>
        {description && (
          <Row className="description">
            <Col span={24}>
              <Body2>{description}</Body2>
            </Col>
          </Row>
        )}
        <Row className="errors">{errorList}</Row>
      </Col>
    </Row>
  );
};

export default styled(Alert)`
  padding: ${guidelines.padding[2]}px ${guidelines.padding[3]}px;
  border-radius: ${guidelines.borderRadius[0]}px;
  margin-top: ${(props) =>
    props.marginTop ? props.marginTop : guidelines.margin[0]}px;
  ${(props) => getStyle(props.type)}
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    font-size: 20px;
    margin-right: ${guidelines.margin[1]}px;
  }
  .description,
  .errors {
    padding-left: ${guidelines.padding[3] + guidelines.margin[1]}px;
  }
`;
