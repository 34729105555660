import React from "react";
import { Row, Col, Button, RowProps, ButtonProps } from "antd";
import styled from "styled-components";
import {
  FormModalReducerInterface,
  guidelines,
  HeaderReducerInterface,
  responsive,
  SourcesReducerInterface,
  stripeKey,
  WindowSizeReducerInterface,
} from "../../utils";
import { connect } from "react-redux";
import {
  deactivateFormModal,
  storeSource,
  updateHeaderData,
} from "../../store/actions";
import { useEffect } from "react";
import { Form } from "../form";
import { loadStripe, Token } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../checkout-form";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

const stripePromise = loadStripe(stripeKey);

let CloseButton = ({
  className,
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => (
  <Row justify="end" className={className} {...props}>
    <CloseIcon />
  </Row>
);

CloseButton = styled(CloseButton)`
  color: white;
  font-size: 40px;
  cursor: pointer;
`;

let PrimaryButton = ({
  className,
  children,
  ...props
}: ButtonProps & React.RefAttributes<HTMLElement>) => (
  <Button shape="round" className={className} {...props}>
    {children}
  </Button>
);

PrimaryButton = styled(PrimaryButton)`
  color: white;
  background: transparent !important;
`;

let SecondaryButton = ({
  className,
  children,
  ...props
}: ButtonProps & React.RefAttributes<HTMLElement>) => (
  <Button type="link" className={className} {...props}>
    {children}
  </Button>
);

SecondaryButton = styled(SecondaryButton)`
  color: white;
`;

const mapStateToProps = (state: any) => ({
  sources: state.sources,
  formModal: state.formModal,
  windowSize: state.windowSize,
});

const mapDispatchToProps = (dispatch: any) => ({
  deactivateFormModal: () => dispatch(deactivateFormModal()),
  updateHeaderData: (data: any) => dispatch(updateHeaderData(data)),
  storeSource: ({ id }: { id: string }) => dispatch(storeSource(id)),
});

let Buttons: any = ({
  className,
  loading,
  formModal: {
    secondaryAction,
    loadingPrimaryButton,
    loadingSecondaryButton,
    closeable,
  },
  windowSize,
  children,
  sources,
  dispatch,
  updateHeaderData,
  deactivateFormModal,
  storeSource,
  ...props
}: {
  loading?: boolean;
  dispatch?: (dispatch: any) => any;
} & FormModalInterface &
  RowProps &
  React.RefAttributes<HTMLDivElement>) => {
  return (
    <Row justify="space-between" className={className} {...props}>
      {!loadingSecondaryButton && (
        <PrimaryButton htmlType="submit" loading={loadingPrimaryButton}>
          {children || "Confirm"}
        </PrimaryButton>
      )}
      {!(loadingPrimaryButton || (!closeable && !secondaryAction)) && (
        <SecondaryButton
          loading={loadingSecondaryButton}
          onClick={secondaryAction || deactivateFormModal}
        >
          {children || "Cancel"}
        </SecondaryButton>
      )}
    </Row>
  );
};

Buttons = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  styled(Buttons)`
    margin-top: ${guidelines.margin[4]}px;
    padding-bottom: ${guidelines.margin[0]}px;
    border-bottom: 1px solid white;
  `
);

export interface FormModalInterface {
  className?: string;
  sources: SourcesReducerInterface;
  formModal: FormModalReducerInterface;
  storeSource: ({ id }: { id: string }) => Promise<any>;
  deactivateFormModal: () => void;
  updateHeaderData: (data: HeaderReducerInterface) => Promise<any>;
  windowSize: WindowSizeReducerInterface;
}

const FormModal = ({
  className,
  sources,
  formModal: {
    closeable,
    activeModal,
    requiredDefaultCard,
    loadingPrimaryButton,
    loadingSecondaryButton,
    primaryAction,
    secondaryAction,
    ...formModalProps
  },
  storeSource,
  formModal,
  deactivateFormModal,
  updateHeaderData,
  windowSize,
  ...props
}: FormModalInterface) => {
  useEffect(() => {
    activeModal &&
      updateHeaderData({
        mode: "dark",
        backButton: {
          active: false,
        },
        logoutButton: false,
        logoButton: () => deactivateFormModal(),
      });
    // eslint-disable-next-line
  }, [activeModal]);

  if (activeModal) {
    const handleCheckoutFormFinish = (token?: Token) => {
      if (token) {
        return storeSource(token);
      } else {
        return Promise.resolve();
      }
    };

    const stripeForm = (
      <Elements stripe={stripePromise}>
        <CheckoutForm
          title={
            <span style={{ fontWeight: 500 }}>Enter your payment details</span>
          }
          description={
            <span style={{ color: "rgba(255,255,255,0.75)" }}>
              - Credit or debit card for the payment of your billing cycle.
              <br />- No commitments. Cancel online at any time.
            </span>
          }
          buttonText={"Save & Continue"}
          type="white"
          onFinish={handleCheckoutFormFinish}
        />
      </Elements>
    );

    return (
      <Row>
        <Row
          justify="center"
          align="top"
          className={className}
          {...props}
          onClick={
            !loadingPrimaryButton && !loadingSecondaryButton && closeable
              ? deactivateFormModal
              : undefined
          }
        >
          <Col className="modal" onClick={(e) => e.stopPropagation()}>
            {!loadingPrimaryButton && !loadingSecondaryButton && closeable && (
              <CloseButton onClick={deactivateFormModal} />
            )}
            <Row className="container" justify="center">
              {requiredDefaultCard && !sources.default && stripeForm}
              {((requiredDefaultCard && sources.default) ||
                !requiredDefaultCard) && (
                <Form
                  type="popup"
                  Buttons={Buttons}
                  onFinish={
                    primaryAction ||
                    ((data: Object) => {
                      console.log("Success:", data);
                      deactivateFormModal();
                    })
                  }
                  {...formModalProps}
                />
              )}
            </Row>
          </Col>
        </Row>
      </Row>
    );
  } else {
    return <></>;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(FormModal)`
  z-index: 1;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 40, 91, 0.98);
  padding-bottom: ${guidelines.padding[4] + 50}px;

  .modal {
    width: 90%;
    max-width: ${(props) =>
      responsive({ xs: "525px" }, props.windowSize.width)};
    margin-top: ${guidelines.headerPadding}px;
    .container {
      margin-top: ${guidelines.margin[3]}px;
    }
  }
`);
