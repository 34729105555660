import { SourcesReducerInterface } from "../../utils";

const initialState: SourcesReducerInterface = {
  all: [],
  activeModal: false,
  default: null,
};

export default function sourcesReducer(
  state = initialState,
  { type, payload }: { type: string; payload: SourcesReducerInterface }
) {
  switch (type) {
    case "SET_SOURCES":
      return { ...state, ...payload };

    case "SET_DEFAULT_SOURCE":
      return { ...state, ...payload };

    case "ACTIVATE_SOURCES_MODAL":
      return { ...state, ...payload };

    case "DEACTIVATE_SOURCES_MODAL":
      return { ...state, activeModal: false };

    case "RESET_SOURCES":
      return initialState;

    default:
      return state;
  }
}
