import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  guidelines,
  responsive,
  WindowSizeReducerInterface,
} from "../../utils";

const Price = ({
  className,
  prefix,
  children,
  suffix,
}: {
  className?: string;
  prefix?: React.ReactNode;
  children: React.ReactNode;
  suffix?: React.ReactNode;
  color?: string;
  cursor?: string;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  windowSize: WindowSizeReducerInterface;
}) => {
  return (
    <p className={className}>
      <span>{prefix}</span> {children} <span>{suffix}</span>
    </p>
  );
};

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
}) => ({
  windowSize: state.windowSize,
});

export default connect(mapStateToProps)(styled(Price)`
  margin-top: ${guidelines.margin[0]}px;
  margin-bottom: unset;

  color: ${(props) => (props.color ? props.color : guidelines.colors.blue[0])};

  font-size: ${(props) =>
    responsive(guidelines.fontSize.title, props.windowSize.width)}px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : null)};

  cursor: ${(props) => (props.cursor ? props.cursor : null)};

  span {
    font-size: ${(props) =>
      responsive(guidelines.fontSize.body1, props.windowSize.width)}px;
  }
`);
