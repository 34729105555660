import React from "react";
import styled from "styled-components";
import { guidelines } from "../../utils";
import { isString } from "lodash";

const MenuButton1 = ({
  className,
  children,
  padding,
  backgroundColor,
  color,
  style,
  ...props
}: {
  padding?: string;
  backgroundColor?: string;
  cursor?: string;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button className={className} style={style} {...props}>
      <p>{children}</p>
    </button>
  );
};

export default styled(MenuButton1)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  color: ${(props) => (props.color ? props.color : guidelines.colors.blue[0])};
  /* padding: ${(props) =>
    props.padding
      ? isString(props.padding)
        ? props.padding
        : `${props.padding}px`
      : "38px"}; */
  border: none;
  border-radius: 20px;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  font-size: 19px;

  :focus {
    outline: none;
  }

  p {
    margin: 0;
  }
`;
