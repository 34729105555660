import React, { useState } from "react";
import { Row, Col, Button, RowProps, ButtonProps } from "antd";
import styled from "styled-components";
import {
  guidelines,
  SourcesReducerInterface,
  stripeKey,
  WindowSizeReducerInterface,
  HeaderReducerInterface,
} from "../../utils";
import { Source } from "./";
import { connect } from "react-redux";
import { loadStripe, Token } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../checkout-form";
import {
  indexSources,
  storeSource,
  deactivateSourcesModal,
  updateHeaderData,
} from "../../store/actions";
import { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(stripeKey);

let CloseButton = ({
  className,
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => (
  <Row justify="end" className={className} {...props}>
    <CloseIcon />
  </Row>
);

CloseButton = styled(CloseButton)`
  color: white;
  font-size: 40px;
  cursor: pointer;
`;

let NewPaymentSourceButton = ({
  className,
  ...props
}: ButtonProps & React.RefAttributes<HTMLElement>) => (
  <Button type="dashed" className={className} {...props}>
    New payment source
  </Button>
);

NewPaymentSourceButton = styled(NewPaymentSourceButton)`
  background: transparent !important;
  color: white;
  border-radius: 5px;
  width: 100%;
  margin-top: ${guidelines.margin[2]}px;
`;

interface SourceModalInterface
  extends RowProps,
    React.RefAttributes<HTMLDivElement> {
  sources: SourcesReducerInterface;
  indexSources: () => Promise<void>;
  storeSource: (token: Token) => Promise<void>;
  deactivateSourcesModal: () => Promise<void>;
  updateHeaderData: (data: HeaderReducerInterface) => Promise<void>;
  windowSize: WindowSizeReducerInterface;
}

export const SourcesModal = ({
  className,
  sources,
  indexSources,
  storeSource,
  deactivateSourcesModal,
  updateHeaderData,
  windowSize: { width },
  ...props
}: SourceModalInterface) => {
  let [addSource, setAddSource] = useState(false);

  useEffect(() => {
    if (sources.activeModal)
      updateHeaderData({
        mode: "dark",
        backButton: {
          active: false,
        },
        logoutButton: false,
        logoButton: () => deactivateSourcesModal(),
      });
    return () => {
      setAddSource(false);
    };
  }, [updateHeaderData, deactivateSourcesModal, sources]);

  if (sources.activeModal) {
    const handleCheckoutFormFinish: (token?: Token) => Promise<void> = (
      token
    ) => {
      if (token) {
        return storeSource(token).then(() => {
          setAddSource(false);
        });
      } else {
        return Promise.resolve();
      }
    };

    const sourceList =
      sources.all &&
      sources.all.map((source, index) => <Source key={index} {...source} />);

    const stripeForm = (
      <Elements stripe={stripePromise}>
        <CheckoutForm type="white" onFinish={handleCheckoutFormFinish} />
      </Elements>
    );

    return (
      <>
        <Row
          onClick={() => deactivateSourcesModal()}
          justify="center"
          align="top"
          className={className}
          {...props}
        >
          <Col className="modal" onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={deactivateSourcesModal} />
            {sources.all.length > 0 && (
              <Row className="content" justify="center">
                <Col>{sourceList}</Col>
              </Row>
            )}
            {!addSource && (
              <NewPaymentSourceButton
                onClick={() => {
                  setAddSource(true);
                }}
              />
            )}
            {addSource && stripeForm}
          </Col>
        </Row>
      </>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: {
  sources: SourcesReducerInterface;
  windowSize: WindowSizeReducerInterface;
}) => ({
  sources: state.sources,
  windowSize: state.windowSize,
});

const mapDispatchToProps = (dispatch: (dispatch: any) => Promise<any>) => ({
  indexSources: () => dispatch(indexSources()),
  storeSource: ({ id }: Token) => dispatch(storeSource(id)),
  deactivateSourcesModal: () => dispatch(deactivateSourcesModal()),
  updateHeaderData: (data: HeaderReducerInterface) =>
    dispatch(updateHeaderData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(SourcesModal)`
  z-index: 1;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 40, 91, 0.95);
  padding-bottom: ${guidelines.padding[4] + 50}px;

  .modal {
    width: 90%;
    max-width: 360px;
    margin-top: ${guidelines.headerPadding}px;
    .content {
      margin-top: ${guidelines.margin[3]}px;
      padding: ${guidelines.padding[1]}px;
      background: white;
      border-radius: ${guidelines.borderRadius[0]}px;
    }
  }
`);
