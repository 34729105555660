import { Col, Row } from "antd";
import React from "react";
import { guidelines, InvitationInterface } from "../../utils";
import { Body1 } from "../typography";
import Invitation from "./Invitation";

const Label = ({
  children,
  span,
}: {
  children: React.ReactNode;
  span?: string | number;
}) => (
  <Col span={span}>
    <Body1 marginTop="0">{children}</Body1>
  </Col>
);

function InvitationsTable({
  invitations,
  actions,
  onMemberUpdateFinished,
  onMemberDeleteFinished,
}: {
  invitations?: InvitationInterface[];
  actions?: boolean;
  onMemberUpdateFinished?: Function;
  onMemberDeleteFinished?: Function;
}) {
  return (
    <Row>
      <Col span={24}>
        <Row
          justify="center"
          style={{
            marginTop: `${guidelines.padding[4]}px`,
            backgroundColor: `${guidelines.colors.blue[4]}`,
            borderRadius: "100px",
          }}
        >
          <Col
            xs={{ span: 0 }}
            lg={{ span: 23 }}
            style={{
              padding: "7.5px 0",
            }}
          >
            <Row>
              <Label span={2}>ID</Label>
              <Label span={6}>Recipient</Label>
              <Label span={3}>Role</Label>
              <Label span={3}>Mail</Label>
              <Label span={5}>Status</Label>
            </Row>
          </Col>
        </Row>
        <Row>
          {invitations?.map((invitation, index) => (
            <Col span="24" key={index}>
              <Invitation
                onUpdateFinished={onMemberUpdateFinished}
                onDeleteFinished={onMemberDeleteFinished}
                data={invitation}
                actions={actions}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
}

export default InvitationsTable;
