import React from "react";
import { Row, Col, RowProps } from "antd";
import styled from "styled-components";
import { H1, H3 } from "..";
import { guidelines, responsive } from "../../utils";
import { useNavigate } from "react-router-dom";

interface MenuAdminInterface
  extends RowProps,
    React.RefAttributes<HTMLDivElement> {}

export const MenuAdmin = ({ className, ...props }: MenuAdminInterface) => {
  const navigate = useNavigate();

  return (
    <Row className={className} {...props}>
      <Col span={24}>
        <H1 className="title" color="white">
          Admin
        </H1>
        <Row gutter={[guidelines.spacing[0], guidelines.spacing[0]]}>
          <Col flex={responsive({ xs: "0 0 100%", sm: 0 }, window.innerWidth)}>
            <H3
              onClick={() => navigate("/customers")}
              className="item"
              color={guidelines.colors.blue[1]}
              cursor="pointer"
            >
              Customers
            </H3>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default styled(MenuAdmin)`
  .title {
    margin-bottom: ${guidelines.margin[3]}px;
  }
  .item {
    margin-top: 0;
  }
`;
