import { Col, Row } from "antd";
import { RowProps } from "antd/lib/row";
import React from "react";
import styled from "styled-components";
import { guidelines } from "../../utils";
import { Body1 } from "../typography";
import { ReactComponent as AcceptIcon } from "../../icons/accept.svg";

function Company({
  children,
  active,
  ...props
}: { active?: boolean; children?: string } & RowProps &
  React.RefAttributes<HTMLDivElement>) {
  return (
    <Row align="middle" {...props}>
      <div className="checkbox">{active && <AcceptIcon />}</div>
      <Col flex="1" className="container">
        <Body1 marginTop="0">{children}</Body1>
      </Col>
    </Row>
  );
}

export default styled(Company)`
  margin-top: ${guidelines.margin[0]}px;
  cursor: pointer;

  .container {
    background-color: white;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .checkbox {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
