import { Row, RowProps } from "antd";
import React from "react";
import styled from "styled-components";
import { guidelines } from "../../utils";

let BallPulseSync: any = ({
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => {
  return (
    <Row justify="center" align="middle" {...props}>
      <div className="ball-pulse-sync">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Row>
  );
};

BallPulseSync = styled(BallPulseSync)`
  height: 60%;
  > * {
    > div {
      width: 10px;
      height: 10px;
      background-color: ${guidelines.colors.red[0]};
    }
  }
`;

export default BallPulseSync;
