import React from "react";
import styled from "styled-components";
import { Col } from "antd";
import { guidelines } from "../../utils";
import { ColProps } from "antd/lib/col";

interface TabMenuItemInterFace
  extends ColProps,
    React.RefAttributes<HTMLDivElement> {
  index: number;
  children: React.ReactNode;
  className?: string;
  active: number;
}

export const TabMenuItem = styled(
  ({ className, children, active, ...props }: TabMenuItemInterFace) => {
    return (
      <Col flex="none" className={className} {...props}>
        {children}
      </Col>
    );
  }
)`
  opacity: ${(props) => (props.active === props.index ? 1 : 0.4)};
  padding: 8px 30px;
  border-top: 1px solid ${guidelines.colors.blue[0]};
  border-bottom: 1px solid ${guidelines.colors.blue[0]};
  ${(props) =>
    props.active === props.index || props.index > props.active
      ? `
        border-right: 1px solid ${guidelines.colors.blue[0]};
        border-top-right-radius: ${guidelines.borderRadius[0]}px;
         `
      : null}
  ${(props) =>
    props.active === props.index || props.index < props.active
      ? `
        border-left: 1px solid ${guidelines.colors.blue[0]};
        border-top-left-radius: ${guidelines.borderRadius[0]}px;
         `
      : null}
  cursor: pointer;
`;
