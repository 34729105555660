import React from "react";
import styled from "styled-components";
import { Row, Col, Select } from "antd";
import { H2, LeftMenuItem } from "..";
import {
  guidelines,
  responsive,
  WindowSizeReducerInterface,
} from "../../utils";
import { connect } from "react-redux";

const { Option } = Select;

export interface LeftMenuInterface {
  className?: string;
  title: string;
  items: string[];
  onChange: (section: number) => void;
  activeItem: number;
  windowSize: WindowSizeReducerInterface;
}

const LeftMenu = ({
  className,
  title = "",
  items = [],
  onChange,
  activeItem,
}: LeftMenuInterface) => {
  const handleChange: (value: number) => void = (value) => {
    onChange && onChange(value);
  };

  let itemList = items.map((value, index) => {
    return (
      <LeftMenuItem
        active={index === activeItem}
        onClick={() => handleChange(index)}
        key={index}
      >
        {value}
      </LeftMenuItem>
    );
  });
  let itemOptionList = items.map((value, index) => {
    return (
      <Option value={index} key={index}>
        {value}
      </Option>
    );
  });
  return (
    <Row className={className}>
      <Col span={24}>
        <H2 color="white" textAlign="center">
          {title}
        </H2>
      </Col>
      <Col className="items" xs={{ span: 0 }} md={{ span: 24 }}>
        <Row>{itemList}</Row>
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 0 }}
        style={{ marginTop: guidelines.margin[0] }}
      >
        <Row justify="center">
          <Select
            onChange={handleChange}
            defaultValue={0}
            style={{ width: "100%", color: "white" }}
            bordered={false}
          >
            {itemOptionList}
          </Select>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
}) => ({
  windowSize: state.windowSize,
});

export default connect(mapStateToProps)(styled(LeftMenu)`
  align-content: flex-start;
  min-height: ${(props) => responsive({ md: "450px" }, props.windowSize.width)};
  min-width: 220px;
  padding: ${guidelines.padding[3]}px ${guidelines.padding[3]}px;
  border-radius: ${guidelines.borderRadius[0]}px;
  background: ${guidelines.colors.blue[0]};

  .items {
    margin-top: ${guidelines.margin[3]}px;
  }

  .ant-select-arrow {
    color: rgba(255, 255, 255, 0.5);
  }
`);
