import React, { useEffect, useState } from "react";
import { Button, ButtonProps, Col, Row } from "antd";
import styled from "styled-components";
import { RowProps } from "antd/lib/row";
import { Body1, Body2, H1, H2 } from "../typography";
import {
  apiClient,
  AuthReducerInterface,
  CompanyInterface,
  guidelines,
  InvitationInterface,
  SwitchCompaniesReducerInterface,
} from "../../utils";
import { Company, PendingApprovalCompany } from ".";
import { ReactComponent as RightArrowIcon } from "../../icons/right-arrow.svg";
import { Formik, FormikHelpers, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateSwitchCompanies,
  updateDefaultCompany,
  updateSwitchCompanies,
  updateUserCompanies,
} from "../../store/actions";
import Axios from "axios";
import { Alert } from "../feedback";
import { isEmpty } from "lodash";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

let NewCompany = ({
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => (
  <Row {...props}>
    <Body1 color="white" marginTop="0">
      New company
    </Body1>
  </Row>
);

NewCompany = styled(NewCompany)`
  margin-top: ${guidelines.margin[0]}px;
  border: 1px dashed rgba(255, 255, 255, 0.6);
  padding: 0px 20px;
  border-radius: 10px;
  height: 53px;
  display: flex;
  align-items: center;
`;

let NewCompanyInput = ({
  className,
  onSubmit,
}: {
  onSubmit?: ((
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => Promise<any>) &
    ((values: any) => Promise<any>);
  className?: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleFinish = async (values: any) => {
    setLoading(true);
    onSubmit && (await onSubmit(values));
    setLoading(false);
  };
  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={handleFinish}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .min(2, "at least 2 characters")
          .required("is a required field"),
      })}
    >
      <Form>
        <Row className={className} wrap={false}>
          <Col flex={1}>
            <Row align="middle" className="input-container">
              <Col span={24}>
                <Field disabled={loading} name="name" placeholder="Name" />
                <Body2 className="error" marginTop="0">
                  <ErrorMessage name="name" />
                </Body2>
              </Col>
            </Row>
          </Col>
          <button
            type="submit"
            className={`submit ${loading ? "loading" : ""}`}
          >
            {(loading && (
              <div className="sk-bounce">
                <div className="sk-bounce-dot"></div>
                <div className="sk-bounce-dot"></div>
              </div>
            )) || <RightArrowIcon />}
          </button>
        </Row>
      </Form>
    </Formik>
  );
};

NewCompanyInput = styled(NewCompanyInput)`
  --sk-color: white;
  margin-top: ${guidelines.margin[0]}px;
  height: 53px;

  .input-container {
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    padding: 0px 20px;
    height: 100%;

    input {
      width: 100%;
      color: white;
      padding: 0;
      border-color: transparent;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    .error {
      color: #ff6f6f;
    }
  }

  .submit {
    background-color: transparent;
    border: 1px solid #22ff7a;

    border-radius: 10px;
    height: 53px;
    width: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    &.loading {
      border: none;
    }

    &:focus {
      outline: none;
    }
  }
`;

let CloseButton = ({
  className,
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => {
  const dispatch = useDispatch<any>();

  return (
    <Row
      justify="end"
      className={className}
      onClick={() => dispatch(deactivateSwitchCompanies())}
      {...props}
    >
      <CloseIcon />
    </Row>
  );
};

CloseButton = styled(CloseButton)`
  color: white;
  font-size: 40px;
  cursor: pointer;
`;

let ConfirmButton = ({
  className,
  ...props
}: ButtonProps & React.RefAttributes<HTMLElement>) => (
  <Button shape="round" className={className} {...props}>
    Confirm Selection
  </Button>
);

ConfirmButton = styled(ConfirmButton)`
  color: white;
  background: transparent !important;
`;

interface SwitchCompaniesInterface
  extends RowProps,
    React.RefAttributes<HTMLDivElement> {}

function SwitchCompanies({ ...props }: SwitchCompaniesInterface) {
  const { active, closeable, loading, description, alert, hide } = useSelector(
    (state: { switchCompanies: SwitchCompaniesReducerInterface }) =>
      state.switchCompanies
  );
  const auth = useSelector(
    (state: { auth: AuthReducerInterface }) => state.auth
  );
  const [newCompanyInput, setNewCompanyInput] = useState<boolean>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>();
  const [invitations, setInvitations] = useState<
    InvitationInterface[] | undefined
  >();
  const dispatch = useDispatch<any>();
  const [defaultCompany, setDefaultCompany] = useState<CompanyInterface>();

  const getInvitations = () => {
    Axios.get(`${apiClient}/invitations`).then(({ data }) => {
      setInvitations(data);
    });
  };

  const handleInvitationReplyFinished = () => {
    getInvitations();
    return dispatch(updateUserCompanies());
  };

  const handleNewCompanySubmit = (values: any) => {
    dispatch(
      updateSwitchCompanies({
        loading: true,
      })
    );

    return Axios.post(`${apiClient}/companies`, values)
      .then(async () => {
        await dispatch(updateUserCompanies());
        dispatch(
          updateSwitchCompanies({
            loading: false,
          })
        );
        setNewCompanyInput(false);
      })
      .catch((e: { response?: { data?: any } }) => {
        dispatch(
          updateSwitchCompanies({
            loading: false,
          })
        );
        e.response?.data?.message &&
          dispatch(
            updateSwitchCompanies({
              alert: {
                message: e.response?.data?.message,
              },
            })
          );
      });
  };

  const handleConfirmButtonClick = async () => {
    let prevCloseable = closeable;

    dispatch(
      updateSwitchCompanies({
        closeable: false,
      })
    );

    setConfirmLoading(true);

    if (
      defaultCompany &&
      defaultCompany?.id !== auth.default_company?.company_id
    ) {
      await dispatch(updateDefaultCompany(defaultCompany))
        .then(() => {
          setConfirmLoading(false);
          dispatch(deactivateSwitchCompanies());
        })
        .catch((e: { response?: { data?: any } }) => {
          setConfirmLoading(false);
          dispatch(
            updateSwitchCompanies({
              closeable: prevCloseable,
            })
          );
          e.response?.data?.message &&
            dispatch(
              updateSwitchCompanies({
                alert: {
                  message: e.response?.data?.message,
                },
              })
            );
        });
    } else {
      setConfirmLoading(false);
      dispatch(deactivateSwitchCompanies());
    }
  };

  useEffect(() => {
    auth.token &&
      Axios.get(`${apiClient}/invitations`).then(({ data }) => {
        setInvitations(data);
      });
    return () => {
      setInvitations(undefined);
    };
  }, [auth]);

  return (
    <>
      {active && (
        <Row justify="center" align="top" {...props}>
          <div className="modal-container">
            {!loading && closeable && <CloseButton />}
            <div className="modal">
              {description && (
                <Row>
                  <Body1 marginTop="0" className="description">
                    {description}
                  </Body1>
                </Row>
              )}
              {!hide?.includes("companies") && (
                <>
                  <Row>
                    <H1 color="white" marginTop="0">
                      Companies
                    </H1>
                  </Row>
                  <Row>
                    {auth.companies?.map((company, index) => (
                      <Col span={24} key={index}>
                        <Company
                          onClick={() => setDefaultCompany(company.company)}
                          active={
                            (defaultCompany?.id ||
                              auth.default_company?.company_id) ===
                            company.company.id
                          }
                        >
                          {company.company?.name}
                        </Company>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {!confirmLoading && (
                <>
                  {!hide?.includes("new company") && (
                    <Row>
                      <Col span={24}>
                        {(newCompanyInput && (
                          <NewCompanyInput onSubmit={handleNewCompanySubmit} />
                        )) || (
                          <NewCompany
                            onClick={() => setNewCompanyInput(true)}
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                  {!isEmpty(invitations) && (
                    <>
                      <Row>
                        <H2 color="white">Pending approval</H2>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {invitations?.map((invitation, index) => (
                            <PendingApprovalCompany
                              onReplyFinished={handleInvitationReplyFinished}
                              data={invitation}
                              key={index}
                            >
                              {invitation.company?.name}
                            </PendingApprovalCompany>
                          ))}
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </div>
            {!loading &&
              (defaultCompany?.id || auth.default_company?.company_id) && (
                <Row className="buttons">
                  <ConfirmButton
                    loading={confirmLoading}
                    onClick={handleConfirmButtonClick}
                  />
                </Row>
              )}
            {alert && (
              <Alert
                style={{
                  marginBottom: guidelines.margin[1],
                  width: 370,
                }}
                {...alert}
              />
            )}
          </div>
        </Row>
      )}
    </>
  );
}

export default styled(SwitchCompanies)`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(29, 40, 91, 0.95);
  z-index: 1;
  overflow: scroll;
  top: 0;
  padding-bottom: ${guidelines.padding[4] + 50}px;

  .modal-container {
    margin-top: ${guidelines.headerPadding}px;
    width: 90%;
    max-width: 370px;
    .modal {
      margin-top: ${guidelines.margin[3]}px;
      padding: 60px 30px 50px 30px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.05) 100%
      );
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 20px;

      .description {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .buttons {
      margin-top: ${guidelines.margin[4]}px;
      padding-bottom: ${guidelines.margin[0]}px;
      /* border-bottom: 1px solid white; */
    }
  }
`;
