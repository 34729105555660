import React, { RefObject, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { guidelines, StorageReducerInterface } from "../../utils";
import { Card, CardInterface } from "./Card";
import { RowProps } from "antd/lib";
import { useSelector } from "react-redux";

const Content = styled(
  ({
    className,
    children,
    parentRef,
    ...props
  }: RowProps & { parentRef: React.LegacyRef<HTMLDivElement> }) => {
    return (
      <div>
        <Row
          ref={parentRef}
          justify="center"
          gutter={[guidelines.spacing[0], guidelines.spacing[0]]}
          className={className}
          {...props}
        >
          {children}
        </Row>
      </div>
    );
  }
)`
  margin-top: ${guidelines.margin[3]}px !important;
`;

interface CardProps {
  type: "horizontal" | "vertical";
  cards: CardInterface[];
}

export const Cards = (props: CardProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { windowSize } = useSelector((store: StorageReducerInterface) => ({
    windowSize: store.windowSize,
  }));

  const [cardsList, setCardsList] = useState<React.ReactNode[]>();
  const [extraCardsList, setExtraCardList] = useState<React.ReactNode[]>();

  const cardWidthOptions = {
    vertical: 280,
    horizontal: 370,
  };

  const setExtraCards = () => {
    const { type = "horizontal", cards } = props;

    const cardsWidth = cardWidthOptions[type],
      offsetWidth = parentRef.current?.offsetWidth;

    let extraCards, surplusCards;

    if (
      parentRef &&
      offsetWidth &&
      Array.isArray(cards) &&
      (surplusCards = cards.length % Math.floor(offsetWidth / cardsWidth)) >
        0 &&
      (extraCards = Math.floor(offsetWidth / cardsWidth) - surplusCards) > 0
    ) {
      let extraCardsList: React.ReactNode[] = [];

      for (let i = 0; i < extraCards; i++) {
        extraCardsList.push(
          <Col key={i} style={{ width: `${cardsWidth}px` }}></Col>
        );
      }

      setExtraCardList(extraCardsList);
    } else {
      setExtraCardList([]);
    }
  };

  const setCards = () => {
    const { type = "horizontal", cards } = props;
    if (
      parentRef &&
      (parentRef as RefObject<HTMLDivElement>).current &&
      Array.isArray(cards)
    ) {
      setCardsList(
        cards.map((card, index) => {
          card.type = type;
          card.cardWidth = cardWidthOptions[type];
          return <Card key={index} {...{ ...card, windowSize }}></Card>;
        })
      );
    }
  };

  useEffect(() => {
    setCards();
    setExtraCards();
    // eslint-disable-next-line
  }, [parentRef.current, props.cards]);

  return (
    <Content parentRef={parentRef}>
      {cardsList}
      {extraCardsList}
    </Content>
  );
};
