import { UsersReducerInterface } from "../../utils";

const initialState: UsersReducerInterface = {};

export default function usersReducer(
  state = initialState,
  {
    type,
    payload,
  }: {
    type: "SET_USERS" | "RESET_USERS";
    payload: UsersReducerInterface;
  }
) {
  switch (type) {
    case "SET_USERS":
      return payload;

    case "RESET_USERS":
      return initialState;

    default:
      return state;
  }
}
