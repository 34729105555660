import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, ColProps } from "antd";
import styled from "styled-components";
import { H2, Body1, Form } from "../../components";
import {
  guidelines,
  responsive,
  formatError,
  WindowSizeReducerInterface,
  HeaderReducerInterface,
} from "../../utils";
import { Button2, LoginWithCodeButton } from "../../components/buttons";
import {
  signUp,
  login,
  facebookLogin,
  googleLogin,
  updateHeaderData,
} from "../../store/actions";
import { ReactComponent as Facebook } from "../../img/facebook.svg";
import { ReactComponent as Google } from "../../img/google.svg";
import {
  FormInterface,
  FormItemInterface,
  InputInterface,
} from "../../components/form/Form";
import { RowProps } from "antd/lib";
interface TokenClientConfig {
  client_id: string;
  callback: (params: any) => void;
  scope: string;
  prompt?: "none" | "consent" | "select_account";
  enable_serial_consent: boolean;
}

declare global {
  let google:
    | {
        accounts: {
          oauth2: {
            initTokenClient: (params: {
              client_id: string;
              scope: string;
              callback?: (params: any) => void;
              prompt?: "none" | "consent" | "select_account";
              enable_serial_consent?: boolean;
            }) => {
              requestAccessToken: (
                overrideConfig?: Partial<TokenClientConfig>
              ) => void;
            };
          };
        };
      }
    | undefined;
}

const Container = styled(
  ({
    className,
    children,
    windowSize,
    ...props
  }: RowProps & { windowSize: WindowSizeReducerInterface }) => (
    <Row className={className} {...props}>
      {children}
    </Row>
  )
)`
  width: 100vw;
  height: 100vh;

  .left {
    height: ${(props) => responsive({ lg: "100%" }, props.windowSize.width)};
    background: ${guidelines.colors.blue[0]};
    padding: ${guidelines.headerPadding}px 0 ${guidelines.padding[5]}px 0;
  }

  .right {
    height: ${(props) => responsive({ lg: "100%" }, props.windowSize.width)};
    background: ${guidelines.colors.blue[0]};
    > .content {
      padding: ${(props) =>
          responsive(
            { xs: guidelines.padding[5], md: guidelines.headerPadding },
            props.windowSize.width
          )}px
        0 ${guidelines.padding[5]}px 0;
      width: 100%;
      height: 100%;
      background: white;
      border-top-left-radius: ${guidelines.borderRadius[0]}px;
      border-bottom-left-radius: ${(props) =>
        responsive(
          { md: guidelines.borderRadius[0] },
          props.windowSize.width
        )}px;
      overflow: scroll;
    }
  }
`;

const Line = styled(({ className, ...props }: ColProps) => (
  <Col className={className} {...props}></Col>
))`
  border-bottom: 1px solid ${guidelines.colors.grey[1]};
`;

const Or = styled(({ className, ...props }: RowProps) => (
  <Row align="middle" gutter={[40, 0]} className={className} {...props}>
    <Line flex="auto" />
    <Col>
      <Body1 fontWeight={400} marginTop="0" color={guidelines.colors.grey[0]}>
        Or
      </Body1>
    </Col>

    <Line flex="auto" />
  </Row>
))`
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: ${guidelines.margin[4]}px;
`;

let SocialNetworkLogin = ({
  className,
  loading,
  onFacebookButtonClick,
  onGoogleButtonClick,
}: {
  className?: string;
  loading?: {
    facebook?: boolean;
    google?: boolean;
  };
  onFacebookButtonClick: () => void;
  onGoogleButtonClick: () => void;
}) => {
  return (
    <Row className={className} gutter={[10, 0]}>
      <Col flex="auto">
        <Button
          type="primary"
          className="facebook"
          shape="round"
          block
          onClick={onFacebookButtonClick}
          loading={loading?.facebook}
          disabled={loading?.google}
        >
          <Facebook style={{ marginRight: "15px" }} /> Sign up with Facebook
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          id="google-sign-in"
          className="google"
          shape="round"
          onClick={onGoogleButtonClick}
          loading={loading?.google}
          disabled={loading?.facebook}
        >
          <Google />
        </Button>
      </Col>
    </Row>
  );
};

SocialNetworkLogin = styled(SocialNetworkLogin)`
  margin-top: ${guidelines.margin[4]}px;

  .facebook,
  .google {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .facebook {
    background: #4688f1 !important;
    border: none;
    padding-right: 0px;
    padding-left: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
  .google {
    background: #f2f2f2 !important;
    border: none;

    .ant-btn-loading-icon {
      color: ${guidelines.colors.grey[0]};
    }
  }
`;

const signUpFormItems: FormItemInterface[] = [
  {
    name: "name",
    placeholder: "Name",
    rules: [
      {
        required: true,
        message: "Please input your Name!",
      },
      {
        min: 2,
        message: "Please input at least 2 characters!",
      },
    ],
  },
  {
    name: "email",
    placeholder: "Email",
    rules: [
      {
        type: "email",
        message: "The input is not valid Email!",
      },
      {
        required: true,
        message: "Please input your Email!",
      },
    ],
  },
  {
    name: "company",
    placeholder: "Company",
    rules: [
      {
        required: true,
        message: "Please input your Company!",
      },
      {
        min: 2,
        message: "Please input at least 2 characters!",
      },
    ],
  },
  {
    type: "password",
    name: "password",
    placeholder: "Password",
    rules: [
      {
        required: true,
        message: "Please input your Password!",
      },
      {
        min: 8,
        message: "Please input at least 8 characters!",
      },
    ],
  },
  {
    type: "checkbox",
    name: "terms",
    data: (
      <>
        Creating an account means you’re ok with our{" "}
        <a
          href="https://drive.google.com/file/d/1IpsY8uzuAaZobZmLLpP8Q5Gf5ozDLboN/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions
        </a>{" "}
        and our{" "}
        <a
          href="https://drive.google.com/file/d/1S94pkUuhGgdapnil90xuMKREfIo6uDSj/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
      </>
    ),
    rules: [
      {
        validator: (_, value) =>
          value
            ? Promise.resolve()
            : Promise.reject(
                "Should accept our terms and conditions, and privacy policy."
              ),
      },
    ],
  },
];

export class Login extends Component<
  {
    windowSize: WindowSizeReducerInterface;
    signUp: (data: {
      name: string;
      email: string;
      company: string;
      password: string;
      terms: boolean;
    }) => Promise<any>;
    login: (data: { email: string; password: string }) => Promise<any>;
    updateHeaderData: (data: HeaderReducerInterface) => Promise<any>;
    facebookLogin: (data: string) => Promise<any>;
    googleLogin: (data: string) => Promise<any>;
  },
  {
    loading: {
      facebook: boolean;
      google: boolean;
    };
    activeForm: "signUp" | "login";
    forms: {
      login: FormInterface;
      signUp: FormInterface;
    };
  }
> {
  google: any | undefined;
  constructor(props: {
    windowSize: WindowSizeReducerInterface;
    signUp: (data: {
      name: string;
      email: string;
      company: string;
      password: string;
      terms: boolean;
    }) => Promise<any>;
    login: (data: { email: string; password: string }) => Promise<any>;
    updateHeaderData: (data: HeaderReducerInterface) => Promise<any>;
    facebookLogin: (data: string) => Promise<any>;
    googleLogin: (data: string) => Promise<any>;
  }) {
    super(props);

    this.state = {
      loading: {
        facebook: false,
        google: false,
      },
      activeForm: "signUp",
      forms: {
        signUp: {
          onFinish: (data: {
            name: string;
            email: string;
            company: string;
            password: string;
            terms: boolean;
          }) => {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  signUp: {
                    ...this.state.forms.signUp,
                    alert: null,
                    loading: true,
                  },
                },
              },
              () =>
                props.signUp(data).catch((err: any) => {
                  this.setState({
                    forms: {
                      ...this.state.forms,
                      signUp: {
                        ...this.state.forms.signUp,
                        loading: false,
                        alert: { ...formatError(err), type: "warning" },
                      },
                    },
                  });
                })
            );
          },
          Buttons: ({ loading = false }) => (
            <Row>
              <Col span={24}>
                <Button2
                  style={{ background: "#FDB03C", border: "none" }}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Sign Up
                </Button2>
              </Col>
            </Row>
          ),
          items: signUpFormItems.filter((item) => {
            const params = new URLSearchParams(window.location.search);
            const input = item as InputInterface;
            return params.get("invited") ? input.name !== "company" : true;
          }),
        },
        login: {
          onFinish: (data: { email: string; password: string }) => {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  login: {
                    ...this.state.forms.login,
                    alert: null,
                    loading: true,
                  },
                },
              },
              () =>
                props.login(data).catch((err: any) => {
                  this.setState({
                    forms: {
                      ...this.state.forms,
                      login: {
                        ...this.state.forms.login,
                        loading: false,
                        alert: { ...formatError(err), type: "warning" },
                      },
                    },
                  });
                })
            );
          },
          Buttons: ({ loading = false }) => (
            <>
              <Row>
                <Col span={24}>
                  <Button2
                    style={{ background: "#FDB03C", border: "none" }}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                  >
                    Login
                  </Button2>
                </Col>
              </Row>
              <LoginWithCodeButton />
            </>
          ),
          items: [
            {
              name: "email",
              placeholder: "Email",
              rules: [
                {
                  type: "email",
                  message: "The input is not valid Email!",
                },
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ],
            },
            {
              type: "password",
              name: "password",
              placeholder: "Password",
              rules: [
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ],
            },
          ],
        },
      },
    };
  }

  async componentDidMount() {
    const { updateHeaderData } = this.props;

    updateHeaderData({
      justify: "start",
      mode: "transparent",
      backButton: {
        active: false,
      },
      logoutButton: false,
    });
  }

  componentWillUnmount() {
    const { updateHeaderData } = this.props;

    updateHeaderData({
      mode: "transparent",
      backButton: {
        active: true,
      },
      logoutButton: true,
    });
  }

  handleGoogleLogin = () => {
    if (!this.google)
      this.google = google?.accounts.oauth2.initTokenClient({
        client_id:
          "874147873341-remfukn860h27aaufgkb3t87skbqdijm.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/userinfo.email",
        callback: (r: {
          access_token: string;
          authuser: string;
          expires_in: number;
          prompt: string;
          scope: string;
          token_type: string;
        }) => {
          const { googleLogin } = this.props;

          this.setState({
            loading: { ...this.state.loading, google: true },
          });

          googleLogin(r.access_token)
            .then(() => {
              this.setState({
                loading: { ...this.state.loading, google: false },
              });
            })
            .catch((e) => {
              this.setState({
                forms: {
                  ...this.state.forms,
                  [this.state.activeForm]: {
                    ...this.state.forms[this.state.activeForm],
                    alert: { ...formatError(e), type: "warning" },
                  },
                },
                loading: { ...this.state.loading, google: false },
              });
            });
        },
      });

    this.google?.requestAccessToken();
  };

  handleFacebookLogin = () => {
    const { facebookLogin } = this.props;
    this.setState({ loading: { ...this.state.loading, facebook: true } });
    FB.login(
      (response) => {
        if (response.authResponse?.accessToken) {
          facebookLogin(response.authResponse.accessToken)
            .then(() => {
              this.setState({
                loading: { ...this.state.loading, facebook: false },
              });
            })
            .catch((e) => {
              this.setState({
                forms: {
                  ...this.state.forms,
                  [this.state.activeForm]: {
                    ...this.state.forms[this.state.activeForm],
                    alert: { ...formatError(e), type: "warning" },
                  },
                },
                loading: { ...this.state.loading, facebook: false },
              });
            });
        } else {
          this.setState({
            loading: { ...this.state.loading, facebook: false },
          });
        }
      },
      { scope: "email" }
    );
  };

  render() {
    const { windowSize } = this.props;

    return (
      <Container windowSize={windowSize}>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="left">
          <Row>
            <Col xs={{ offset: 2, span: 20 }} md={{ offset: 4, span: 15 }}>
              <H2 color={guidelines.colors.blue[1]}>Let's begin this</H2>
              <H2 color="white" marginTop="0">
                entrepreneurial journey <br /> together
              </H2>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="right" flex="auto">
          <Row className="content" justify="center">
            <Col xs={{ span: 20 }} md={{ span: 17 }}>
              <Row justify="end">
                {this.state.activeForm === "signUp" && (
                  <Body1
                    onClick={() => this.setState({ activeForm: "login" })}
                    color={guidelines.colors.grey[0]}
                    fontWeight="400"
                  >
                    Already a member?{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        color: guidelines.colors.blue[0],
                      }}
                    >
                      Login
                    </span>
                  </Body1>
                )}
                {this.state.activeForm === "login" && (
                  <Body1
                    onClick={() => this.setState({ activeForm: "signUp" })}
                    color={guidelines.colors.grey[0]}
                    fontWeight="400"
                  >
                    Not a member?{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        color: guidelines.colors.blue[0],
                      }}
                    >
                      Sign up now
                    </span>
                  </Body1>
                )}
              </Row>
              <Row>
                <H2 marginTop={guidelines.margin[4]} fontWeight="500">
                  {this.state.activeForm === "login"
                    ? "Login to FunThriving"
                    : "Join our community"}
                </H2>
              </Row>
              <SocialNetworkLogin
                onFacebookButtonClick={this.handleFacebookLogin}
                onGoogleButtonClick={this.handleGoogleLogin}
                loading={this.state.loading}
              />
              <Or />
              <Row>
                <Col span={24}>
                  <Form
                    {...this.state.forms[this.state.activeForm]}
                    key={this.state.activeForm}
                  />
                  {this.state.activeForm === "login" && (
                    <p style={{ marginTop: 15 }}>
                      <a
                        href="https://drive.google.com/file/d/1IpsY8uzuAaZobZmLLpP8Q5Gf5ozDLboN/view"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://drive.google.com/file/d/1S94pkUuhGgdapnil90xuMKREfIo6uDSj/view"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy
                      </a>
                      .
                    </p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
}) => ({
  windowSize: state.windowSize,
});

const mapDispatchToProps = (dispatch: (data: any) => Promise<any>) => ({
  signUp: (data: {
    name: string;
    email: string;
    company: string;
    password: string;
    terms: boolean;
  }) => dispatch(signUp(data)),
  login: (data: { email: string; password: string }) => dispatch(login(data)),
  updateHeaderData: (data: HeaderReducerInterface) =>
    dispatch(updateHeaderData(data)),
  facebookLogin: (data: string) => dispatch(facebookLogin(data)),
  googleLogin: (data: string) => dispatch(googleLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
