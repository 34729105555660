import { Col, Row } from "antd";
import { RowProps } from "antd/lib/row";
import React, { useState } from "react";
import styled from "styled-components";
import { apiClient, guidelines, InvitationInterface } from "../../utils";
import { Body1 } from "../typography";
import { ReactComponent as AcceptIcon } from "../../icons/accept.svg";
import { ReactComponent as RejectIcon } from "../../icons/reject.svg";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { updateSwitchCompanies } from "../../store/actions";

const action = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => <div {...props}>{children}</div>;

const Action = styled(action)`
  width: 32.5px;
  height: 32.5px;
  border-radius: 100px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

function PendingApprovalCompany({
  data,
  children,
  onReplyFinished,
  ...props
}: {
  data?: InvitationInterface;
  onReplyFinished?: Function;
  children?: string;
} & RowProps &
  React.RefAttributes<HTMLDivElement>) {
  const [loading, setLoading] = useState<boolean>();
  const dispatch = useDispatch();
  const acceptInvitation = () => {
    setLoading(true);
    dispatch(
      updateSwitchCompanies({
        loading: true,
      })
    );
    Axios.post(`${apiClient}/invitations/${data?.id}/reply`, {
      accepted: true,
    })
      .then(async () => {
        await onReplyFinished?.();
        dispatch(
          updateSwitchCompanies({
            loading: false,
          })
        );
      })
      .catch((e: { response?: { data?: any } }) => {
        setLoading(false);
        e.response?.data?.message &&
          dispatch(
            updateSwitchCompanies({
              loading: false,
              alert: {
                message: e.response?.data?.message,
              },
            })
          );
        onReplyFinished?.();
      });
  };
  const rejectInvitation = () => {
    setLoading(true);
    Axios.post(`${apiClient}/invitations/${data?.id}/reply`, {
      accepted: true,
    })
      .then(() => onReplyFinished?.())
      .catch((e: { response?: { data?: any } }) => {
        setLoading(false);
        e.response?.data?.message &&
          dispatch(
            updateSwitchCompanies({
              alert: {
                message: e.response?.data?.message,
              },
            })
          );
        onReplyFinished?.();
      });
  };

  return (
    <Row {...props} align="middle" justify="space-between" wrap={false}>
      <Body1 color="white" marginTop="0">
        {children}
      </Body1>
      <Col>
        {(loading && (
          <div className="sk-bounce">
            <div className="sk-bounce-dot"></div>
            <div className="sk-bounce-dot"></div>
          </div>
        )) || (
          <Row wrap={false}>
            <Action style={{ borderColor: "#22FF7A", marginRight: 10 }}>
              <AcceptIcon onClick={acceptInvitation} />
            </Action>
            <Action style={{ borderColor: "#FF6F6F" }}>
              <RejectIcon onClick={rejectInvitation} />
            </Action>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default styled(PendingApprovalCompany)`
  --sk-color: white;

  margin-top: ${guidelines.margin[0]}px;
  padding: 0px 20px;
  height: 53px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
`;
