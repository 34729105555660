import React from "react";
import styled from "styled-components";
import { Row, Button, Col } from "antd";
import { H2 } from "../typography";
import { connect } from "react-redux";
import { logout } from "../../store/actions";
import {
  guidelines,
  UserInterface,
  WindowSizeReducerInterface,
  AuthReducerInterface,
  HeaderReducerInterface,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../back.svg";
import { ButtonProps } from "antd/lib/button";
import { RowProps } from "antd/lib/row";

const THEME: {
  content: {
    dark: string;
    light: string;
    transparent: string;
  };
  logout: {
    dark: string;
    light: string;
    transparent: string;
  };
} = {
  content: {
    dark: `
    background: linear-gradient(180deg, #020c42 0%, rgba(2, 12, 66, 0) 100%);
    * {
      color: white !important;
    }
  `,
    light: `
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    * {
      color: ${guidelines.colors.blue[0]} !important;
    }
  `,
    transparent: `
    background: transparent;
    * {
      color: white !important;
    }
    `,
  },
  logout: {
    dark: `
    color: white !important;
    `,
    light: `
    color: ${guidelines.colors.blue[0]} !important;
    `,
    transparent: `
    color: white !important;
    `,
  },
};

interface LogoutInterface
  extends ButtonProps,
    React.RefAttributes<HTMLElement> {
  mode?: "dark" | "light" | "transparent";
}

const Logout = styled(({ className, mode, ...props }: LogoutInterface) => (
  <Button type="link" className={className} {...props}>
    - logout -
  </Button>
))`
  ${(props) => THEME.logout[props.mode || "light"]}
`;

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
  header: HeaderReducerInterface;
  auth: UserInterface;
}) => ({
  windowSize: state.windowSize,
  header: state.header,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: (dispatch: any) => Promise<any>) => ({
  logout: () => dispatch(logout()),
});

let BackButton = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => (
  <div className={className} {...props}>
    <BackIcon />
  </div>
);

BackButton = styled(BackButton)`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 100%;
  }
`;

interface HeaderInterface
  extends RowProps,
    React.RefAttributes<HTMLDivElement> {
  header?: HeaderReducerInterface;
  auth?: AuthReducerInterface;
  logout: () => Promise<any>;
  span: number | string;
  windowSize?: WindowSizeReducerInterface;
}

const Header = styled(
  ({
    className,
    windowSize,
    header,
    span,
    logout,
    auth,
    ...props
  }: HeaderInterface) => {
    const navigate = useNavigate();

    return (
      <Row align="middle" justify="center" className={className} {...props}>
        <Col span={span}>
          <Row align="middle" justify={header?.justify}>
            <Col>
              <Row>
                {header?.backButton?.active && (
                  <BackButton
                    onClick={
                      header?.backButton?.onClick
                        ? header?.backButton?.onClick
                        : () => navigate(-1)
                    }
                  />
                )}
              </Row>
            </Col>
            <Col>
              <Row>
                {header?.logoutButton && (
                  <Logout mode={header?.mode} onClick={logout} />
                )}
                {!auth?.token ? (
                  <a href="https://funthriving.com">
                    <H2 exo fontWeight="400" marginTop="0" color=" ">
                      <span style={{ fontWeight: 500 }}>Fun</span>Thriving
                    </H2>
                  </a>
                ) : (
                  // <Link to="/">
                  <H2
                    cursor="pointer"
                    onClick={() => header?.logoButton?.() || navigate("/")}
                    exo
                    fontWeight="400"
                    marginTop="0"
                    color=" "
                  >
                    <span style={{ fontWeight: 500 }}>Fun</span>Thriving
                  </H2>
                  // </Link>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
)`
  ${(props) => THEME.content[props.header?.mode || "light"]}
  width: 100%;
  height: ${guidelines.headerPadding}px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  > .ant-col {
    max-width: 1240px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
