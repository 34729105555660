import React, { useEffect, useState } from "react";
import {
  shift,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
  useFloating,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
} from "@floating-ui/react-dom-interactions";
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { LoadingOutlined } from "@ant-design/icons";

const Menu = styled.div`
  background: white;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 6px;
`;

const Option = styled.div`
  border-radius: 4px;
  border: none;
  padding: 4px 11px;
  cursor: pointer;

  &:hover {
    background: #5377e2;
    color: white;
  }
`;

const Button = styled.button``;

function DropdownButton({
  children,
  options,
  ...props
}: {
  options: {
    type?: "outline" | "fill";
    status?: "loading";
    items: {
      name: string | React.ReactNode;
      onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
    }[];
  };
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, refs, update, context } =
    useFloating({
      open,
      onOpenChange: setOpen,
      placement: "bottom-end",
      middleware: [shift(), flip(), offset({ mainAxis: 6 })],
    });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: "menu" }),
    useDismiss(context),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  return (
    <div>
      <Button {...getReferenceProps({ ref: reference })} {...props}>
        {options.status === "loading" && (
          <LoadingOutlined style={{ marginRight: 10 }} />
        )}
        {children}
      </Button>
      {!options.status && (
        <FloatingPortal>
          {open && (
            <AnimatePresence>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  height: "auto",
                }}
                exit={{
                  height: 0,
                  opacity: 0,
                }}
                transition={{ type: "spring", damping: 20, stiffness: 300 }}
                {...getFloatingProps({
                  ref: floating,
                  className: "options",
                  style: {
                    position: strategy,
                    top: y ?? "",
                    left: x ?? "",
                  },
                })}
              >
                <Menu>
                  {options.items.map((option, key) => {
                    return (
                      <Option
                        key={key}
                        onClick={(e) => {
                          setOpen(false);
                          option.onClick?.(e);
                        }}
                      >
                        {option.name}
                      </Option>
                    );
                  })}
                </Menu>
              </motion.div>
            </AnimatePresence>
          )}
        </FloatingPortal>
      )}
    </div>
  );
}

export default styled(DropdownButton)`
  cursor: pointer;
  border: none;
  padding: 4px 15px;
  border-radius: 5px;
  display: inline-block;
  align-items: center;

  ${(props) =>
    props.options.type === "outline" &&
    css`
      border: 1px solid royalblue;
      color: royalblue;
      background: transparent;
    `}
  ${(props) =>
    props.options.type === "fill" &&
    css`
      color: white;
      background: royalblue;
    `}
`;
