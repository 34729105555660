import { guidelines } from "./guidelines";

export type SizeType = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export const responsive = (
  data: { [size in SizeType]?: any },
  width: number
): any => {
  let maxHeight: number = 0,
    response: any;
  if (typeof data === "object") {
    Object.keys(data).forEach((size) => {
      if (
        width >= guidelines.sizes[size as SizeType] &&
        guidelines.sizes[size as SizeType] >= maxHeight
      ) {
        maxHeight = guidelines.sizes[size as SizeType];
        response = data[size as SizeType];
      }
    });
  } else if (typeof data === "string") {
    response = data;
  }
  return response;
};
