import { FormInstance } from "antd";
import Axios from "axios";
import { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space } from "../../../components/layout";
import { Body2 } from "../../../components/typography";
import { activateFormModal, updateFormModal } from "../../../store/actions";
import { apiClient } from "../../config";
import { guidelines } from "../../guidelines";
import {
  FormModalReducerInterface,
  StorageReducerInterface,
} from "../../interfaces";
import { useShowError } from "../../useShowError";

export const useUspsFormAutocomplete = () => {
  const formRef = createRef<FormInstance>();
  const dispatch = useDispatch<any>();
  const auth = useSelector((store: StorageReducerInterface) => store.auth);
  const showError = useShowError();

  const options: FormModalReducerInterface = {
    formRef,
    title: "PS Form 1583",
    initialValues: {
      "2a_business_name": auth.default_company?.company.name,
      "2b_business_dbas": auth.default_company?.company.aliases || [],
      "12_business_members": [auth.name],
      "6_applicant_name": auth.name,
    },
    primaryAction: async (data) => {
      await dispatch(
        updateFormModal({ loadingPrimaryButton: true, alert: null })
      );
      await Axios.post(`${apiClient}/usps-form-download-pdf`, data, {
        responseType: "blob",
      })
        .then(async (res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.download = "PS Form 1583 (autofill).pdf";
          link.click();
          await dispatch(
            updateFormModal({
              loadingPrimaryButton: false,
              alert: {
                type: "info",
                message:
                  "Document generated successfully; you can now close this window.",
              },
            })
          );
        })
        .catch(showError);
    },
    items: [
      {
        type: "custom",
        data: (
          <>
            <Body2>
              If you wish, you can complete the following questions to
              autocomplete the PS Form 1583.
            </Body2>
            <Body2>
              This is a Beta feature, so please review carefully the final PS
              Form 1583 as you're solely responsible for providing USPS with the
              correct information.
            </Body2>
            <Space height={10} />
          </>
        ),
      },
      { type: "subtitle", data: "Business Information" },
      {
        type: "text",
        placeholder: "Name",
        name: "2a_business_name",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Name' is required",
          },
        ],
      },
      {
        type: "tags",
        name: "2b_business_dbas",
        label: "DBAs (optional, each one counts as a recipient)",
        placeholder: "New DBA",
      },
      {
        type: "text",
        placeholder: "Type of business",
        name: "11_type_of_business",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Type of business' is required",
          },
        ],
      },
      {
        type: "address",
        name: "10_business_address",
        placeholder: "Principal address",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Principal address' is required",
          },
        ],
      },
      {
        type: "text",
        placeholder: "Telephone number (include area code)",
        name: "10e_business_phone",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Telephone number' is required",
          },
        ],
      },
      {
        type: "tags",
        name: "12_business_members",
        label:
          "Members whose mail is to be delivered  (optional, each one counts as a recipient)",
        tooltip:
          "(All names listed must have verifiable identification. A guardian must list the names of minors receiving mail at their delivery address.)",
        placeholder: "New member",
      },
      {
        type: "custom",
        data: (
          <>
            <Body2>
              If business name (corporation or trade name) has been registered,
              give name of county and state, and date of registration.
            </Body2>
            <Space height={10} />
          </>
        ),
      },
      {
        type: "text",
        placeholder: "County",
        name: ["14_business_registration", "county"],
        md: 8,
      },
      {
        type: "text",
        placeholder: "State",
        name: ["14_business_registration", "state"],
        md: 8,
      },
      {
        type: "datepicker",
        placeholder: "Date",
        name: ["14_business_registration", "date"],
        md: 8,
      },
      { type: "subtitle", data: "Applicant Information" },
      {
        type: "text",
        placeholder: "Name",
        name: "6_applicant_name",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Name' is required",
          },
        ],
      },
      {
        type: "text",
        placeholder:
          "Type of ID no. 1 you will submit (must contain photography)",
        name: "8a_applicant_type_of_identification",
        tooltip:
          "Acceptable identification includes: valid driver's license or state non-driver's identification card; armed forces, government, university, or recognized corporate identification card; passport, alien registration card or certificate of naturalization; current lease, mortgage or Deed of Trust; voter or vehicle registration card; or a home or vehicle insurance policy. A photocopy of your identification may be retained by agent for verification.",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Type of ID no. 1' is required",
          },
        ],
      },
      {
        type: "text",
        placeholder: "Type of ID no. 2 you will submit",
        tooltip:
          "Acceptable identification includes: valid driver's license or state non-driver's identification card; armed forces, government, university, or recognized corporate identification card; passport, alien registration card or certificate of naturalization; current lease, mortgage or Deed of Trust; voter or vehicle registration card; or a home or vehicle insurance policy. A photocopy of your identification may be retained by agent for verification.",
        name: "8b_applicant_type_of_identification",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Type of ID no. 2' is required",
          },
        ],
      },
      {
        type: "address",
        name: "7_applicant_address",
        placeholder: "Home address",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Home address' is required",
          },
        ],
      },
      {
        type: "text",
        placeholder: "Telephone number (include Area Code)",
        name: "7e_applicant_phone",
        rules: [
          {
            type: "string",
            required: true,
            message: "'Telephone number' is required",
          },
        ],
      },
      {
        type: "checkbox",
        style: { paddingBottom: 20 },
        data: (
          <>
            <Body2>I understand that:</Body2>
            <Body2 style={{ color: guidelines.colors.grey[6] }}>
              1. Furnishing false or misleading information on PS Form 1583 or
              omission of material information may result in criminal sanctions
              (including fines and imprisonment) and/or civil sanctions
              (including multiple damages and civil penalties).
            </Body2>
            <Body2
              style={{
                color: guidelines.colors.grey[6],
                paddingBottom: 10,
              }}
            >
              2. Advans Investments, LLC, assumes no liability in providing this
              functionality; I must review carefully the final PS Form 1583 as
              I'm solely responsible for providing USPS with the correct
              information.
            </Body2>
          </>
        ),
        name: "terms",
        rules: [
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                    "You must read and mark this information as understood."
                  ),
          },
        ],
      },
    ],
  };

  return () => {
    dispatch(activateFormModal(options));
  };
};
