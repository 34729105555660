import { Row, RowProps } from "antd";
import React from "react";
import styled from "styled-components";
import { guidelines } from "../../utils";

let LineSpinFadeLoader: any = ({
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => {
  return (
    <Row justify="center" align="middle" {...props}>
      <div className="line-spin-fade-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Row>
  );
};

LineSpinFadeLoader = styled(LineSpinFadeLoader)`
  height: 60%;
  > * > div {
    background-color: ${guidelines.colors.red[0]};
  }
`;

export default LineSpinFadeLoader;
