import { Col, Row } from "antd";
import React from "react";
import { useTemporaryAccessCodeModal } from "../../utils/hooks/modals/useTemporaryAccessCodeModal";
import Button2 from "./Button2";

function LoginWithCodeButton() {
  const temporaryAccessCodeModal = useTemporaryAccessCodeModal();

  return (
    <Row>
      <Col span={24}>
        <Button2
          style={{ background: "#9a9a9a", border: "none" }}
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            temporaryAccessCodeModal();
          }}
        >
          Login with code
        </Button2>
      </Col>
    </Row>
  );
}

export default LoginWithCodeButton;
