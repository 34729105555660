import { Row, RowProps } from "antd";
import React from "react";
import styled from "styled-components";
import { guidelines } from "../../utils";

let BallClipRotate: any = ({
  ...props
}: RowProps & React.RefAttributes<HTMLDivElement>) => {
  return (
    <Row justify="center" align="middle" {...props}>
      <div className="ball-clip-rotate">
        <div></div>
      </div>
    </Row>
  );
};

BallClipRotate = styled(BallClipRotate)`
  height: 60%;
  > * > div {
    border: 2px solid ${guidelines.colors.red[0]};
    border-bottom-color: transparent;
  }
`;

export default BallClipRotate;
