import React, { useEffect, useState } from "react";

import { BallPulse, MailTable } from "../../components";
import { connect } from "react-redux";
import { filter, isEmpty, uniqBy, upperFirst } from "lodash";
import { TwoColumns } from "../../components/layout";
import {
  apiClient,
  CompanyInterface,
  MailItemInterface,
  AuthReducerInterface,
  MailItemStatusInterface,
} from "../../utils";
import Axios from "axios";
import Fuse from "fuse.js";
import useBackToHomeHeader from "../../utils/hooks/useBackToHomeHeader";
import { useQuery } from "@tanstack/react-query";

const MailStatuses: MailItemStatusInterface[] = [
  { id: 1, name: "all" },
  // { id: 2, name: "available", description: "" },
  // { id: 3, name: "deposited", description: "" },
  // { id: 4, name: "picked up", description: "" },
  // { id: 5, name: "scanning", description: "" },
  // { id: 6, name: "shipped", description: "" },
  // { id: 7, name: "shredded", description: "" },
];

const Mail = ({
  // match: {
  //   params: { status },
  // },
  auth,
}: {
  windowSize: { width: number };
  // match: { params: { status: string } };
  auth: AuthReducerInterface;
}) => {
  const [state, setState] = useState<{
    activeSection: number;
    activeSubsection: number;
    popup: {
      active: boolean;
      title?: string | null;
      description?: string | null;
      questions?: string | null;
      action?: string | null;
    };
    mails?: MailItemInterface[];
    company?: CompanyInterface;
  }>({
    activeSection: 0,
    activeSubsection: 0,
    popup: {
      active: false,
      title: null,
      description: null,
      questions: null,
      action: null,
    },
  });

  const [search, setSearch] = useState<string | undefined>();
  const [mails, setMails] = useState<MailItemInterface[] | undefined>();
  const [statuses, setStatuses] =
    useState<MailItemStatusInterface[]>(MailStatuses);

  const backToHomeHeader = useBackToHomeHeader();

  useEffect(() => {
    backToHomeHeader();
    // eslint-disable-next-line
  }, []);

  const { default_company } = auth;

  const { data } = useQuery<MailItemInterface[]>({
    queryKey: ["mail-items"],
    queryFn: () =>
      Axios.get(
        `${apiClient}/companies/${default_company?.company.id}/mail-items`
      ).then(({ data }: { data: MailItemInterface[] }) => {
        return data;
      }),
    enabled: !!default_company,
  });

  useEffect(() => {
    if (data) {
      setMails(data);

      let itemStatuses = uniqBy(
        filter(data.map((item) => item.statuses?.[0])),
        "name"
      ) as MailItemStatusInterface[];

      setStatuses([...MailStatuses, ...itemStatuses]);
    }
  }, [data]);

  const handleLeftMenuChange = (section: number) => {
    setState({
      ...state,
      activeSection: section,
    });
  };

  const handleTabMenuChange = (subsection: number) => {
    setState({
      ...state,
      activeSubsection: subsection,
    });
  };

  if (true) {
    const leftMenuItems = statuses.map((item) => upperFirst(item.name));
    let tabMenuItems: any | null = null;

    let items = mails?.filter((item) => {
      if (statuses[state.activeSection].name === "all") return item;

      return statuses[state.activeSection].id === item.statuses?.[0].id;
    });

    // switch (status) {
    //   case "deposited":
    //     tabMenuItems = filter(
    //       mails?.map((mail) => mail.deposit_statuses?.[0]?.status)
    //     )
    //       ?.sort((a, b) => (a && b ? a.id - b.id : 0))
    //       ?.map((status) => status && status.name);
    //     break;

    //   case "shredded":
    //     tabMenuItems = filter(
    //       mails?.map((mail) => mail.shredded_statuses?.[0]?.status)
    //     )
    //       ?.sort((a, b) => (a && b ? a.id - b.id : 0))
    //       ?.map((status) => status && status.name);
    //     break;

    //   default:
    //     break;
    // }

    let result: MailItemInterface[] = [];

    if (search && items) {
      const fuseOptions = {
        keys: ["date", "from", "type.name", "member.user.name"],
      };

      const fuse = new Fuse(items, fuseOptions);

      result = fuse.search(search).map((result) => result.item);
    }

    return (
      <>
        <TwoColumns
          leftMenu={{
            title: "Status",
            items: leftMenuItems,
            onLeftMenuChange: handleLeftMenuChange,
          }}
          tabMenu={{
            items: tabMenuItems,
            onLeftMenuChange: handleTabMenuChange,
          }}
          pageDescription={{
            title: "Mail",
            description:
              "Mail, packages, or checks we have received on your behalf in our facility.",
          }}
          onSearchInput={(value: string) => setSearch(value)}
        >
          <MailTable mails={!isEmpty(result) ? result : items} />
          {!mails && <BallPulse />}
        </TwoColumns>
      </>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: any) => ({
  windowSize: state.windowSize,
  subscriptions: state.subscriptions,
  auth: state.auth,
});

export default connect(mapStateToProps)(Mail);
