import React from "react";
import styled from "styled-components";

let Space = ({
  className,
}: {
  className?: string;
  width?: number;
  height?: number;
}) => {
  return <div className={className}></div>;
};

Space = styled(Space)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export default Space;
