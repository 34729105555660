import { useSelector } from "react-redux";
import { MemberInterface, StorageReducerInterface } from ".";

export const isCompanyAdmin = (default_company?: MemberInterface) => {
  return !!default_company?.roles.filter((role) => {
    return ["admin", "owner"].includes(role.name);
  }).length;
};

export const useIsCompanyAdmin = () => {
  const default_company = useSelector(
    (state: StorageReducerInterface) => state.auth.default_company
  );

  return isCompanyAdmin.bind(null, default_company);
};
