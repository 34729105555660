import { Col, Row } from "antd";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Body2 } from "../../../components";
import { Space } from "../../../components/layout";
import {
  storeSubscription,
  activateFormModal,
  updateFormModal,
} from "../../../store/actions";
import {
  PlanInterface,
  StorageReducerInterface,
  SubscriptionInterface,
} from "../../interfaces";
import { useShowError } from "../../useShowError";
import { useCongratulationsModal } from "./useCongratulationsModal";
import useSegment from "../useSegment";
import { ReactComponent as StripeClimateBadge } from "../../../img/Stripe Climate Badge.svg";
import CongratulationsImage from "../../../img/congratulations.png";
import DowngradeImage from "../../../img/downgraded.png";

const useActivateOrChangeSubscriptionModal = (callback: () => void) => {
  const dispatch = useDispatch<any>();
  const congratulations = useCongratulationsModal();
  const showError = useShowError();
  const auth = useSelector((store: StorageReducerInterface) => store.auth);
  const analytics = useSegment();

  const activateOrChangeSubscriptionModal = ({
    activePlans,
    plan,
    isActive,
    subscription,
  }: {
    activePlans?: PlanInterface[];
    plan: PlanInterface;
    isActive: boolean;
    subscription: SubscriptionInterface;
  }) =>
    !plan.message &&
    !isActive &&
    (() => {
      let description: React.ReactNode = (
          <>
            <span style={{ fontWeight: 500 }}>Great!</span>, you're about to
            subscribe to our{" "}
            <span style={{ fontWeight: 500 }}>{plan.nickname}</span> plan.
          </>
        ),
        image: string = CongratulationsImage,
        title: string,
        message: React.ReactNode = (
          <>
            <span style={{ fontWeight: 500 }}>
              Subscription is successfully activated
            </span>
            ; you'll receive an email shortly with your{" "}
            <span style={{ fontWeight: 500 }}>
              custom business address and steps to follow
            </span>{" "}
            to enjoy your plan benefits fully. Otherwise, please contact us at{" "}
            <a
              style={{ fontWeight: 500 }}
              href="mailto:support@funthriving.freshdesk.com"
            >
              support@funthriving.freshdesk.com
            </a>
            .
          </>
        );

      if (activePlans?.length) {
        let maxAmount = Math.max(
          ...activePlans.map((activePlan) => activePlan.amount)
        );

        let maxPlanPerAmount = activePlans.filter(
          (activePlan) => activePlan.amount === maxAmount
        )[0];

        if (maxAmount > plan.amount) {
          description = (
            <>
              You're about to <span style={{ fontWeight: 500 }}>downgrade</span>{" "}
              your subscription from{" "}
              <span style={{ fontWeight: 500 }}>
                {maxPlanPerAmount.nickname}
              </span>{" "}
              to <span style={{ fontWeight: 500 }}>{plan.nickname}</span> plan.{" "}
              <i>
                This change will apply at the{" "}
                <span style={{ fontWeight: 500 }}>
                  end of your billing period.
                </span>
              </i>
            </>
          );
          image = DowngradeImage;
          title = "Scheduled downgrade";
          message = (
            <>
              Subscription will be{" "}
              <span style={{ fontWeight: 500 }}>downgraded</span> at the{" "}
              <span style={{ fontWeight: 500 }}>
                end of your billing period
              </span>{" "}
              as requested.
            </>
          );
        } else {
          description = (
            <>
              You're about to <span style={{ fontWeight: 500 }}>upgrade</span>{" "}
              your subscription from{" "}
              <span style={{ fontWeight: 500 }}>
                {maxPlanPerAmount.nickname}
              </span>{" "}
              to <span style={{ fontWeight: 500 }}>{plan.nickname}</span> plan.
            </>
          );
          message = (
            <>
              <span style={{ fontWeight: 500 }}>
                Subscription successfully upgraded
              </span>
              ; you can now start enjoying the benefits of your plan.
            </>
          );
        }
      }

      dispatch(
        activateFormModal({
          title: subscription.name,
          description: description,
          items: [
            {
              type: "custom",
              data: (
                <>
                  <Space height={25} />
                  <Row align="middle">
                    <StripeClimateBadge style={{ width: 37.5, height: 37.5 }} />
                    <Space width={15} />
                    <Col flex="1">
                      <Body2 style={{ color: "#6A7383", marginTop: 0 }}>
                        FunThriving will contribute{" "}
                        <strong>1.5% of your subscription</strong> to remove CO₂
                        from the atmosphere.
                      </Body2>
                    </Col>
                  </Row>
                </>
              ),
            },
          ],
          price: `${(plan.amount / 100)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
          requiredDefaultCard: true,
          primaryAction: () => {
            dispatch(
              updateFormModal({
                loadingPrimaryButton: true,
              })
            );
            dispatch(
              storeSubscription({
                planId: plan.id,
                prodId: plan.product,
                callback,
              })
            )
              .then(async () => {
                await dispatch(
                  updateFormModal({
                    loadingPrimaryButton: false,
                  })
                );
                if (plan) {
                  Axios.get("https://ipapi.co/json/", {
                    headers: {
                      Accept: "application/json",
                      Authorization: "",
                    },
                  })
                    .then(({ data: { ip } }) =>
                      Axios.post(
                        "https://api.fomo.com/api/v1/applications/arCmUwXWDKmGYv3Iib88fg/webhooks/custom_webhook/b9964217096f5b6f26560ab9eeaec0f9",
                        {
                          name: auth.name,
                          email: auth.email,
                          plan: plan.nickname,
                          ip,
                        }
                      )
                    )
                    .catch((e) => console.log(e));

                  analytics
                    ?.track("Subscribed", {
                      plan: plan.nickname,
                      price: plan.amount / 100,
                      currency: plan.currency,
                    })
                    .catch((e) => console.log(e));
                }
                congratulations({ image, title, message });
              })
              .catch(showError);
          },
        })
      );
    });

  return activateOrChangeSubscriptionModal;
};

export default useActivateOrChangeSubscriptionModal;
