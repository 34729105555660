import { AxiosStatic } from "axios";
import { Dispatch } from "redux";
import { StorageReducerInterface } from "../../utils";
// import { getUserInfo } from "./authActions";

export const indexSubscriptions = () => {
  return (
    dispatch: Dispatch,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.get(
      `${apiClient}/companies/${default_company?.company.id}/subscriptions`
    ).then(({ data }) =>
      dispatch({
        type: "SET_SUBSCRIPTIONS",
        payload: data,
      })
    );
  };
};

export const storeSubscription = ({
  planId,
  prodId,
  callback,
}: {
  planId: string;
  prodId: string;
  callback: () => void;
}) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.post(
      `${apiClient}/companies/${default_company?.company.id}/subscriptions`,
      {
        plan_id: planId,
        prod_id: prodId,
      }
    ).then(() =>
      Axios.get(
        `${apiClient}/companies/${default_company?.company.id}/subscriptions`
      ).then(({ data }) => {
        dispatch({
          type: "SET_SUBSCRIPTIONS",
          payload: data,
        });
        callback();
      })
    );
  };
};

export const cancelSubscription = (subsId: string, callback: () => void) => {
  return (
    dispatch: Dispatch,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.post(
      `${apiClient}/companies/${default_company?.company.id}/subscriptions/${subsId}/cancel`
    ).then(() =>
      Axios.get(
        `${apiClient}/companies/${default_company?.company.id}/subscriptions`
      ).then(({ data }) => {
        dispatch({
          type: "SET_SUBSCRIPTIONS",
          payload: data,
        });
        callback();
      })
    );
  };
};

export const resetSubscriptions = () => {
  return (dispatch: (dispatch: any) => Promise<any>) => {
    return dispatch({
      type: "RESET_SUBSCRIPTIONS",
    });
  };
};
