import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import { guidelines } from "../../utils";
import { ButtonProps } from "antd/lib/button";

const Button2 = ({
  className,
  children,
  marginTop,
  ...props
}: ButtonProps & { marginTop?: number }) => {
  return (
    <Button className={className} {...props}>
      {children}
    </Button>
  );
};

export default styled(Button2)`
  width: 100%;
  border-radius: ${guidelines.borderRadius[0]}px;
  margin-top: ${(props) =>
    props.marginTop ? props.marginTop : guidelines.margin[0]}px;
`;
