import React, { useCallback, useEffect, useState } from "react";
import { BallPulse, Company, User } from "../../components";
import { apiAdmin, CompanyInterface, UserInterface } from "../../utils";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import { TwoColumns } from "../../components/layout";
import { useNavigate } from "react-router-dom";
import useBackToHomeHeader from "../../utils/hooks/useBackToHomeHeader";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";

const Customers = () => {
  let [state, setState] = useState({
    activeSection: 0,
    activeSubsection: 0,
    search: "",
    leftMenuItems: ["Companies", "People"],
    tabMenuItems: ["With Subscription", "All"],
  });

  const navigate = useNavigate();

  const backToHomeHeader = useBackToHomeHeader();

  const { data: companies } = useQuery<{ [key: string]: CompanyInterface[] }>({
    queryKey: ["admin", "companies"],
    queryFn: () =>
      Axios.get(`${apiAdmin}/companies`, {
        params: { filter: "grouped" },
      }).then((res) => {
        const { data } = res;
        return data;
      }),
  });

  const { data: users } = useQuery<{ [key: string]: CompanyInterface[] }>({
    queryKey: ["admin", "users"],
    queryFn: () =>
      Axios.get(`${apiAdmin}/users`, {
        params: { filter: "grouped" },
      }).then((res) => {
        const { data } = res;
        return data;
      }),
  });

  useEffect(() => {
    const init = async () => {
      backToHomeHeader();
    };

    init();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const handleLeftMenuChange = async (section: number) => {
    state = {
      ...state,
      activeSection: section,
    };

    setState(state);
  };

  const handleTabMenuChange = (subsection: number) => {
    setState({
      ...state,
      activeSubsection: subsection,
    });
  };

  const {
    activeSection,
    activeSubsection,
    search,
    leftMenuItems,
    tabMenuItems,
  } = state;

  useEffect(() => {
    if (activeSection === 0 && companies) {
      setState({
        ...state,
        tabMenuItems: Object.keys(companies),
      });
    } else if (activeSection === 1 && users) {
      setState({
        ...state,
        tabMenuItems: Object.keys(users),
      });
    }

    // eslint-disable-next-line
  }, [activeSection, users, companies]);

  const result = useCallback(() => {
    let result: (CompanyInterface | UserInterface)[] | undefined;

    switch (activeSection) {
      case 0:
        if (search && companies?.[tabMenuItems[activeSubsection]]) {
          const fuseOptions = {
            keys: ["name", "aliases", "subscriptions", "pmb"],
            includeScore: true,
            shouldSort: true,
            useExtendedSearch: true,
          };
          const fuse = new Fuse(
            companies[tabMenuItems[activeSubsection]],
            fuseOptions
          );
          result = fuse.search(search).map((result) => result.item);
        } else {
          result = companies?.[tabMenuItems[activeSubsection]];
        }
        break;

      case 1:
        if (search && users?.[tabMenuItems[activeSubsection]]) {
          const fuseOptions = {
            keys: [
              "name",
              "email",
              "companies_with_subscriptions.name",
              "companies_with_subscriptions.pmb",
            ],
          };
          const fuse = new Fuse(
            users[tabMenuItems[activeSubsection]],
            fuseOptions
          );
          result = fuse.search(search).map((result) => result.item);
        } else {
          result = users?.[tabMenuItems[activeSubsection]];
        }
        break;

      default:
        break;
    }

    return result;
  }, [tabMenuItems, activeSection, activeSubsection, search, companies, users]);

  return (
    <TwoColumns
      leftMenu={{
        title: "Type",
        items: leftMenuItems,
        onLeftMenuChange: handleLeftMenuChange,
      }}
      tabMenu={{
        items: tabMenuItems,
        onLeftMenuChange: handleTabMenuChange,
      }}
      onSearchInput={(value: string) => setState({ ...state, search: value })}
    >
      {activeSection === 0 ? (
        isEmpty(companies) ? (
          <BallPulse />
        ) : (
          (result() as CompanyInterface[])?.map((company) => (
            <Company
              onClick={() => navigate(`/customers/${company.id}`)}
              key={company.id}
              data={company}
            />
          ))
        )
      ) : null}
      {activeSection === 1 ? (
        isEmpty(users) ? (
          <BallPulse />
        ) : (
          (result() as UserInterface[])?.map((user) => (
            <User key={user.id} data={user} />
          ))
        )
      ) : null}
    </TwoColumns>
  );
};

export default Customers;
