import React, { ReactText } from "react";
import styled from "styled-components";
import {
  guidelines,
  responsive,
  WindowSizeReducerInterface,
} from "../../utils";
import { connect } from "react-redux";
import { Dispatch } from "redux";

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
}) => ({
  windowSize: state.windowSize,
});

interface Body2Interface
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  className?: string;
  children?: React.ReactNode;
  color?: string;
  marginTop?: number | string | ReactText;
  marginBottom?: number | string | ReactText;
  notera?: boolean;
  fontWeight?: number;
  textAlign?: string;
  cursor?: string;
  windowSize?: WindowSizeReducerInterface;
  dispatch: Dispatch;
}

let body2 = ({
  children,
  className,
  marginTop,
  marginBottom,
  windowSize,
  textAlign,
  dispatch,
  ...props
}: Body2Interface) => {
  return (
    <p className={className} {...props}>
      {children}
    </p>
  );
};

export const Body2 = connect(mapStateToProps)(styled(body2)`
  hyphens: auto;
  color: ${(props) => (props.color ? props.color : guidelines.colors.blue[0])};
  margin-top: ${(props) =>
    props.marginTop ? props.marginTop : guidelines.margin[0]}px;
  margin-bottom: unset;
  font-family: ${(props) => (props.notera ? "Notera" : null)};
  font-size: ${(props) =>
    responsive(guidelines.fontSize.body2, props.windowSize?.width as number)}px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : null)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : null)};
  cursor: ${(props) => (props.cursor ? props.cursor : null)};
`);
