import { HeaderReducerInterface } from "../../utils";

export const updateHeaderData = ({
  mode,
  justify = "space-between",
  backButton,
  logoutButton = true,
  logoButton,
}: HeaderReducerInterface) => {
  return (dispatch: any) => {
    return new Promise((resolve) => {
      dispatch({
        type: "UPDATE_HEADER_DATA",
        payload: {
          mode,
          justify,
          backButton,
          logoutButton,
          logoButton,
        },
      });
      resolve(0);
    });
  };
};
