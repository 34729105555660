import React, { useRef, useState } from "react";
import {
  Form as FormBase,
  Input,
  Col,
  Row,
  Checkbox,
  Select,
  Spin,
  ColProps,
  Tooltip,
} from "antd";
import styled from "styled-components";
import { guidelines } from "../../utils";
import { Alert } from "../feedback";
import { H1, Body1, Price, H2, Body2 } from "../typography";
import { FormInstance, Rule } from "antd/lib/form";
import { BaseSelectRef } from "rc-select";
import { InternalNamePath, Store } from "antd/lib/form/interface";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { find } from "lodash";
import { Moment } from "moment";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tags } from "./";
import SearchAddress from "../inputs/SearchAddress";
import { AlertInterface } from "../feedback/Alert";
import Phone from "../inputs/Phone";
import { Space } from "../layout";
import { DatePicker } from "../inputs";

const THEME = {
  popup: `
  padding: ${guidelines.padding[3]}px;
  background: white;
  border-radius: ${guidelines.borderRadius[0]}px;
  `,
};

export interface FormInterface {
  className?: string;
  title?: string | null;
  description?: React.ReactNode | null;
  price?: number | string | null;
  loading?: boolean;
  alert?: AlertInterface | null;
  initialValues?: Store;
  onFieldsChange?: (changedFields: any, allFields: any) => void;
  onValuesChange?: (changedValues: any, values: any) => void;
  items?: FormItemInterface[] | null;
  Buttons?: (props: any) => any;
  onFinish?: (values: any) => void;
  form?: FormInstance<any>;
  formRef?:
    | ((instance: FormInstance<any> | null) => void)
    | React.RefObject<FormInstance<any>>
    | null;
  marginTop?: number;
  type?: "popup";
}

export type FormItemInterface =
  | SubtitleItemInterface
  | ParagraphItemInterface
  | PasswordInputInterface
  | TagInputInterface
  | DatePickerInputInterface
  | CheckboxInputInterface
  | SelectInputInterface
  | CustomItemInterface
  | DefaultInputInterface
  | AddressInputInterface
  | PhoneInputInterface;

interface GeneralFormItemInterface
  extends Pick<ColProps, "xs" | "sm" | "md" | "lg" | "xl"> {
  type?:
    | string
    | "subtitle"
    | "paragraph"
    | "password"
    | "datepicker"
    | "select"
    | "custom";
  span?: number;
  flex?: string | number | undefined;
  offset?: string | number;
  style?: React.CSSProperties;
}
interface TextItemInterface extends GeneralFormItemInterface {
  data: React.ReactNode;
  marginBottom?: number | string;
}

interface SubtitleItemInterface extends TextItemInterface {
  type: "subtitle";
}

interface ParagraphItemInterface extends TextItemInterface {
  type: "paragraph";
}

export interface InputInterface {
  name: string | number | InternalNamePath;
  rules?: Rule[];
  hidden?: boolean;
  placeholder?: string | React.ReactNode;
  label?: string | React.ReactNode;
  tooltip?: string;
  disabled?: boolean;
}

interface DefaultInputInterface extends TextInputInterface {
  type?: "email" | "text" | "password" | "number";
}

interface PasswordInputInterface extends TextInputInterface {
  type: "password";
}

interface TagInputInterface extends GeneralFormItemInterface, InputInterface {
  type: "tags";
  placeholder?: string;
}

interface DatePickerInputInterface
  extends GeneralFormItemInterface,
    InputInterface {
  type: "datepicker";
  placeholder?: string;
  disableDate?: ((date: Moment) => boolean) | undefined;
}

interface TextInputInterface extends GeneralFormItemInterface, InputInterface {
  placeholder?: string;
  prefix?: React.ReactNode;
}

interface CheckboxInputInterface
  extends GeneralFormItemInterface,
    InputInterface {
  type: "checkbox";
  data: (string | CheckboxOptionType)[] | React.ReactNode;
}

export interface SelectInputInterface
  extends GeneralFormItemInterface,
    InputInterface {
  type: "select";
  ref?: React.Ref<BaseSelectRef>;
  placeholder?: React.ReactNode;
  fetching?: boolean;
  onSearch?: (value: string) => void;
  allowClear?: boolean;
  data: {
    hidden?: boolean;
    value: React.ReactText;
    label: string;
  }[];
  mode?: "multiple" | "tags";
}

export interface PhoneInputInterface
  extends GeneralFormItemInterface,
    InputInterface {
  type: "phone";
}

export interface AddressInputInterface
  extends GeneralFormItemInterface,
    InputInterface {
  type: "address";
  placeholder?: React.ReactNode;
  allowClear?: boolean;
}

interface CustomItemInterface extends GeneralFormItemInterface {
  type: "custom";
  data: React.ReactNode;
}

const Form = ({
  className,
  title,
  description,
  price,
  loading = false,
  alert = null,
  initialValues,
  onFieldsChange,
  onValuesChange,
  items = [],
  Buttons,
  onFinish,
  formRef,
}: FormInterface) => {
  const { Option } = Select;
  const { Item } = FormBase;

  const [form] = FormBase.useForm();

  const ref = useRef(form);

  const [inputValues, setInputValues] = useState<any>();

  const itemList =
    items &&
    items.map((itemParams, index) => {
      let input: React.ReactNode = null;

      const {
        type,
        span = 24,
        offset,
        flex,
        style,
        xs,
        sm,
        md,
        lg,
        xl,
      } = itemParams;

      switch (type) {
        case "subtitle":
          itemParams = itemParams as SubtitleItemInterface;
          input = (
            <H2
              marginTop="0"
              marginBottom={itemParams.marginBottom ?? guidelines.margin[0]}
            >
              {itemParams.data}
            </H2>
          );
          break;

        case "paragraph":
          itemParams = itemParams as ParagraphItemInterface;
          input = (
            <Body1
              marginBottom={itemParams.marginBottom ?? guidelines.margin[0]}
            >
              {itemParams.data}
            </Body1>
          );
          break;

        case "phone":
          itemParams = itemParams as PhoneInputInterface;
          input = (
            <Item
              hidden={itemParams.hidden}
              name={itemParams.name}
              rules={itemParams.rules}
            >
              <Phone
                // initialValue={get(initialValues, itemParams.name)}
                style={{ columnGap: 5 }}
              />
            </Item>
          );
          break;

        case "datepicker":
          itemParams = itemParams as DatePickerInputInterface;

          input = (
            <Item
              hidden={itemParams.hidden}
              name={itemParams.name}
              rules={itemParams.rules}
            >
              <DatePicker
                placeholder={itemParams.placeholder}
                bordered={false}
                disabledDate={itemParams.disableDate}
              />
            </Item>
          );
          break;

        case "password":
          itemParams = itemParams as PasswordInputInterface;
          input = (
            <Item
              hidden={itemParams.hidden}
              name={itemParams.name}
              rules={itemParams.rules}
              hasFeedback
            >
              <Input.Password
                bordered={false}
                prefix={itemParams.prefix}
                placeholder={itemParams.placeholder}
              />
            </Item>
          );
          break;

        case "checkbox":
          itemParams = itemParams as CheckboxInputInterface;
          if (Array.isArray(itemParams.data)) {
            input = (
              <Item
                hidden={itemParams.hidden}
                name={itemParams.name}
                rules={itemParams.rules}
                valuePropName="checked"
              >
                <Checkbox.Group options={itemParams.data} />
              </Item>
            );
          } else {
            input = (
              <Item
                hidden={itemParams.hidden}
                name={itemParams.name}
                rules={itemParams.rules}
                valuePropName="checked"
              >
                <Checkbox>{itemParams.data}</Checkbox>
              </Item>
            );
          }
          break;

        case "tags":
          itemParams = itemParams as TagInputInterface;
          input = (
            <Item
              hidden={itemParams.hidden}
              name={itemParams.name}
              rules={itemParams.rules}
            >
              <Tags
                placeholder={itemParams.placeholder}
                form={form}
                name={itemParams.name}
                disabled={itemParams.disabled}
              />
            </Item>
          );
          break;

        case "address":
          itemParams = itemParams as AddressInputInterface;
          input = (
            <Item
              hidden={itemParams.hidden}
              name={itemParams.name}
              rules={itemParams.rules}
            >
              <SearchAddress
                placeholder={itemParams.placeholder}
                bordered={false}
                showSearch
              />
            </Item>
          );
          break;

        case "select":
          itemParams = itemParams as SelectInputInterface;
          if (Array.isArray(itemParams.data)) {
            let optionList = itemParams.data.map(
              (
                option: {
                  hidden?: boolean;
                  value: React.ReactText;
                  label: string;
                },
                index: number
              ) => (
                <Option hidden={option.hidden} key={index} value={option.value}>
                  {option.label || option.value}
                </Option>
              )
            );
            input = (
              <Item
                hidden={itemParams.hidden}
                name={itemParams.name}
                rules={itemParams.rules}
              >
                <Select
                  ref={itemParams.ref}
                  bordered={false}
                  showSearch
                  onSearch={itemParams.onSearch}
                  allowClear={itemParams.allowClear}
                  optionFilterProp="children"
                  notFoundContent={
                    itemParams.fetching ? <Spin size="small" /> : null
                  }
                  mode={itemParams.mode}
                  placeholder={itemParams.placeholder}
                >
                  {optionList}
                </Select>
              </Item>
            );
          } else {
            input = (
              <Item
                hidden={itemParams.hidden}
                name={itemParams.name}
                rules={itemParams.rules}
              >
                <Select
                  showSearch
                  allowClear={itemParams.allowClear}
                  notFoundContent={
                    itemParams.fetching ? <Spin size="small" /> : null
                  }
                  mode={itemParams.mode}
                  placeholder={itemParams.placeholder}
                ></Select>
              </Item>
            );
          }
          break;

        case "custom":
          itemParams = itemParams as CustomItemInterface;
          input = itemParams.data;
          break;

        default:
          itemParams = itemParams as DefaultInputInterface;
          input = (
            <Item
              hidden={itemParams.hidden}
              name={itemParams.name}
              rules={itemParams.rules}
              hasFeedback
            >
              <Input
                type=""
                prefix={itemParams.prefix}
                placeholder={itemParams.placeholder}
                bordered={false}
                disabled={itemParams.disabled}
              />
            </Item>
          );
          break;
      }

      if (input) {
        let { tooltip, name, hidden, label, placeholder } =
          itemParams as InputInterface;
        return (
          <Col
            span={span}
            flex={flex}
            style={style}
            offset={offset}
            key={index}
            className="item"
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
          >
            {name && !hidden ? (
              <div className="input">
                {(find(inputValues, {
                  name: Array.isArray(name) ? name : [name],
                })?.value ||
                  (Array.isArray(name) ? name : [name]).reduce(
                    (o, i) => o?.[i],
                    initialValues
                  ) !== undefined) && (
                  <Body2 className="label" style={{ marginTop: 0 }}>
                    {label || placeholder}
                  </Body2>
                )}
                <Row align="middle">
                  {tooltip && (
                    <Tooltip
                      placement="topLeft"
                      className="tooltip"
                      title={tooltip}
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  )}
                  <Col flex="1">{input}</Col>
                </Row>
              </div>
            ) : (
              input
            )}
          </Col>
        );
      } else {
        return null;
      }
    });

  return (
    <FormBase
      ref={formRef || ref}
      form={form}
      className={className}
      initialValues={initialValues}
      onFieldsChange={(changedFields, allFields) => {
        onFieldsChange?.(changedFields, allFields);
        setInputValues(allFields);
      }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      onFinishFailed={(errorInfo) => {
        console.log("Failed:", errorInfo);
      }}
      scrollToFirstError
    >
      <Row className="content">
        <Col span={24}>
          {title && (
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  width: 10,
                  minHeight: 60,
                  backgroundColor: "#D5DEF3",
                  borderRadius: 100,
                }}
              />
              <Space width={12} />
              <H1 style={{ marginTop: 0 }} color={guidelines.colors.blue[0]}>
                {title}
              </H1>
            </div>
          )}
          <Body1 className="description">{description}</Body1>
          {price && <Price suffix="plus tax / month">${price}</Price>}
          <Row className="inputs" gutter={10}>
            {itemList}
          </Row>
        </Col>
      </Row>
      {alert ? (
        <Alert
          style={{ marginBottom: `${guidelines.margin[1]}px` }}
          {...alert}
        />
      ) : null}
      {Buttons && <Buttons loading={loading} />}
    </FormBase>
  );
};

export default styled(Form)`
  width: 100%;

  margin-top: ${(props) =>
    props.marginTop ? props.marginTop : guidelines.margin[3]}px;

  .content  {
    ${(props) => (props.type ? THEME[props.type] : null)}
  }

  .inputs {
    margin-top: ${guidelines.margin[0]}px;

    .item {
      .input {
        margin-bottom: 10px;
        min-height: 44px;
        border: 1px solid rgb(217, 217, 217);
        border-radius: 6px;
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .tooltip {
          color: ${guidelines.colors.grey[4]};
          margin-right: 5px;
        }

        .ant-checkbox {
          margin-right: 10px;
        }

        .ant-picker.ant-picker-borderless {
          width: 100%;
        }

        .ant-form-item {
          margin-bottom: 0;
          .ant-select-selection-item {
            svg {
              /* display: none; */
            }
          }
          .ant-select-selection-search {
            left: 0;
          }
          .ant-picker,
          .ant-select-selector,
          .ant-input-affix-wrapper,
          .ant-form-item-control-input {
            min-height: 0;
            padding: 0;
          }
          .ant-select-arrow,
          .ant-select-clear {
            right: 0;
          }
        }

        .ant-select-selector {
          border-width: 0;
        }

        .label {
          color: grey;
          font-size: 10px;
        }
      }
    }
  }

  // .ant-form-item-control-input-content > .ant-input,
  // .ant-input-password,
  // .ant-select-selector,
  // .ant-input-affix-wrapper {
  //   border-radius: ${guidelines.borderRadius[0]}px !important;
  // }

  .ant-select-selector {
    display: flex;
    align-items: center;
  }
`;
