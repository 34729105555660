import { Select, SelectProps, Spin } from "antd";
import Axios from "axios";
import React, { useState } from "react";
import { apiClient, useShowError } from "../../utils";

let timeout: NodeJS.Timeout | undefined;

function SearchAddress(props: SelectProps) {
  const [data, setData] = useState<{ description: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const showError = useShowError();

  const getPredictions = async (input: string) => {
    if (input) {
      const res = await Axios.post<{ description: string }[]>(
        `${apiClient}/google-maps/predictions`,
        {
          input,
        }
      ).catch(showError);
      const predictions = res?.data || [];
      setData(predictions);
    }
  };

  const { Option } = Select;

  return (
    <Select
      {...props}
      notFoundContent={loading ? <Spin size="small" /> : undefined}
      allowClear
      optionFilterProp="children"
      filterOption={false}
      onSearch={(value) => {
        timeout && clearTimeout(timeout);

        if (value) {
          setLoading(true);
          timeout = setTimeout(async () => {
            await getPredictions(value);
            setLoading(false);
          }, 500);
        }
      }}
    >
      {data.map((prediction, index) => (
        <Option key={index} value={prediction.description}>
          {prediction.description}
        </Option>
      ))}
    </Select>
  );
}

export default SearchAddress;
