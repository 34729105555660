import { Row } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  activateFormModal,
  deactivateFormModal,
  getUserInfo,
  updateFormModal,
  voipSetup,
} from "../../../store/actions";
import { useIsCompanyAdmin } from "../../useIsCompanyAdmin";

export const useCongratulationsModal = () => {
  const dispatch = useDispatch<any>();

  const navigate = useNavigate();
  const isCompanyAdmin = useIsCompanyAdmin();

  const congratulations = ({
    image,
    title,
    message,
  }: {
    image: string;
    title?: string;
    message: React.ReactNode;
  }) => {
    dispatch(
      activateFormModal({
        closeable: false,
        items: [
          {
            type: "custom",
            data: (
              <>
                {image && (
                  <Row justify="center">
                    <img width={250} src={image} alt="" />
                  </Row>
                )}
              </>
            ),
            style: {
              marginBottom: 10,
            },
          },
          {
            type: "subtitle",
            data: title || "Congratulations!",
            style: {
              paddingLeft: "5%",
              paddingRight: "5%",
            },
          },
          {
            type: "paragraph",
            data: message,
            style: {
              paddingLeft: "5%",
              paddingRight: "5%",
            },
          },
        ],
        primaryAction: async () => {
          await dispatch(
            updateFormModal({
              loadingPrimaryButton: true,
            })
          );
          await dispatch(getUserInfo({ voip: false }));
          await dispatch(deactivateFormModal());
          if (isCompanyAdmin()) dispatch(voipSetup());

          navigate("/");
        },
      })
    );
  };

  return congratulations;
};
