import React from "react";
import styled from "styled-components";
import { Col, ColProps } from "antd";
import { Body1 } from "../typography";
import { guidelines } from "../../utils";

const LeftMenuItem = ({
  className,
  children,
  active,
  ...props
}: { active: boolean } & ColProps & React.RefAttributes<HTMLDivElement>) => {
  return (
    <Col span={24} className={className} {...props}>
      <Body1 color="white" textAlign="center" cursor="pointer">
        {children}
      </Body1>
    </Col>
  );
};

export default styled(LeftMenuItem)`
  border-bottom: ${(props) => (props.active ? "1px solid white" : "")};
  padding-bottom: ${guidelines.padding[0]}px;
`;
