import React from "react";
import styled from "styled-components";
import { guidelines } from "../../utils";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";

const Button1 = ({ className, children, loading, ...props }: ButtonProps) => {
  return (
    <Button loading={loading} className={className} {...props}>
      {children}
    </Button>
  );
};

export default styled(Button1)`
  font-size: ${guidelines.fontSize.body1.xs}px;
  color: ${(props) => props.color ?? guidelines.colors.grey[0]};
  border-radius: ${guidelines.borderRadius[0]}px;
  background: transparent !important;
  padding: 5px 25px;
  border: 1px solid #0e184c;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
