import React from "react";
import { Row, Col, notification, message } from "antd";
import styled from "styled-components";
import { H1, MenuButton1, MenuButton2 } from "../";
import {
  FormModalReducerInterface,
  guidelines,
  responsive,
  useIsCompanyAdmin,
  UserInterface,
  useShowError,
  VoipInterface,
  WindowSizeReducerInterface,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateUserInfo,
  activateFormModal,
  updateFormModal,
  deactivateFormModal,
  getVoip,
  updateVoip,
} from "../../store/actions";
import { VoIP } from "../voip";
import { isEmpty, isEqual } from "lodash";
import { FormItemInterface } from "../form/Form";
import { RowProps } from "antd/lib/row";
import { isValidPhoneNumber } from "libphonenumber-js";

const unauthorizedNotification = () => {
  notification["info"]({
    message: "Unauthorized",
    description: "The owner or admin didn't allow you to access this section.",
  });
};

const covid19Notification = (name: string) => {
  notification["info"]({
    message: name,
    description: "Temporarily unavailable.",
  });
};

const Header = styled(({ className }: RowProps) => (
  <Row className={className}>
    <Col span={24}>
      <H1 color="white">Menu</H1>
    </Col>
  </Row>
))``;

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
  auth: UserInterface;
  voip: VoipInterface;
}) => ({
  windowSize: state.windowSize,
  auth: state.auth,
  voip: state.voip,
});

const mapDispatchToProps = (dispatch: (dispatch: any) => Promise<any>) => ({
  activateFormModal: (data: FormModalReducerInterface) =>
    dispatch(activateFormModal(data)),
  updateFormModal: (data: FormModalReducerInterface) =>
    dispatch(updateFormModal(data)),
  deactivateFormModal: () => dispatch(deactivateFormModal()),
  updateUserInfo: (data: UserInterface) => dispatch(updateUserInfo(data)),
  getVoip: () => dispatch(getVoip()),
  updateVoip: (data: VoipInterface) => dispatch(updateVoip(data)),
});

interface MenuClientInterface
  extends RowProps,
    React.RefAttributes<HTMLDivElement> {
  auth: UserInterface;
  voip: VoipInterface;
  windowSize: WindowSizeReducerInterface;
  activateFormModal: (data: FormModalReducerInterface) => Promise<any>;
  updateFormModal: (data: FormModalReducerInterface) => Promise<any>;
  deactivateFormModal: () => Promise<any>;
  updateUserInfo: (data: UserInterface) => Promise<any>;
  getVoip: () => Promise<any>;
  dispatch?: (dispatch: any) => Promise<any>;
  updateVoip: (data: Partial<VoipInterface>) => Promise<any>;
}

const MenuClient = ({
  className,
  windowSize,
  dispatch,
  activateFormModal,
  updateFormModal,
  deactivateFormModal,
  updateUserInfo,
  auth: { name, email, phone_number: phoneNumber },
  auth,
  voip: { voice_app: voiceApp },
  voip,
  getVoip,
  updateVoip,
  ...props
}: MenuClientInterface) => {
  const showError = useShowError();
  const navigate = useNavigate();
  const isCompanyAdmin = useIsCompanyAdmin();
  const handleAccountButtonClick = () => {
    let items: FormItemInterface[] = [
        {
          name: "name",
          placeholder: "Name",
          rules: [
            {
              required: true,
              message: "Please input your Name!",
            },
            {
              min: 2,
              message: "Please input at least 2 characters!",
            },
          ],
        },
        {
          name: "email",
          placeholder: "Email",
          rules: [
            {
              type: "email",
              message: "The input is not valid Email!",
            },
            {
              required: true,
              message: "Please input your Email!",
            },
          ],
        },
      ],
      initialValues: any = {
        name: name,
        email: email,
      };

    if (phoneNumber) {
      items = [
        ...items,
        {
          name: "phone_number",
          placeholder: "Phone number",
          type: "phone",
          // prefix: "+",
          rules: [
            {
              validator: (_, value) => {
                return isValidPhoneNumber(value)
                  ? Promise.resolve()
                  : Promise.reject("Please input a valid phone number.");
              },
            },
            {
              transform: (value) => String(parseInt(value)),
              max: 15,
              message: "Please input a maximum of 15 characters!",
            },
            {
              required: true,
              message: "Please input a Phone Number!",
            },
          ],
        },
      ];

      initialValues = {
        ...initialValues,
        phone_number: `+${phoneNumber}`,
      };
    }

    items = [
      ...items,
      { type: "subtitle", data: "Password" },
      {
        type: "password",
        name: "password",
        placeholder: "Change password",
        rules: [
          {
            min: 8,
            message: "Please input at least 8 characters!",
          },
        ],
      },
    ];

    activateFormModal({
      title: "Account",
      description: "You're about to update your profile info or password",
      initialValues,
      items,
      primaryAction: (values) => {
        let data: UserInterface = {};

        Object.entries(values).forEach(([key, value]) => {
          if ((auth as any)[key] !== value) {
            (data as any)[key] = value;
          }
        });

        updateFormModal({
          loadingPrimaryButton: true,
        });
        updateUserInfo(data)
          .then(() => {
            updateFormModal({
              loadingPrimaryButton: false,
            });
            deactivateFormModal().then(() =>
              message.success("Your account was successfully updated.")
            );
          })
          .catch(showError);
      },
    });
  };

  const handleVoIPButtonClick = () => {
    let items: FormItemInterface[] = [],
      initialValues;

    if (voiceApp) {
      items = [
        {
          type: "custom",
          data: <VoIP />,
        },
      ];

      initialValues = {
        voice_app: voiceApp,
      };
    }

    activateFormModal({
      title: "Phone",
      description: "You're about to update your phone settings",
      initialValues,
      items,
      primaryAction: (values: Partial<VoipInterface>) => {
        let data: Partial<VoipInterface> = {};

        Object.entries(values).forEach(([key, value]) => {
          if (!isEqual((voip as any)[key], value)) {
            (data as any)[key] = value;
          }
        });

        updateFormModal({
          loadingPrimaryButton: true,
        });

        if (isEmpty(data)) {
          deactivateFormModal().then(() =>
            message.success("Your phone was successfully updated.")
          );
        } else {
          updateVoip(data)
            .then(() => {
              updateFormModal({
                loadingPrimaryButton: false,
              });
              deactivateFormModal().then(() =>
                message.success("Your phone was successfully updated.")
              );
            })
            .catch(showError);
        }
      },
    });
  };

  return (
    <Row
      gutter={[guidelines.spacing[0], guidelines.spacing[0]]}
      className={className}
      {...props}
    >
      <Col span={24}>
        <Header />
      </Col>
      {(isCompanyAdmin() && (
        <Col xs={{ span: 24 }} md={{ span: 23 }}>
          <Row
            gutter={responsive(
              {
                xs: [15, 15],
              },
              windowSize.width
            )}
          >
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <Row
                gutter={responsive(
                  {
                    xs: [15, 15],
                  },
                  windowSize.width
                )}
              >
                <Col span={12}>
                  <MenuButton1
                    onClick={handleAccountButtonClick}
                    className="button"
                    style={{ height: 90 }}
                  >
                    Account
                  </MenuButton1>
                </Col>
                <Col span={12}>
                  <MenuButton1
                    className="button"
                    style={{
                      height: 90,
                      color: "white",
                      backgroundColor: guidelines.colors.yellow[0],
                    }}
                    onClick={() => navigate("/mail")}
                  >
                    Mail
                  </MenuButton1>
                </Col>
                <Col span={24}>
                  <MenuButton2
                    onClick={() => covid19Notification("Services shop")}
                    className="button"
                    style={{ height: 90 }}
                  >
                    Services <br />
                    <span style={{ fontWeight: 600 }}>shop *</span>
                  </MenuButton2>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <Row
                gutter={responsive(
                  {
                    xs: [15, 15],
                  },
                  windowSize.width
                )}
                style={{ height: "100%" }}
              >
                <Col span={24}>
                  <MenuButton1
                    className="button"
                    style={{
                      height: responsive(
                        { xs: 90, md: "100%" },
                        windowSize.width
                      ),
                      backgroundColor: guidelines.colors.red[0],
                      color: "white",
                    }}
                    onClick={() => {
                      isCompanyAdmin()
                        ? navigate("/subscriptions")
                        : unauthorizedNotification();
                    }}
                  >
                    Subscriptions
                  </MenuButton1>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row
                gutter={responsive(
                  {
                    xs: [15, 15],
                  },
                  windowSize.width
                )}
                style={{ height: "100%" }}
              >
                <Col span={24}>
                  <MenuButton2
                    style={{
                      height: 90,
                    }}
                    className="button"
                    onClick={() =>
                      window.open(
                        "https://funthriving.freshdesk.com/support/home"
                      )
                    }
                  >
                    Support
                  </MenuButton2>
                </Col>
                <Col span={24}>
                  <MenuButton1
                    onClick={() => {
                      isCompanyAdmin()
                        ? navigate("/team")
                        : unauthorizedNotification();
                    }}
                    className="button"
                    style={{
                      height: responsive(
                        { xs: 90, md: isEmpty(voiceApp) ? 195 : 90 },
                        windowSize.width
                      ),
                    }}
                  >
                    Team
                  </MenuButton1>
                </Col>
                {!isEmpty(voiceApp) && (
                  <Col span={24}>
                    <MenuButton1
                      onClick={
                        isCompanyAdmin()
                          ? handleVoIPButtonClick
                          : unauthorizedNotification
                      }
                      className="button"
                      style={{
                        height: 90,
                        color: "white",
                        backgroundColor: guidelines.colors.green[0],
                      }}
                    >
                      Phone
                    </MenuButton1>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      )) || (
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col xs={{ span: 24 }} md={{ span: 7 }}>
              <MenuButton2
                onClick={() => covid19Notification("Services shop")}
                className="button"
                style={{
                  height: responsive({ xs: 90, md: "100%" }, windowSize.width),
                }}
              >
                Services <br />
                <span style={{ fontWeight: 600 }}>shop *</span>
              </MenuButton2>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 11 }}>
              <Row gutter={[15, 15]}>
                <Col span={24}>
                  <MenuButton1
                    onClick={handleAccountButtonClick}
                    className="button"
                    style={{
                      height: responsive({ xs: 90, md: 160 }, windowSize.width),
                    }}
                  >
                    Account
                  </MenuButton1>
                </Col>
                <Col span={12}>
                  <MenuButton1
                    className="button"
                    style={{
                      height: 90,
                      color: "white",
                      backgroundColor: guidelines.colors.yellow[0],
                    }}
                    onClick={() => navigate("/mail")}
                  >
                    Mail
                  </MenuButton1>
                </Col>
                <Col span={12}>
                  <MenuButton2
                    style={{
                      height: 90,
                    }}
                    className="button"
                    onClick={() =>
                      window.open(
                        "https://funthriving.freshdesk.com/support/home"
                      )
                    }
                  >
                    Support
                  </MenuButton2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  styled(MenuClient)`
    .button {
      width: 100%;
    }
  `
);
