import React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { CardSection } from "..";
import { useState } from "react";
import { Button, Col, Row } from "antd";
import { guidelines } from "../../utils";
import styled from "styled-components";
import { Body2, Body3, H2 } from "../typography";
import {
  StripeCardElementChangeEvent,
  StripeCardElement,
  Token,
} from "@stripe/stripe-js";
import { Space } from "../layout";
import { ReactComponent as StripeClimateBadge } from "../../img/Stripe Climate Badge.svg";
import { ReactComponent as PoweredByStripe } from "../../icons/powered-by-stripe-white.svg";

const formStyle = {
  dark: ``,
  white: `
    color: white;
    `,
};

let Form = ({
  className,
  children,
  type,
  // message,
  ...props
}: { type: "dark" | "white" } & React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>) => (
  <form className={className} {...props}>
    {children}
  </form>
);

Form = styled(Form)`
  ${(props) => formStyle[props.type]}
  width: 100%;
  button {
    border-radius: 5px;
    margin-top: ${guidelines.margin[2]}px;
  }
`;

const CheckoutForm = ({
  type = "dark",
  title,
  description,
  buttonText,
  onFinish,
  stripeClimateBadge,
  className,
}: {
  type: "dark" | "white";
  title?: React.ReactNode;
  description?: React.ReactNode;
  onFinish: (token?: Token) => Promise<void>;
  buttonText?: React.ReactNode;
  stripeClimateBadge?: boolean;
  className?: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<null | string | undefined>(null);
  const [loading, setLoading] = useState(false);

  const handleChange: (event: StripeCardElementChangeEvent) => any = (
    event
  ) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSubmit: (
    event: React.FormEvent<HTMLFormElement>
  ) => void = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card as StripeCardElement);
    if (result.error) {
      // Inform the user if there was an error.
      setLoading(false);
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      stripeTokenHandler(result.token);
    }
  };

  const stripeTokenHandler = async (token?: Token) => {
    if (onFinish) {
      await onFinish(token);
    }
  };

  return (
    <Form onSubmit={handleSubmit} type={type} className={className}>
      <H2 color="white">{title}</H2>
      <Body2 color="white">{description}</Body2>
      {(title || description) && <Space height={10} />}
      <CardSection type={type} onChange={handleChange} />
      <Space height={5} />
      <div className="card-errors" role="alert">
        {error}
      </div>
      <Button
        loading={loading}
        disabled={!stripe}
        type="primary"
        htmlType="submit"
        block
      >
        {buttonText || "Confirm"}
      </Button>
      <Space height={15} />
      <Row justify="end">
        <Col>
          <PoweredByStripe
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://stripe.com", "_blank")}
            width={150 * 0.8}
            height={34 * 0.8}
          />
        </Col>
      </Row>
      {stripeClimateBadge && (
        <>
          <Space height={35} />
          <Row align="middle">
            <StripeClimateBadge style={{ width: 37.5, height: 37.5 }} />
            <Space width={15} />
            <Col flex="1">
              <Body3 style={{ color: "white", marginTop: 0 }}>
                FunThriving will contribute{" "}
                <strong>1.5% of your subscription</strong> to remove CO₂ from
                the atmosphere.
              </Body3>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default styled(CheckoutForm)`
  .card-errors {
    color: #fa755a;
  }
`;
