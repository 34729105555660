import { VoipReducerInterface } from "../../utils";

const initialState = {};

export default function voipReducer(
  state = initialState,
  {
    type,
    payload,
  }:
    | { type: "SET_VOIP"; payload: VoipReducerInterface }
    | { type: "RESET_VOIP"; payload?: null }
) {
  switch (type) {
    case "SET_VOIP":
      return { ...state, ...payload };

    case "RESET_VOIP":
      return initialState;

    default:
      return state;
  }
}
