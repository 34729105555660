import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { ReactComponent as PeopleIcon } from "../../icons/people.svg";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as RequestIcon } from "../../icons/request.svg";
import { CompanyInterface, guidelines, responsive } from "../../utils";
import { connect } from "react-redux";
import { Body1, H2 } from "../typography";
import moment from "moment";
import { Alias } from ".";

const Label = styled(
  ({
    text = "No Active Subscription",
    className,
    style,
  }: {
    text?: string;
    className?: string;
    color?: string;
    backgroundColor?: string;
    style?: React.CSSProperties;
  }) => (
    <Col className={className} style={style}>
      {text}
    </Col>
  )
)`
  color: ${(props) => props.color ?? guidelines.colors.grey[4]};
  background-color: ${(props) =>
    props.backgroundColor ?? guidelines.colors.grey[3]};
  padding: 3.5px 14px;
  border-radius: 5px;
`;

const Company = ({
  onClick,
  className,
  windowSize: { width },
  data: {
    name,
    created_at,
    members_count,
    requested_services_count,
    mail_items_count,
    last_requested_service,
    subscriptions,
    pmb,
    aliases,
  },
}: {
  onClick?: () => void;
  windowSize: {
    width: number;
    height: number;
  };
  data: CompanyInterface;
  className?: string;
}) => {
  return (
    <Row onClick={onClick} className={className}>
      <Col span={24} className="container">
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row
              wrap={responsive({ xs: true, md: false }, width)}
              justify="space-between"
              gutter={[20, 10]}
            >
              <Col flex={responsive({ xs: "100%", md: null }, width)}>
                <Row gutter={[10, 0]} align="middle">
                  <H2 marginTop="0" style={{ marginRight: 10 }}>
                    {name} {pmb && <span className="pmb">#{pmb}</span>}
                  </H2>{" "}
                  {aliases &&
                    aliases.map((alias, index) => (
                      <Alias key={index}>{alias}</Alias>
                    ))}
                </Row>
              </Col>
              <Col
                className="created_at"
                flex={responsive({ xs: "100%", md: null }, width)}
              >
                {moment(created_at).format("MMM D, YYYY")}{" "}
                <span className="hour">{moment(created_at).format("h a")}</span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 10]}>
              {(subscriptions &&
                subscriptions?.length > 0 &&
                subscriptions?.map((subscription, index) => {
                  if (index % 2 === 0 || index === 0) {
                    return (
                      <Label
                        key={index}
                        text={subscription}
                        color={guidelines.colors.blue[6]}
                        backgroundColor={guidelines.colors.blue[4]}
                        style={{
                          marginLeft: index > 0 ? guidelines.margin[0] : 0,
                        }}
                      />
                    );
                  } else {
                    return (
                      <Label
                        key={index}
                        text={subscription}
                        color={guidelines.colors.purple[1]}
                        backgroundColor={guidelines.colors.purple[0]}
                        style={{
                          marginLeft: index > 0 ? guidelines.margin[0] : 0,
                        }}
                      />
                    );
                  }
                })) || <Label />}
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" gutter={[20, 10]}>
              <Col flex={responsive({ xs: "100%", md: null }, width)}>
                {last_requested_service?.created_at && (
                  <Body1 color={guidelines.colors.green[0]}>
                    last request{" "}
                    {moment(last_requested_service?.created_at).fromNow()}
                  </Body1>
                )}
              </Col>
              <Col flex={responsive({ xs: "100%", md: null }, width)}>
                <Row>
                  {!!members_count && (
                    <Col className="count">
                      <Row>
                        <PeopleIcon className="icon" />
                        <Body1 className="number">{members_count}</Body1>
                      </Row>
                    </Col>
                  )}
                  {!!mail_items_count && (
                    <Col className="count">
                      <Row>
                        <MailIcon className="icon" />
                        <Body1 className="number">{mail_items_count}</Body1>
                      </Row>
                    </Col>
                  )}
                  {!!requested_services_count && (
                    <Col className="count">
                      <Row>
                        <RequestIcon className="icon" />
                        <Body1 className="number">
                          {requested_services_count}
                        </Body1>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: {
  windowSize: { width: number; height: number };
}) => ({
  windowSize: state.windowSize,
});

export default connect(mapStateToProps)(styled(Company)`
  border: 1px solid ${guidelines.colors.grey[1]};
  border-radius: 20px;
  padding: 25px 30px;
  /* min-height: 180px; */
  margin-top: ${guidelines.margin[3]}px;
  cursor: pointer;

  .pmb {
    font-weight: 500;
  }

  .hour {
    color: ${guidelines.colors.grey[0]};
  }

  .container {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }

  .created_at {
    flex-shrink: 0;
  }

  .count {
    &:not(:last-child) {
      margin-right: 15px;
    }

    .icon {
      margin-right: 7.5px;
      color: ${guidelines.colors.grey[0]};
    }

    .number {
      margin-top: 0;
      color: ${guidelines.colors.grey[0]};
    }
  }
`);
