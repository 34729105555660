import Axios from "axios";
import moment from "moment";
import { deactivateFormModal, updateFormModal } from "../../../store/actions";
import {
  apiAdmin,
  CompanyInterface,
  FormModalReducerInterface,
  MailAdminActionInterface,
} from "../..";

export const updateMailModal = ({
  data,
  dispatch,
  company,
  onActionFinished,
  showError,
}: MailAdminActionInterface & {
  company?: CompanyInterface;
}): FormModalReducerInterface => ({
  initialValues: data,
  title: "Mail Item",
  items: [
    {
      span: 12,
      type: "datepicker",
      name: "date",
      placeholder: "Date",
      disableDate: (current) => current > moment().endOf("day"),
    },
    {
      span: 12,
      type: "select",
      name: "mail_type_id",
      placeholder: "Type",
      data: [
        { value: 1, label: "Check" },
        { value: 2, label: "Envelope" },
        { value: 3, label: "Package" },
      ],
    },
    { name: "from", placeholder: "From" },
    {
      type: "select",
      allowClear: true,
      tooltip: "Only if mail comes addressed to one of these list members.",
      name: "member_id",
      placeholder: "Team member (optional)",
      data:
        company?.members?.map((member) => ({
          label: member.user.name as string,
          value: member.id as number,
        })) || [],
    },
    {
      name: "location",
      tooltip: "Location inside Prospreneurs Workplace, e.g., 1F 1001",
      placeholder: "Location",
    },
    {
      type: "number",
      name: "weight",
      placeholder: "Weight (pound)",
      rules: [
        {
          transform: (value) => parseFloat(value),
          type: "number",
          min: 0.1,
        },
      ],
    },
    {
      type: "paragraph",
      data: "Dimensions:",
    },
    {
      span: 8,
      name: ["dimensions", "width"],
      placeholder: "Width (inch)",
      rules: [
        {
          transform: (value) => parseFloat(value),
          type: "number",
        },
      ],
    },
    {
      span: 8,
      name: ["dimensions", "length"],
      placeholder: "Lenght (inch)",
      rules: [
        {
          transform: (value) => parseFloat(value),
          type: "number",
        },
      ],
    },
    {
      span: 8,
      name: ["dimensions", "height"],
      placeholder: "Height (inch)",
      rules: [
        {
          transform: (value) => parseFloat(value),
          type: "number",
        },
      ],
    },
  ],
  primaryAction: (values) => {
    dispatch(updateFormModal({ loadingPrimaryButton: true }));

    for (const [key] of Object.entries(values)) {
      if (values[key] === undefined) values[key] = "";
    }

    Axios.post(`${apiAdmin}/mail-items/${data.id}`, data)
      .then(async () => {
        await onActionFinished?.();
        dispatch(deactivateFormModal());
      })
      .catch(showError);
  },
});
