import {
  SourceInterface,
  StorageReducerInterface,
} from "./../../utils/interfaces";
import { AxiosStatic } from "axios";
import { updateHeaderData } from "./headerActions";

export const indexSources = () => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.get(
      `${apiClient}/companies/${default_company?.company.id}/payment-sources`
    ).then(({ data = [] }: { data: SourceInterface[] }) =>
      dispatch({
        type: "SET_SOURCES",
        payload: {
          all: data,
          default: data.filter((source) => (source.default = true))[0],
        },
      })
    );
  };
};

export const storeSource = (stripeToken: string) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.post(
      `${apiClient}/companies/${default_company?.company.id}/payment-sources`,
      {
        stripe_token: stripeToken,
      }
    ).then(() =>
      Axios.get(
        `${apiClient}/companies/${default_company?.company.id}/payment-sources`
      ).then(({ data = [] }: { data: SourceInterface[] }) =>
        dispatch({
          type: "SET_SOURCES",
          payload: {
            all: data,
            default: data.filter((source) => (source.default = true))[0],
          },
        })
      )
    );
  };
};

export const destroySource = (sourceId: string) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.delete(
      `${apiClient}/companies/${default_company?.company.id}/payment-sources/${sourceId}`
    ).then(() =>
      Axios.get(
        `${apiClient}/companies/${default_company?.company.id}/payment-sources`
      ).then(({ data = [] }: { data: SourceInterface[] }) =>
        dispatch({
          type: "SET_SOURCES",
          payload: {
            all: data,
            default: data.filter((source) => (source.default = true))[0],
          },
        })
      )
    );
  };
};

export const getDefaultSource = () => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.get(
      `${apiClient}/companies/${default_company?.company.id}/default-payment-source`
    ).then(({ data }) => {
      dispatch({
        type: "SET_DEFAULT_SOURCE",
        payload: {
          default: data,
        },
      });
    });
  };
};

export const setDefaultSource = (sourceId: string) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
    }: StorageReducerInterface = getState();

    return Axios.post(
      `${apiClient}/companies/${default_company?.company.id}/default-payment-source`,
      {
        source_id: sourceId,
      }
    ).then(() =>
      Axios.get(
        `${apiClient}/companies/${default_company?.company.id}/payment-sources`
      ).then(({ data = [] }: { data: SourceInterface[] }) =>
        dispatch({
          type: "SET_SOURCES",
          payload: {
            all: data,
            default: data.filter((source) => (source.default = true))[0],
          },
        })
      )
    );
  };
};

export const activateSourcesModal = () => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    const {
      auth: { default_company },
      header,
    }: StorageReducerInterface = getState();

    return Axios.get(
      `${apiClient}/companies/${default_company?.company.id}/payment-sources`
    ).then(({ data }) =>
      dispatch({
        type: "ACTIVATE_SOURCES_MODAL",
        payload: {
          all: data,
          activeModal: true,
          prevHeader: header,
        },
      })
    );
  };
};

export const deactivateSourcesModal = () => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => StorageReducerInterface
  ) => {
    const { sources } = getState();

    return new Promise((resolve) => {
      dispatch({
        type: "DEACTIVATE_SOURCES_MODAL",
      });
      sources.prevHeader && dispatch(updateHeaderData(sources.prevHeader));
      resolve(0);
    });
  };
};

export const resetSources = () => {
  return (dispatch: (dispatch: Function | object) => Promise<any>) => {
    return dispatch({
      type: "RESET_SOURCES",
    });
  };
};
