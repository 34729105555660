let stripeKey: string;

if (JSON.parse(process.env.REACT_APP_TEST_MODE ?? "true")) {
  stripeKey = `pk_test_wd5FRmKsArMRlN5s2aAm7zBp`;
} else {
  stripeKey = `pk_live_vmGVpIFeJED4Fm9eMKzSh8h5`;
}

const api = process.env.REACT_APP_BACKEND_URL;
const apiClient = `${api}/client`;
const apiAdmin = `${api}/admin`;

export { api, apiClient, apiAdmin, stripeKey };
