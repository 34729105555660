import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as RequestIcon } from "../../icons/request.svg";
import { guidelines, responsive, UserInterface } from "../../utils";
import { connect } from "react-redux";
import { Body1, Body2, H2 } from "../typography";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Company = styled(
  ({
    text = "No Active Subscription",
    className,
    style,
    onClick,
  }: {
    text?: string;
    className?: string;
    color?: string;
    backgroundColor?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }) => (
    <Col className={className} style={style} onClick={onClick}>
      {text}
    </Col>
  )
)`
  color: ${(props) => props.color ?? guidelines.colors.grey[4]};
  background-color: ${(props) =>
    props.backgroundColor ?? guidelines.colors.grey[3]};
  padding: 3.5px 14px;
  border-radius: 5px;
  cursor: pointer;
`;

const User = ({
  className,
  windowSize: { width },
  data: {
    name,
    created_at,
    email,
    requested_services_count,
    mail_items_count,
    last_requested_service,
    companies_with_subscriptions,
  },
}: {
  windowSize: {
    width: number;
    height: number;
  };
  data: UserInterface;
  className?: string;
}) => {
  const navigate = useNavigate();
  return (
    <Row className={className}>
      <Col span={24} className="container">
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row justify="space-between" gutter={[20, 10]}>
              <Col flex={responsive({ xs: "100%", md: null }, width)}>
                <H2 marginTop="0">{name}</H2>
                <Body2 marginTop="0" className="email">
                  {email}
                </Body2>
              </Col>
              <Col flex={responsive({ xs: "100%", md: null }, width)}>
                {moment(created_at).format("MMM D, YYYY")}{" "}
                <span className="hour">{moment(created_at).format("h a")}</span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 10]}>
              {(companies_with_subscriptions &&
                companies_with_subscriptions?.length > 0 &&
                companies_with_subscriptions?.map((company, index) => {
                  return (
                    <Company
                      key={index}
                      text={`${company.name} ${
                        company.pmb ? `#${company.pmb}` : ""
                      }`}
                      color={
                        index % 2 === 0 || index === 0
                          ? guidelines.colors.blue[6]
                          : guidelines.colors.purple[1]
                      }
                      backgroundColor={
                        index % 2 === 0 || index === 0
                          ? guidelines.colors.blue[4]
                          : guidelines.colors.purple[0]
                      }
                      style={{
                        marginLeft: index > 0 ? guidelines.margin[0] : 0,
                      }}
                      onClick={() => navigate(`/customers/${company.id}`)}
                    />
                  );
                })) || <Company />}
            </Row>
          </Col>
          {(mail_items_count ||
            requested_services_count ||
            last_requested_service) && (
            <Col span={24}>
              <Row justify="space-between" gutter={[20, 20]}>
                <Col flex={responsive({ xs: "100%", md: null }, width)}>
                  {last_requested_service?.created_at && (
                    <Body1 color={guidelines.colors.green[0]}>
                      last request{" "}
                      {moment(last_requested_service?.created_at).fromNow()}
                    </Body1>
                  )}
                </Col>

                <Col flex={responsive({ xs: "100%", md: null }, width)}>
                  <Row>
                    {!!mail_items_count && (
                      <Col className="count">
                        <Row>
                          <MailIcon className="icon" />
                          <Body1 className="number">{mail_items_count}</Body1>
                        </Row>
                      </Col>
                    )}
                    {!!requested_services_count && (
                      <Col className="count">
                        <Row>
                          <RequestIcon className="icon" />
                          <Body1 className="number">
                            {requested_services_count}
                          </Body1>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: {
  windowSize: { width: number; height: number };
}) => ({
  windowSize: state.windowSize,
});

export default connect(mapStateToProps)(styled(User)`
  border: 1px solid ${guidelines.colors.grey[1]};
  border-radius: 20px;
  padding: 25px 30px;
  min-height: 180px;
  margin-top: ${guidelines.margin[3]}px;

  .container {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }

  .hour,
  .email {
    color: ${guidelines.colors.grey[0]};
  }

  .count {
    margin-right: 10px;

    .icon {
      margin-right: 7.5px;
      color: ${guidelines.colors.grey[4]};
    }

    .number {
      margin-top: 0;
      color: ${guidelines.colors.grey[4]};
    }
  }
`);
