import React from "react";
// import "normalize.css";
// import "antd/dist/antd.min.css";
import "antd/dist/reset.css";
import "./fonts.css";
import "./index.css";
import "spinkit/spinkit.min.css";
import "loaders.css/loaders.min.css";
import * as Sentry from "@sentry/react";
import App from "./App";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { rootReducer } from "./store/reducers";
import Axios from "axios";
import { api, apiClient, apiAdmin } from "./utils";
import reportWebVitals from "./reportWebVitals";
import "flag-icons/css/flag-icons.min.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import reactQueryClient from "./reactQueryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

Sentry.init({
  dsn: "https://2cfaf2920401487781b5fa3bd7ccd3bb@o499967.ingest.sentry.io/5579091",
  release: "futfr@2.0.0",
  autoSessionTracking: true,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          api,
          apiClient,
          apiAdmin,
          Axios,
        },
      },
    }),
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={reactQueryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
