import React from "react";
import { Col, Row } from "antd";
import { guidelines, MailItemInterface } from "../../utils";
import { Body1 } from "../typography";
import styled from "styled-components";
import MailItem from "./MailItem";

const Label = ({
  children,
  span,
}: {
  children: React.ReactNode;
  span?: string | number;
}) => (
  <Col span={span}>
    <Body1 marginTop="0">{children}</Body1>
  </Col>
);

function MailTable({
  userActions,
  adminActions,
  onAdminActionsFinished,
  deleteAction,
  onDeleteActionFinished,
  mails,
}: {
  userActions?: boolean;
  adminActions?: boolean;
  onAdminActionsFinished?: () => void;
  deleteAction?: boolean;
  onDeleteActionFinished?: () => void;
  mails?: MailItemInterface[];
}) {
  return (
    <Row>
      <Col span={24}>
        <Row
          justify="center"
          style={{
            marginTop: `${guidelines.padding[4]}px`,
            backgroundColor: `${guidelines.colors.blue[4]}`,
            borderRadius: "100px",
          }}
        >
          <Col
            xs={{ span: 0 }}
            lg={{ span: 23 }}
            style={{
              padding: "7.5px 0",
            }}
          >
            <Row>
              <Label span={2}>ID</Label>
              <Label span={4}>Date</Label>
              <Label span={4}>From</Label>
              <Label span={4}>Type</Label>
              <Label span={5}>History</Label>
              <Label span={5}>Description</Label>
            </Row>
          </Col>
        </Row>
        <Row>
          {mails?.map((mail, index) => (
            <Col span="24" key={index}>
              <MailItem
                data={mail}
                userActions={userActions}
                adminActions={mail.available && adminActions}
                onAdminActionsFinished={onAdminActionsFinished}
                deleteAction={
                  mail.available &&
                  (!mail.statuses || mail.statuses?.length === 1) &&
                  deleteAction
                }
                onDeleteActionFinished={onDeleteActionFinished}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
}

export default styled(MailTable)``;
