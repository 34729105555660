import { WindowSizeReducerInterface } from "../../utils";

const initialState: WindowSizeReducerInterface = {
  width: window.innerWidth,
  height: window.innerHeight,
};

export default function windowSizeReducer(
  state = initialState,
  { type, payload }: { type: string; payload: WindowSizeReducerInterface }
) {
  switch (type) {
    case "SET_WINDOW_SIZE":
      return {
        ...payload,
      };

    default:
      return state;
  }
}
