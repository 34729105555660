import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import {
  apiAdmin,
  CompanyInterface,
  guidelines,
  MailAdminActionInterface,
  useShowError,
} from "../../utils";
import Axios from "axios";
import { activateFormModal } from "../../store/actions";
import { updateMailModal } from "../../utils/hooks/modals/updateMailModal";
import { pickUpMailModal } from "../../utils/hooks/modals/pickUpMailModal";
import { useDispatch } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import DropdownButton from "../buttons/DropdownButton";

const updateMail = async (props: MailAdminActionInterface) => {
  const { dispatch } = props;

  props.data = {
    ...props.data,
    date: moment(props.data.date),
  };

  props.setStatus("loading");

  let company: CompanyInterface | undefined = await Axios.get(
    `${apiAdmin}/companies/${props.data.company_id}`
  ).then(({ data }) => data);

  props.setStatus();

  dispatch(activateFormModal(updateMailModal({ ...props, company })));
};

const pickedUpMail = async (props: MailAdminActionInterface) => {
  const { dispatch } = props;
  dispatch(activateFormModal(pickUpMailModal(props)));
};

const StyledDropdownButton = styled(DropdownButton)`
  margin-top: ${guidelines.padding[0]}px;
  border-color: ${guidelines.colors.grey[1]};
  border-radius: 100px;
  color: black;
`;

function AdminActionsButton({
  ...props
}: Omit<
  MailAdminActionInterface,
  "showError" | "dispatch" | "setStatus"
> & {}) {
  const showError = useShowError();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<"loading">();

  const { data } = props;

  const action = async (callback: () => void) => {
    await props.onActionBegin?.();
    callback();
  };

  let actions = [
    {
      name: "Edit",
      onClick: () =>
        action(() => updateMail({ showError, setStatus, dispatch, ...props })),
    },
  ];

  if (data.statuses?.[0].name === "available")
    actions = [
      ...actions,
      {
        name: "Picked up",
        onClick: () =>
          action(() =>
            pickedUpMail({ showError, setStatus, dispatch, ...props })
          ),
      },
    ];

  return (
    <StyledDropdownButton options={{ type: "outline", status, items: actions }}>
      Actions <DownOutlined style={{ marginLeft: 5, fontSize: 10 }} />
    </StyledDropdownButton>
  );
}

export default AdminActionsButton;
