import Axios from "axios";
import { useDispatch } from "react-redux";
import {
  activateFormModal,
  dispatchUserInfo,
  updateFormModal,
  deactivateFormModal,
} from "../../../store/actions";
import { api } from "../../config";
import { FormModalReducerInterface } from "../../interfaces";
import { nanoid } from "nanoid/non-secure";
import { useState } from "react";
import { useShowError } from "../../useShowError";

export const useTemporaryAccessCodeModal = () => {
  const dispatch = useDispatch<any>();
  const [device] = useState(nanoid());
  const showError = useShowError();

  const options: FormModalReducerInterface = {
    title: "Temporary Code Login",
    description: "Please provide below the email you use to sign up:",
    primaryAction: async (data) => {
      await dispatch(
        updateFormModal({ loadingPrimaryButton: true, alert: null })
      );

      Axios.post(`${api}/devices/${device}/temporary-access-code`, data)
        .then(() => {
          dispatch(
            updateFormModal({
              loadingPrimaryButton: false,
              items: [
                ...(options.items || []),
                {
                  type: "paragraph",
                  data: "Now enter the code you received; this code will be valid for only five (5) minutes.",
                },
                {
                  name: "temporary_access_code",
                  placeholder: "Code",
                  type: "password",
                },
              ],
              primaryAction: async (data) => {
                await dispatch(
                  updateFormModal({ loadingPrimaryButton: true, alert: null })
                );
                Axios.post(
                  `${api}/devices/${device}/check-temporary-access-code`,
                  data
                )
                  .then((res) => {
                    dispatchUserInfo(res, dispatch);
                    dispatch(deactivateFormModal());
                  })
                  .catch(showError);
              },
            })
          );
        })
        .catch(showError);
    },
    items: [{ name: "email", placeholder: "Email" }],
  };

  return () => dispatch(activateFormModal(options));
};
