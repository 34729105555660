import { combineReducers } from "redux";
import authReducer from "./authReducer";
import companiesReducer from "./companiesReducer";
import formModalReducer from "./formModalReducer";
import headerReducer from "./headerReducer";
import sourcesReducer from "./sourcesReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import switchCompaniesReducer from "./switchCompaniesReducer";
import usersReducer from "./usersReducer";
import voipReducer from "./voipReducer";
import windowSizeReducer from "./windowSizeReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  companies: companiesReducer,
  formModal: formModalReducer,
  header: headerReducer,
  sources: sourcesReducer,
  subscriptions: subscriptionsReducer,
  switchCompanies: switchCompaniesReducer,
  users: usersReducer,
  voip: voipReducer,
  windowSize: windowSizeReducer,
});
