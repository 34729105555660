import React from "react";
import styled from "styled-components";
import { Col, ColProps, Row } from "antd";
import { guidelines } from "../../utils";
import { Body1 } from "../typography";

let Alias: any = ({
  children,
  ...props
}: ColProps & React.RefAttributes<HTMLDivElement>) => {
  return (
    <Col {...props}>
      <Row className="alias" wrap={false} align="middle">
        <Col className="dot"></Col>
        <Body1 marginTop="0">{children}</Body1>
      </Row>
    </Col>
  );
};

Alias = styled(Alias)`
  p {
    color: ${guidelines.colors.grey[0]};
  }
  .dot {
    width: 3px;
    height: 3px;
    border-radius: 100px;
    margin-right: 5px;
    background-color: ${guidelines.colors.grey[4]};
  }
`;

export default Alias;
