import { HeaderReducerInterface } from "../../utils";

const initialState: HeaderReducerInterface = {
  mode: "light",
  backButton: {
    active: false,
  },
  logoutButton: true,
  justify: "space-between",
};

export default function headerReducer(
  state = initialState,
  {
    type,
    payload,
  }: { type: "UPDATE_HEADER_DATA"; payload: HeaderReducerInterface }
) {
  switch (type) {
    case "UPDATE_HEADER_DATA":
      return { ...state, ...payload };

    default:
      return state;
  }
}
