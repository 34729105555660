import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import styled from "styled-components";
import { Body2, H1, H3 } from "../typography";
import {
  apiClient,
  CompanyInterface,
  guidelines,
  responsive,
  StorageReducerInterface,
  useHasActiveVoPlan,
  useIsCompanyAdmin,
  useShowError,
  WindowSizeReducerInterface,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { Button1 } from "../buttons";
import { ColProps } from "antd/lib/col";
import { ButtonProps } from "antd/lib/button";
import {
  activateFormModal,
  activateSwitchCompanies,
  deactivateFormModal,
  getUserInfo,
  updateFormModal,
} from "../../store/actions";
import Axios from "axios";
import CountryList from "../../json/country-list.json";
import { FormItemInterface } from "../form/Form";
import { useUspsFormAutocomplete } from "../../utils/hooks/modals/useUspsFormAutocomplete";
import { Space } from "../layout";
import Upload from "../form/Upload";

interface DataInterface extends ColProps, React.RefAttributes<HTMLDivElement> {
  color?: string;
  body?: string | number;
  cursor?: string;
}

let Data = ({ className, title, body, ...props }: DataInterface) => (
  <Col span={11} className={className} {...props}>
    <Body2 color={guidelines.colors.grey[0]}>{title}</Body2>
    <H3>{body}</H3>
  </Col>
);

Data = styled(Data)`
  cursor: ${(props) => props.cursor || ""};
  h3 {
    padding-left: ${guidelines.padding[0]}px;
    border-left: 2px solid;
    border-left-color: ${(props) => props.color || ""};
  }
`;

let Pic = ({ className }: { className?: string }) => (
  <Col className={className}>
    <Row justify="center" align="middle">
      <H3 marginTop="0" color="white">
        Pic
      </H3>
    </Row>
  </Col>
);

Pic = styled(Pic)`
  background-color: ${guidelines.colors.red[0]};
  width: 75px;
  height: 75px;
  border-radius: ${guidelines.borderRadius[1]}px;

  .ant-row {
    width: 100%;
    height: 100%;
  }
`;

let SwitchCompany = ({ loading, className, ...props }: ButtonProps) => {
  const dispatch = useDispatch<any>();
  return (
    <Button1
      loading={loading}
      className={className}
      onClick={() => dispatch(activateSwitchCompanies())}
      {...props}
    >
      switch
    </Button1>
  );
};

SwitchCompany = styled(SwitchCompany)`
  padding: 5px 10px;
  height: auto;
  line-height: 1;
  color: white;
  border-color: white;
`;

let BusinessCard = ({
  name,
  pmb,
  phoneNumber,
  className,
  company,
}: {
  name: string;
  pmb?: number;
  phoneNumber?: string;
  className?: string;
  company: CompanyInterface;
}) => {
  const isCompanyAdmin = useIsCompanyAdmin();
  const hasActiveVoPlan = useHasActiveVoPlan();
  const dispatch = useDispatch<any>();
  const showError = useShowError();

  phoneNumber = phoneNumber?.toString();

  const updateCompany = () => {
    let items: FormItemInterface[] = [
      { type: "subtitle", data: "Billing details" },
      { name: ["address", "line1"], placeholder: "Address line 1" },
      { name: ["address", "line2"], placeholder: "Address line 2" },
      {
        name: ["address", "country"],
        placeholder: "Country",
        type: "select",
        data: CountryList.map((country) => ({
          value: country["alpha-2"],
          label: country.name,
        })),
      },
      { name: ["address", "city"], placeholder: "City" },
      { name: ["address", "state"], placeholder: "State" },
      { name: ["address", "postal_code"], placeholder: "ZIP" },
    ];
    dispatch(
      activateFormModal({
        initialValues: {
          company_id: company.id,
          name: company.name,
          address: {
            line1: company.address?.line1,
            line2: company.address?.line2,
            country: company.address?.country,
            city: company.address?.city,
            state: company.address?.state,
            postal_code: company.address?.postal_code,
          },
          aliases: company.aliases,
        },
        title: "Update Company",
        items: company.last_usps_form?.approved
          ? [
              {
                name: "name",
                placeholder: "Name",
                disabled: true,
              },
              {
                type: "tags",
                name: "aliases",
                label: "Aliases/DBAs (each one counts as a recipient)",
                placeholder: "New Aliases/DBAs",
                disabled: true,
              },
              {
                type: "custom",
                data: (
                  <Body2 marginTop="0" style={{ marginBottom: 10 }}>
                    To update the above information, you must fill out{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://funthriving.freshdesk.com/support/solutions/articles/64000260549"
                      style={{ fontWeight: 600 }}
                    >
                      PS Form 1583
                    </a>{" "}
                    again and send it for review to{" "}
                    <a
                      href="mailto:support@funthriving.freshdesk.com?subject=PS Form 1583"
                      style={{ fontWeight: 600 }}
                    >
                      support@funthriving.freshdesk.com.
                    </a>
                  </Body2>
                ),
              },
              ...items,
            ]
          : [
              {
                name: "name",
                placeholder: "Name",
              },
              {
                type: "tags",
                name: "aliases",
                label: "Aliases/DBAs (each one counts as a recipient)",
                placeholder: "New Aliases/DBAs",
              },
              ...items,
            ],
        primaryAction: (data) => {
          dispatch(updateFormModal({ loadingPrimaryButton: true }));

          Axios.put(`${apiClient}/companies/${company.id}`, data)
            .then(async () => {
              await dispatch(getUserInfo());
              dispatch(deactivateFormModal());
            })
            .catch(showError);
        },
      })
    );
  };

  const uspsFormAutocomplete = useUspsFormAutocomplete();

  const hasPMBAssigned = pmb;
  const isUSPSFormUnderReview =
    company.last_usps_form &&
    company.last_usps_form.approved === null &&
    hasPMBAssigned;
  const isUSPSFormApproved =
    !!company.last_usps_form?.approved && hasPMBAssigned;

  return (
    <div className={className}>
      <Row gutter={[0, 20]}>
        <Col span="24">
          <H3 marginTop="0" color="white">
            {name}
          </H3>
        </Col>
      </Row>
      <Row className="description">
        <Col span="24">
          <Row gutter={[10, 10]}>
            {isUSPSFormApproved && hasActiveVoPlan() && (
              <Col span={24}>
                <Body2 className="text" marginTop="0">
                  320 Southeast 9th Street #{pmb}
                </Body2>
                <Body2 className="text" marginTop="0">
                  Fort Lauderdale, FL 33316-1128
                </Body2>
              </Col>
            )}
            {isUSPSFormUnderReview && hasActiveVoPlan() && (
              <Col span={24}>
                <Body2 className="text welcome" marginTop="0">
                  We have received the <span>PS Form 1583</span> you submitted;
                  we will review it within <span>two (2) business days</span>.
                  If we need additional information, we will contact you by
                  email.
                </Body2>
              </Col>
            )}
            {!(isUSPSFormUnderReview || isUSPSFormApproved) &&
              hasActiveVoPlan() && (
                <Col span={24}>
                  <Body2
                    className="active-pmb text"
                    marginTop="0"
                    onClick={() =>
                      window.open(
                        "https://funthriving.freshdesk.com/support/solutions/articles/64000260508"
                      )
                    }
                  >
                    One step away from receiving your mail, <span>click</span>{" "}
                    for more information.
                  </Body2>
                  <Space height={5} />
                  <Body2
                    className="active-pmb text"
                    marginTop="0"
                    onClick={() => uspsFormAutocomplete()}
                  >
                    <i>
                      Click here to use our beta feature to{" "}
                      <span>autofill the PS Form 1583.</span>
                    </i>
                  </Body2>
                </Col>
              )}
            {!hasActiveVoPlan() && (
              <Col span={24}>
                <Body2 className="welcome text" marginTop="0">
                  We're glad to see you're here. We can't wait to see what{" "}
                  <span>fantastic</span> things you're going to do.
                </Body2>
              </Col>
            )}
            <Col span={24}>
              <Row align="middle" justify="space-between" gutter={[10, 10]}>
                <Col>
                  {phoneNumber && (
                    <Body2
                      className="text"
                      marginTop="0"
                    >{`+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
                      1,
                      4
                    )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(
                      7,
                      11
                    )}`}</Body2>
                  )}
                </Col>
                <Col style={{ paddingBottom: 0 }}>
                  <Row>
                    <Col>
                      <SwitchCompany />
                    </Col>
                    <Col>
                      {isCompanyAdmin() && (
                        <Button1 className="editButton" onClick={updateCompany}>
                          edit
                        </Button1>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

BusinessCard = styled(BusinessCard)`
  background: ${guidelines.colors.blue[5]} url("/img/background.svg");
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  padding: 8%;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .text {
    color: rgba(255, 255, 255, 0.8);
  }
  .description {
    margin-top: 15px;

    .active-pmb {
      cursor: pointer;

      span {
        font-weight: 600;
      }
    }
    .welcome {
      span {
        font-weight: 600;
      }
    }
    .editButton {
      line-height: 1;
      padding-left: 10px;
      padding-right: 0;
      height: auto;
      border: none;
      color: white;
    }
  }
`;

const Overview = ({
  className,
}: {
  className?: string;
  windowSize?: WindowSizeReducerInterface;
}) => {
  const {
    auth: { name, default_company },
    voip: { phone_number, balance },
  } = useSelector((store: StorageReducerInterface) => ({
    auth: store.auth,
    voip: store.voip,
  }));

  const hasActiveVoPlan = useHasActiveVoPlan();

  const dispatch = useDispatch<any>();

  const isCompanyAdmin = useIsCompanyAdmin();

  const navigate = useNavigate();

  const hasVoip =
    default_company?.company.active_plan?.metadata?.voip &&
    ["1", "true"].includes(
      default_company?.company.active_plan?.metadata.voip.toString()
    );

  const phoneNumber = phone_number?.toString();

  const uspsForm = default_company?.company.last_usps_form;

  const isUSPSFormUnderReview = uspsForm && uspsForm.approved == null;
  const isUSPSFormApproved = !!uspsForm?.approved;
  const hasPMBAssigned = !!default_company?.company.pmb;

  const uploadData = {
    onUploadFinished: () => {
      return dispatch(getUserInfo({ voip: false }));
    },
    disabled: isUSPSFormUnderReview,
    placeholder: !(isUSPSFormApproved || isUSPSFormUnderReview)
      ? "Upload for review"
      : !isUSPSFormApproved
      ? "Under review"
      : "Upload a new form for review",
    file: {
      title: "PS Form 1583 (notarized)",
      name: uspsForm?.url?.split("/").reverse()[0],
      url: uspsForm?.url
        ? `${apiClient}/companies/${default_company?.company.id}/usps-forms/${uspsForm.id}/file`
        : undefined,
    },
    request: {
      key: "file",
      url: `${apiClient}/companies/${default_company?.company.id}/usps-forms`,
    },
  };

  return (
    <Row justify="center" className={className}>
      <Col style={{ maxWidth: 600 }} span={24}>
        <Row justify="end">
          <Pic />
        </Row>
        <H1 color={guidelines.colors.blue[0]}>
          <span style={{ fontWeight: 500 }}>Hello,</span> {name?.split(" ")[0]}
        </H1>
        {default_company?.company.name && (
          <BusinessCard
            company={default_company.company}
            pmb={default_company.company.pmb}
            name={default_company.company.name}
            phoneNumber={phoneNumber}
          />
        )}
        {hasPMBAssigned && hasActiveVoPlan() && isCompanyAdmin() && (
          <>
            <Space height={20} />
            <Row>
              <Col span={24}>
                <Upload data={uploadData} />
              </Col>
            </Row>
          </>
        )}
        <Row justify="center">
          <Col span="22">
            <Row
              justify="space-between"
              style={{ marginTop: `${guidelines.margin[2]}px` }}
            >
              <Data
                cursor="pointer"
                color={guidelines.colors.red[0]}
                title="Active plan:"
                onClick={() => navigate("/subscriptions")}
                body={
                  default_company?.company.active_plan?.nickname ??
                  "To activate"
                }
              />
              <Data
                color={guidelines.colors.yellow[0]}
                title="Mail / packages:"
                body={
                  default_company?.company.mail_items_count ||
                  default_company?.mail_items_count
                }
              />
              {hasVoip && balance && (
                <Data
                  color={guidelines.colors.green[0]}
                  title="Phone balance:"
                  body={`$ ${balance}`}
                />
              )}
            </Row>
            <Space height={20} />
            {/* <H3
              marginTop={guidelines.margin[2]}
              color={guidelines.colors.grey[0]}
            >
              + Perks
            </H3> */}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: StorageReducerInterface) => ({
  windowSize: state.windowSize,
});

export default connect(mapStateToProps)(styled(Overview)`
  min-height: ${(props) =>
    responsive({ lg: "565px" }, props.windowSize?.width as number)};
  border-radius: ${guidelines.borderRadius[0]}px;
  padding: 7%;
  background: white;
`);
