import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { H1, Body1, Button1 } from "..";
import {
  guidelines,
  responsive,
  StorageReducerInterface,
  useIsCompanyAdmin,
  WindowSizeReducerInterface,
} from "../../utils";
import { activateSourcesModal } from "../../store/actions";
import { useSelector } from "react-redux";
import { useState } from "react";
import { RowProps } from "antd/lib/row";
import { useDispatch } from "react-redux";

export interface PageDescriptionInterface extends Omit<RowProps, "title"> {
  showPaymentSources?: boolean;
  activateSourcesModal?: Function;
  title?: React.ReactNode;
  description?: string;
  windowSize?: WindowSizeReducerInterface;
  rightContent?: React.ReactNode;
  leftContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
}

export const PageDescription = styled(
  ({
    className,
    showPaymentSources = true,
    title,
    description,
    rightContent,
    leftContent,
    bottomContent,
    ...props
  }: PageDescriptionInterface) => {
    const { windowSize } = useSelector((store: StorageReducerInterface) => ({
      windowSize: store.windowSize,
    }));
    const dispatch = useDispatch<any>();
    const [paymentSourcesButtonLoading, setPaymentSourcesButtonLoading] =
      useState(false);
    const isCompanyAdmin = useIsCompanyAdmin();

    const handleClickPaymentSourcesButton = () => {
      setPaymentSourcesButtonLoading(true);
      dispatch(activateSourcesModal()).then(() => {
        setPaymentSourcesButtonLoading(false);
      });
    };

    return (
      <Row className={className} {...props}>
        <Col flex="auto">
          <Row
            wrap={
              responsive(
                { xs: true, lg: false },
                windowSize?.width as number
              ) as boolean
            }
            gutter={[0, guidelines.spacing[0]]}
          >
            <Col className="left-content-container" flex="auto">
              <H1 marginTop="0" className="title">
                {title}
              </H1>
              <Body1 className="description">{description}</Body1>
              {leftContent}
            </Col>
            <Col
              flex={responsive(
                { xs: 1, lg: null },
                windowSize?.width as number
              )}
              className="right-content-container"
            >
              {showPaymentSources && isCompanyAdmin() && (
                <Button1
                  loading={paymentSourcesButtonLoading}
                  onClick={handleClickPaymentSourcesButton}
                >
                  Payment Sources
                </Button1>
              )}
              {rightContent}
            </Col>
          </Row>
          {bottomContent}
        </Col>
      </Row>
    );
  }
)`
  padding: ${guidelines.padding[4]}px ${guidelines.padding[5]}px;
  background: ${guidelines.colors.yellow[1]};
  border-radius: ${guidelines.borderRadius[0]}px;
`;
