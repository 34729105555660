import { CompanyInterface, MemberInterface } from "./../../utils/interfaces";
import {
  UserInterface,
  VoipInterface,
  AuthReducerInterface,
  StorageReducerInterface,
  apiClient,
} from "../../utils";
import { isEmpty } from "lodash";
import { setVoip, voipSetup } from "./voipActions";
import Axios, { AxiosError, AxiosStatic } from "axios";
import { isCompanyAdmin } from "../../utils/useIsCompanyAdmin";
import reactQueryClient from "../../reactQueryClient";

export const dispatchUserInfo = async (
  res: { data: AuthReducerInterface },
  dispatch: (dispatch: Function | object) => Promise<any>,
  validate: { voip: boolean } = { voip: true }
) => {
  const {
      data: {
        id,
        token,
        name,
        email,
        roles,
        companies = [],
        default_company,
        invitations,
        phone_number,
      },
    } = res,
    payload: AuthReducerInterface = {
      id,
      loaded: true,
      name,
      email,
      roles,
      companies,
      default_company,
      invitations,
      phone_number,
    };

  if (token) {
    payload.token = token;
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  await dispatch({
    type: "SET_USER_INFO",
    payload,
  });

  const sources = default_company?.company.sources;

  if (sources?.length)
    await dispatch({
      type: "SET_SOURCES",
      payload: {
        all: sources,
        default: sources.filter((source) => (source.default = true))[0],
      },
    });

  const voip = default_company?.company.voip;

  if (!isEmpty(voip)) await dispatch(setVoip(voip as VoipInterface));

  if (validate.voip && isCompanyAdmin(default_company)) dispatch(voipSetup());
};

export const login = (data: { email: string; password: string }) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { api, Axios }: { api: string; Axios: AxiosStatic }
  ) => {
    return Axios.post(`${api}/login`, data).then(
      (res: { data: AuthReducerInterface }) => {
        dispatchUserInfo(res, dispatch);
      }
    );
  };
};

export const updateUserCompanies = () => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { api, Axios }: { api: string; Axios: AxiosStatic }
  ) => {
    return Axios.get(`${apiClient}/companies`).then(
      (res: { data: MemberInterface[] }) => {
        dispatch({
          type: "SET_USER_COMPANIES",
          payload: res.data,
        });
      }
    );
  };
};

export const updateDefaultCompany = (data: CompanyInterface) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { api, Axios }: { api: string; Axios: AxiosStatic }
  ) => {
    return Axios.get(`${apiClient}/companies/${data.id}/default`).then(
      async (res: { data: MemberInterface }) => {
        await dispatch({
          type: "SET_USER_DEFAULT_COMPANY",
          payload: res.data,
        });

        await Promise.all([
          reactQueryClient.removeQueries({ queryKey: ["mail-items"] }),
          reactQueryClient.removeQueries({ queryKey: ["members"] }),
          reactQueryClient.removeQueries({ queryKey: ["subscriptions"] }),
          dispatch({
            type: "RESET_SUBSCRIPTIONS",
          }),
          dispatch({
            type: "RESET_VOIP",
          }),
          dispatch({
            type: "RESET_SOURCES",
          }),
        ]);

        const sources = res.data.company.sources;

        if (sources?.length)
          await dispatch({
            type: "SET_SOURCES",
            payload: {
              all: sources,
              default: sources.filter((source) => (source.default = true))[0],
            },
          });

        const voip = res.data.company.voip;

        if (!isEmpty(voip)) await dispatch(setVoip(voip as VoipInterface));

        if (isCompanyAdmin(res.data)) dispatch(voipSetup());

        return Promise.resolve();
      }
    );
  };
};

export const facebookLogin = (data: string) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { api, Axios }: { api: string; Axios: AxiosStatic }
  ) => {
    return Axios.post(`${api}/login/facebook`, {
      accessToken: data,
    }).then((res) => {
      return dispatchUserInfo(res, dispatch);
    });
  };
};

export const googleLogin = (data: string) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { api, Axios }: { api: string; Axios: AxiosStatic }
  ) => {
    return Axios.post(`${api}/login/google`, {
      accessToken: data,
    }).then((res) => {
      return dispatchUserInfo(res, dispatch);
    });
  };
};

export const signUp = (data: {
  name: string;
  email: string;
  company: string;
  password: string;
  terms: boolean;
}) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { api, Axios }: { api: string; Axios: AxiosStatic }
  ) => {
    return Axios.post(`${api}/sign-up`, data).then((res) => {
      dispatchUserInfo(res, dispatch);
    });
  };
};

export const logout = () => {
  return (dispatch: (dispatch: Function | object) => Promise<any>) => {
    return new Promise((resolve) => {
      dispatch({
        type: "LOGOUT",
      });
      dispatch({
        type: "RESET_SUBSCRIPTIONS",
      });
      dispatch({
        type: "RESET_VOIP",
      });
      dispatch({
        type: "RESET_SOURCES",
      });
      window.location.href = "/";
      resolve(0);
    });
  };
};

export const getUserInfo = (validate: { voip: boolean } = { voip: true }) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { api, Axios }: { api: string; Axios: AxiosStatic }
  ) => {
    return Axios.get(`${api}/client/user-info`)
      .then((res) => {
        const {
          auth: { token },
        }: StorageReducerInterface = getState();

        token && dispatchUserInfo(res, dispatch, validate);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const updateSocialNetworkInfo = (data: {
  email?: string;
  company?: string;
}) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    return Axios.post(`${apiClient}/update-social-network-info`, data).then(
      (res) => {
        dispatchUserInfo(res, dispatch);
      }
    );
  };
};

export const updateUserInfo = (data: UserInterface) => {
  return (
    dispatch: (dispatch: Function | object) => Promise<any>,
    getState: () => any,
    { apiClient, Axios }: { apiClient: string; Axios: AxiosStatic }
  ) => {
    return Axios.put(`${apiClient}/user`, data).then((res) => {
      dispatchUserInfo(res, dispatch);
    });
  };
};
