import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TwoColumns } from "../../components/layout";
import {
  apiAdmin,
  CompanyInterface,
  guidelines,
  MailItemInterface,
  useShowError,
} from "../../utils";
import moment from "moment";
import { Button, ButtonProps, Col, Row } from "antd";
import { ReactComponent as PeopleIcon } from "../../icons/people.svg";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as RequestIcon } from "../../icons/request.svg";
import {
  BallPulse,
  Body1,
  Body2,
  H3,
  MailTable,
  MembersTable,
} from "../../components";
import styled from "styled-components";
import { upperFirst } from "lodash";
import {
  activateFormModal,
  deactivateFormModal,
  updateFormModal,
  updateHeaderData,
} from "../../store/actions";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import DropdownButton from "../../components/buttons/DropdownButton";
import Description from "./Description";
import { useQuery } from "@tanstack/react-query";

const StyledDropdownButton = styled(DropdownButton)`
  padding: 0;
  margin-top: 15px;
  background-color: #edeff3;
  color: #767b84;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* border: 1px solid #c7cfdb; */

  .text {
    padding: 8px 10px;
  }

  .icon-container {
    padding: 8px 8px;
    /* border-left: 1px solid #c7cfdb; */
    background-color: #e1e5eb;
  }
`;

// const link = document.createElement("a");

let AllMailsPickedUp = (
  props: ButtonProps & React.RefAttributes<HTMLElement>
) => <Button {...props}>All picked up</Button>;

AllMailsPickedUp = styled(AllMailsPickedUp)`
  background-color: #edeff3 !important;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 5px;
  margin-top: 15px;
  border: none;
  height: 39px;
  font-size: ${guidelines.fontSize.body2.xs}px;
  color: #767b84 !important;
  /* border: 1px solid #c7cfdb; */
`;

function Customer({ className }: { className?: string }) {
  const { id }: { id?: string } = useParams();
  const { token } = useSelector(
    (state: { auth: { token?: string } }) => state.auth
  );
  const [customer, setCustomer] = useState<CompanyInterface | undefined>();
  const [activeSection, setActiveSection] = useState<number>(0);
  const dispatch = useDispatch<any>();
  const showError = useShowError();
  const navigate = useNavigate();

  const sections = ["Mail", "Users"];

  useEffect(() => {
    dispatch(
      updateHeaderData({
        mode: "light",
        backButton: {
          active: true,
          onClick: () => navigate("/customers"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);

  const getCustomer = () => {
    return Axios.get(`${apiAdmin}/companies/${id}`).then(({ data }) => data);
  };

  const { data } = useQuery({
    queryKey: ["customer", id],
    queryFn: () => getCustomer(),
    enabled: !!(id && token),
  });

  useEffect(() => {
    setCustomer(data);
    return () => {};
  }, [data]);

  const handleNewMailClick = () =>
    dispatch(
      activateFormModal({
        initialValues: {
          company_id: customer?.id,
        },
        title: "Mail Item",
        items: [
          {
            span: 12,
            type: "datepicker",
            name: "date",
            placeholder: "Date",
            disableDate: (current) => current > moment().endOf("day"),
          },
          {
            span: 12,
            type: "select",
            name: "mail_type_id",
            placeholder: "Type",
            data: [
              { value: 1, label: "Check" },
              { value: 2, label: "Envelope" },
              { value: 3, label: "Package" },
            ],
          },
          { name: "company_id", hidden: true },
          { name: "from", placeholder: "From" },
          {
            type: "select",
            allowClear: true,
            name: "member_id",
            tooltip:
              "Only if mail comes addressed to one of these list members.",
            placeholder: "Team member (optional)",
            data:
              customer?.members?.map((member) => ({
                label: member.user.name as string,
                value: member.id as number,
              })) || [],
          },
          {
            name: "location",
            tooltip: "Location inside Prospreneurs Workplace, e.g., 1F 1001",
            placeholder: "Location",
          },
          {
            type: "number",
            name: "weight",
            placeholder: "Weight (pound)",
            rules: [
              {
                transform: (value) => parseFloat(value),
                type: "number",
                min: 0.1,
              },
            ],
          },
          {
            type: "paragraph",
            data: "Dimensions:",
          },
          {
            span: 8,
            name: ["dimensions", "width"],
            placeholder: "Width (inch)",
            rules: [
              { transform: (value) => parseFloat(value), type: "number" },
            ],
          },
          {
            span: 8,
            name: ["dimensions", "length"],
            placeholder: "Lenght (inch)",
            rules: [
              { transform: (value) => parseFloat(value), type: "number" },
            ],
          },
          {
            span: 8,
            name: ["dimensions", "height"],
            placeholder: "Height (inch)",
            rules: [
              { transform: (value) => parseFloat(value), type: "number" },
            ],
          },
        ],
        primaryAction: (data) => {
          dispatch(updateFormModal({ loadingPrimaryButton: true }));

          Axios.post(`${apiAdmin}/mail-items`, data)
            .then(async () => {
              await getCustomer();
              dispatch(deactivateFormModal());
            })
            .catch(showError);
        },
      })
    );

  const handleAllMailsPickedUpClick = () => {
    dispatch(
      activateFormModal({
        title: "All Mails Picked Up",
        description: "Are you sure that the customer picked up the mail?",
        items: [
          {
            type: "paragraph",
            data: (
              <span style={{ fontWeight: 500 }}>
                <i>This action can't be undone.</i>
              </span>
            ),
          },
        ],
        primaryAction: () => {
          dispatch(updateFormModal({ loadingPrimaryButton: true }));
          Axios.put(`${apiAdmin}/companies/${customer?.id}/mails-picked-up`)
            .then((res) => {
              dispatch(deactivateFormModal());
              customer &&
                setCustomer({
                  ...customer,
                  mail_items: res.data,
                });
            })
            .catch(showError);
        },
      })
    );
  };

  const handleDeleteMailPickerClick = ({ id }: { id: number }) => {
    dispatch(
      activateFormModal({
        title: "Delete Mail Picker",
        description: "Are you sure you want to delete this mail picker?",
        items: [
          {
            type: "paragraph",
            data: (
              <span style={{ fontWeight: 500 }}>
                <i>This action can't be undone.</i>
              </span>
            ),
          },
        ],
        primaryAction: () => {
          dispatch(updateFormModal({ loadingPrimaryButton: true }));
          Axios.delete(`${apiAdmin}/mail-pickers/${id}`)
            .then(async (res) => {
              await getCustomer();
              dispatch(deactivateFormModal());
            })
            .catch(showError);
        },
      })
    );
  };

  const handleNewMailPicker = () =>
    dispatch(
      activateFormModal({
        initialValues: {
          company_id: customer?.id,
        },
        title: "Mail Picker",
        description:
          "Please complete the following information to add a new mail picker to this company.",
        items: [
          {
            name: "name",
            placeholder: "Name",
            rules: [{ required: true }],
          },
        ],
        primaryAction: (data) => {
          dispatch(updateFormModal({ loadingPrimaryButton: true }));

          Axios.post(`${apiAdmin}/companies/${customer?.id}/mail-pickers`, data)
            .then(async () => {
              await getCustomer();
              dispatch(deactivateFormModal());
            })
            .catch(showError);
        },
      })
    );

  const USPSForm = customer?.last_usps_form;

  const [link] = useState(document.createElement("a"));

  useEffect(() => {
    if (USPSForm?.url) {
      Axios.get(`${apiAdmin}/usps-forms/${USPSForm.id}/file`, {
        responseType: "blob",
      }).then(({ data }) => {
        const fileUrl = window.URL.createObjectURL(new Blob([data]));

        link.target = "_blank";
        link.href = fileUrl;
        link.download = USPSForm.url.split("/").reverse()[0];
      });
    }
    // eslint-disable-next-line
  }, [USPSForm]);

  return (
    <TwoColumns
      className={className}
      leftMenu={{
        title: "Menu",
        items: sections,
        onLeftMenuChange: setActiveSection,
      }}
      pageDescription={
        customer && {
          className: "page-description",
          title: (
            <span>
              {upperFirst(customer?.name)}{" "}
              {customer?.pmb && <span className="pmb">#{customer?.pmb}</span>}
            </span>
          ),
          showPaymentSources: false,
          rightContent: (
            <Row
              gutter={[0, 15]}
              className="right-content"
              justify="space-between"
              align="bottom"
            >
              <Col xs={{ order: 3 }} lg={{ order: 0 }}>
                {customer?.created_at && (
                  <Body1 className="created-at">
                    {moment(customer?.created_at).format("MMM D, YYYY")}{" "}
                    <span className="hour">
                      {moment(customer?.created_at).format("h a")}
                    </span>
                  </Body1>
                )}
              </Col>

              {customer?.active_plan && (
                <Col className="plan">
                  <Row wrap={false}>
                    <Col className="left">
                      <Body1 marginTop="0" textAlign="right">
                        Plan
                      </Body1>
                      <H3 textAlign="right" marginTop="0">
                        {customer?.active_plan?.nickname}
                      </H3>
                    </Col>
                    <Col className="right">
                      <Body2 marginTop="0">
                        <span className="label">VoIP?</span>{" "}
                        {customer.active_plan?.metadata.voip ? "True" : "False"}
                      </Body2>
                      <Body2 marginTop="0">
                        <span className="label">Recipients?</span>{" "}
                        {customer.active_plan?.metadata.recipients ?? 0}
                      </Body2>
                      {
                        <Body2 marginTop="0">
                          <span className="label">
                            {USPSForm?.url ? (
                              <u
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  link.click();
                                }}
                              >
                                PS Form 1583
                              </u>
                            ) : (
                              "PS Form 1583"
                            )}
                            ?
                          </span>{" "}
                          {!USPSForm
                            ? "Pending"
                            : USPSForm.approved === null
                            ? "Under review"
                            : USPSForm.approved
                            ? "Approved"
                            : "Rejected"}
                        </Body2>
                      }
                    </Col>
                  </Row>
                </Col>
              )}
              <Col className="metrics">
                <Row justify="center">
                  {!!customer?.members_count && (
                    <Col className="count">
                      <Row align="middle">
                        <PeopleIcon className="icon member" />
                        <Body1>{customer?.members_count}</Body1>
                      </Row>
                    </Col>
                  )}
                  {!!customer?.mail_items_count && (
                    <Col className="count">
                      <Row align="middle">
                        <MailIcon className="icon mail" />
                        <Body1>{customer?.mail_items_count}</Body1>
                      </Row>
                    </Col>
                  )}
                  {!!customer?.requested_services_count && (
                    <Col className="count ">
                      <Row align="middle">
                        <RequestIcon className="icon request" />
                        <Body1>{customer?.requested_services_count}</Body1>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          ),
          leftContent: (
            <Row className="left-content-container">
              <Col
                xs={{ span: 24 }}
                md={{ span: 20 }}
                xl={{ span: 18 }}
                className="left-content"
              >
                <Description
                  onDeleteMailPickerClick={handleDeleteMailPickerClick}
                  customer={customer}
                />
              </Col>
            </Row>
          ),
          bottomContent: <Row justify="space-between"></Row>,
        }
      }
    >
      {!customer && <BallPulse />}
      {customer && (
        <>
          {activeSection === 0 && (
            <>
              <Row justify="end" align="middle" gutter={[10, 10]}>
                <Col>
                  <AllMailsPickedUp onClick={handleAllMailsPickedUpClick} />
                </Col>
                <Col>
                  <StyledDropdownButton
                    options={{
                      items: [
                        { name: "Mail picker", onClick: handleNewMailPicker },
                        { name: "Mail", onClick: handleNewMailClick },
                      ],
                    }}
                  >
                    <div className="text">Add</div>
                    <div className="icon-container">
                      <PlusIcon />
                    </div>
                  </StyledDropdownButton>
                </Col>
              </Row>
              <MailTable
                mails={customer.mail_items as MailItemInterface[]}
                adminActions={true}
                onAdminActionsFinished={getCustomer}
                deleteAction={true}
                onDeleteActionFinished={getCustomer}
              />
            </>
          )}
          {activeSection === 1 && (
            <>
              <Row justify="end" gutter={[10, 10]}>
                <Col>
                  <StyledDropdownButton
                    options={{
                      items: [
                        { name: "Mail picker", onClick: handleNewMailPicker },
                      ],
                    }}
                  >
                    <div className="text">Add</div>
                    <div className="icon-container">
                      <PlusIcon />
                    </div>
                  </StyledDropdownButton>
                </Col>
              </Row>
              <MembersTable members={customer.members} />
            </>
          )}
        </>
      )}
    </TwoColumns>
  );
}

export default styled(Customer)`
  .page-description {
    background-color: ${guidelines.colors.blue[4]};

    .title {
      color: ${guidelines.colors.blue[0]};
      .pmb {
        font-weight: 500;
      }
    }

    .plan {
      align-self: flex-start;
      .left {
        padding-right: 7.5px;
        border-right: 1px solid ${guidelines.colors.red[0]};
        margin-right: 15px;
      }
      .right {
        .label {
          color: ${guidelines.colors.grey[0]};
        }
        p {
          font-size: 12px;
          color: ${guidelines.colors.grey[4]};
        }
      }
    }

    .metrics {
      background-color: ${guidelines.colors.blue[0]};
      border-radius: 10px;
      padding: 15px 25px;
      min-width: 100%;

      p {
        color: white;
      }

      .count {
        &:not(:last-child) {
          margin-right: 15px;
        }
        .icon {
          margin-right: 7.5px;
          &.member {
            color: #ff9b3f;
          }
          &.mail {
            color: #3fa3ff;
          }
          &.request {
            color: #66ac20;
          }
        }
        p {
          margin-top: 0;
        }
      }
    }

    .right-content-container {
      margin-left: auto;
      .right-content {
        flex-direction: column;
        height: 100%;

        .created-at {
          color: ${guidelines.colors.grey[0]};
          .hour {
            color: ${guidelines.colors.grey[4]};
          }
        }
      }
    }
  }
`;
