import React, { forwardRef, LegacyRef, useEffect, useState } from "react";
import {
  shift,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
  useFloating,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  Placement,
} from "@floating-ui/react-dom-interactions";
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const Menu = styled.div`
  background: white;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 6px;
`;

let Option = ({
  value,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { value: string }) => (
  <div {...props}>{children}</div>
);

Option = styled(Option)`
  border-radius: 4px;
  border: none;
  padding: 4px 11px;
  cursor: pointer;

  &:hover {
    background: #5377e2;
    color: white;
  }
`;

const Button = styled.div``;

const Select = forwardRef(
  (
    {
      children,
      version,
      placement,
      placeholder,
      value: initialValue,
      options,
      onChange,
      ...props
    }: React.ButtonHTMLAttributes<HTMLDivElement> & {
      value?: string;
      placeholder?: string;
      onChange?: (value: string) => void;
      version?: "outline" | "fill";
      placement?: Placement;
      options: {
        value: string;
        label: string | React.ReactNode;
      }[];
      //   status?: "loading";
    },
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | undefined>(initialValue);

    useEffect(() => {
      setValue(value);
      // eslint-disable-next-line
    }, [initialValue]);

    const { x, y, reference, floating, strategy, refs, update, context } =
      useFloating({
        open,
        onOpenChange: setOpen,
        placement: placement || "bottom-start",
        middleware: [shift(), flip(), offset({ mainAxis: 6 })],
      });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useClick(context),
      useRole(context, { role: "menu" }),
      useDismiss(context),
    ]);

    useEffect(() => {
      if (refs.reference.current && refs.floating.current && open) {
        return autoUpdate(
          refs.reference.current,
          refs.floating.current,
          update
        );
      }
    }, [refs.reference, refs.floating, update, open]);

    return (
      <div ref={ref}>
        <Button {...getReferenceProps({ ref: reference })} {...props}>
          {/* {status === "loading" && (
          <LoadingOutlined style={{ marginRight: 10 }} />
        )} */}
          {value !== undefined
            ? options.filter((options) => options.value === value)[0].label
            : placeholder}
        </Button>
        {
          <FloatingPortal>
            {open && (
              <AnimatePresence>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    height: "auto",
                  }}
                  exit={{
                    height: 0,
                    opacity: 0,
                  }}
                  transition={{ type: "spring", damping: 20, stiffness: 300 }}
                  {...getFloatingProps({
                    ref: floating,
                    className: "options",
                    style: {
                      zIndex: 2,
                      position: strategy,
                      top: y ?? "",
                      left: x ?? "",
                    },
                  })}
                >
                  <Menu>
                    {options.map((option, key) => {
                      return (
                        <Option
                          value={option.value}
                          key={key}
                          onClick={() => {
                            setValue(option.value);
                            onChange?.(option.value);
                            setOpen(false);
                          }}
                        >
                          {option.label}
                        </Option>
                      );
                    })}
                  </Menu>
                </motion.div>
              </AnimatePresence>
            )}
          </FloatingPortal>
        }
      </div>
    );
  }
);
export default styled(Select)`
  cursor: pointer;
  border: none;
  padding: 4px 15px;
  border-radius: 5px;
  display: inline-block;
  align-items: center;

  ${(props) =>
    props.version === "outline" &&
    css`
      border: 1px solid royalblue;
      color: royalblue;
      background: transparent;
    `}
  ${(props) =>
    props.version === "fill" &&
    css`
      color: white;
      background: royalblue;
    `}
`;
