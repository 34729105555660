import React, { useEffect } from "react";
import "./App.css";
import { Routes, Navigate, Route, useLocation } from "react-router-dom";
import {
  Subscriptions,
  ServicesShop,
  Login,
  Home,
  Team,
  Mail,
  Customers,
  Customer,
} from "./pages";
import { connect, useDispatch } from "react-redux";
import { setWindowSize } from "./store/actions/windowSizeActions";
import {
  getUserInfo,
  indexSources,
  activateFormModal,
  updateSocialNetworkInfo,
  deactivateFormModal,
  updateFormModal,
  storeVoip,
  activateSwitchCompanies,
} from "./store/actions";
import { SourcesModal, FormModal } from "./components";
import { Header } from "./components/layout";
import {
  FormModalReducerInterface,
  responsive,
  useShowError,
  VoipInterface,
  WindowSizeReducerInterface,
  AuthReducerInterface,
  useHandleError,
  useIsCompanyAdmin,
} from "./utils";
import { message } from "antd";
import { FormItemInterface } from "./components/form/Form";
import { SwitchCompanies } from "./components/switch-companies";
import Axios from "axios";
import { filter } from "lodash";
import BallPuse from "./components/loaders/BallPulse";
import useSegment from "./utils/hooks/useSegment";

interface AppInterface {
  auth: AuthReducerInterface;
  windowSize: WindowSizeReducerInterface;
  formModal: FormModalReducerInterface;
  activateFormModal: (data: FormModalReducerInterface) => Promise<any>;
  updateFormModal: (data: FormModalReducerInterface) => Promise<any>;
  deactivateFormModal: () => Promise<any>;
  updateSocialNetworkInfo: (data: {
    email?: string | undefined;
    company?: string | undefined;
  }) => Promise<any>;
  voip: VoipInterface;
  setWindowSize: (data: WindowSizeReducerInterface) => Promise<any>;
  getUserInfo: () => Promise<any>;
  storeVoip: (data: { user_phone: string; phnum: string }) => Promise<any>;
}

const App = ({
  auth: {
    email,
    token,
    roles,
    loaded,
    companies,
    invitations,
    default_company,
  },
  windowSize: { width },
  auth,
  formModal,
  voip,
  activateFormModal,
  updateFormModal,
  deactivateFormModal,
  updateSocialNetworkInfo,
  setWindowSize,
  getUserInfo,
}: AppInterface) => {
  const showError = useShowError();
  const handleError = useHandleError();
  const dispatch = useDispatch();
  const location = useLocation();

  const analytics = useSegment();

  useEffect(() => {
    Axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        handleError(error);
        return Promise.reject(error);
      }
    );

    return () => {};
    // eslint-disable-next-line
  }, []);

  Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }, [setWindowSize]);

  useEffect(() => {
    analytics?.page(location.pathname);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    auth.token && !auth.loaded && getUserInfo();

    if (auth.id && auth.email && auth.name)
      analytics?.identify(String(auth.id), {
        email: auth.email,
        name: auth.name,
      });
    // eslint-disable-next-line
  }, [auth]);

  const isCompanyAdmin = useIsCompanyAdmin();

  useEffect(() => {
    if (auth.loaded) {
      let items: FormItemInterface[] = [];
      if (!email) {
        items = [
          {
            name: "email",
            placeholder: "Email",
            rules: [
              {
                type: "email",
                message: "The input is not valid Email!",
              },
              {
                required: true,
                message: "Please input your Email!",
              },
            ],
          },
        ];
      }

      if (!auth.companies?.length && !auth.invitations?.length) {
        items = [
          ...items,
          {
            name: "company",
            placeholder: "Company",
            rules: [
              {
                required: true,
                message: "Please input your Company!",
              },
            ],
          },
        ];
      }

      if (items.length && !formModal.activeModal) {
        activateFormModal({
          title: "Information",
          description:
            "Please update the following information to begin your journey with us:",
          closeable: false,
          items: items,
          primaryAction: (data) => {
            updateFormModal({
              loadingPrimaryButton: true,
            });
            updateSocialNetworkInfo(data)
              .then(() => {
                updateFormModal({
                  loadingPrimaryButton: false,
                });
                deactivateFormModal().then(() =>
                  message.success("Information was successfully updated.")
                );
              })
              .catch(showError);
          },
        });
      }

      if (
        auth.invitations?.length &&
        !auth.companies?.length &&
        !formModal.activeModal
      ) {
        dispatch(
          activateSwitchCompanies({
            description: (
              <>
                <strong>Congratulations</strong>, you already have some
                invitations to join other companies; take a look to approve or
                decline them: "
              </>
            ),
            hide: ["companies", "new company"],
            closeable: false,
          })
        );
      }

      if (companies?.length && !default_company && !formModal.activeModal) {
        dispatch(
          activateSwitchCompanies({
            description:
              "Please select one of the following companies to get started:",
            closeable: false,
          })
        );
      }
    }
  });

  return (
    <>
      <Header span={responsive({ xs: 20 }, width)} />

      {token ? (
        loaded ? (
          <Routes>
            <Route path="/services-shop" element={<ServicesShop />} />
            <Route
              path="/subscriptions"
              element={
                isCompanyAdmin() ? (
                  <Subscriptions />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route path="/mail/:status" element={<Mail />} />
            <Route path="/mail" element={<Mail />} />
            <Route
              path="/team"
              element={
                isCompanyAdmin() ? <Team /> : <Navigate to="/" replace />
              }
            />

            <Route
              path="/customers/:id"
              element={
                (filter(roles, { name: "admin" }).length && <Customer />) || (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/customers"
              element={
                (filter(roles, { name: "admin" }).length && <Customers />) || (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        ) : (
          <div style={{ width: "100%", height: window.innerHeight }}>
            <BallPuse style={{ height: "100%" }} />
          </div>
        )
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
      <SourcesModal />
      <FormModal />
      {loaded && <SwitchCompanies />}
    </>
  );
};

const mapStateToProps = (state: {
  auth: AuthReducerInterface;
  formModal: FormModalReducerInterface;
  voip: VoipInterface;
  windowSize: WindowSizeReducerInterface;
}) => ({
  formModal: state.formModal,
  voip: state.voip,
  windowSize: state.windowSize,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: (dispatch: any) => Promise<any>) => ({
  activateFormModal: (data: FormModalReducerInterface) =>
    dispatch(activateFormModal(data)),
  updateFormModal: (data: FormModalReducerInterface) =>
    dispatch(updateFormModal(data)),
  deactivateFormModal: () => dispatch(deactivateFormModal()),
  updateSocialNetworkInfo: (data: {
    email?: string | undefined;
    company?: string | undefined;
  }) => dispatch(updateSocialNetworkInfo(data)),
  storeVoip: (data: { user_phone: string; phnum: string }) =>
    dispatch(storeVoip(data)),
  setWindowSize: (size: WindowSizeReducerInterface) =>
    dispatch(setWindowSize(size)),
  getUserInfo: () => dispatch(getUserInfo()),
  indexSources: () => dispatch(indexSources()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
