import React, { useCallback, useEffect, useState } from "react";
import { Cards } from "../../components";
import {
  ActiveSubscriptionInterface,
  apiClient,
  PlanInterface,
  StorageReducerInterface,
  SubscriptionInterface,
  SubscriptionsReducerInterface,
  WindowSizeReducerInterface,
} from "../../utils";
import { connect, useDispatch, useSelector } from "react-redux";
import { indexSubscriptions, resetSubscriptions } from "../../store/actions";
import { TwoColumns } from "../../components/layout";
import BallPuse from "../../components/loaders/BallPulse";
import useBackToHomeHeader from "../../utils/hooks/useBackToHomeHeader";
import useCancelSubscriptionModal from "../../utils/hooks/modals/useCancelSubscriptionModal";
import useActivateOrChangeSubscriptionModal from "../../utils/hooks/modals/useActivateOrChangeSubscriptionModal";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";

const Subscriptions = (props: {
  windowSize: WindowSizeReducerInterface;
  subscriptions: SubscriptionsReducerInterface;
}) => {
  const [state, setState] = useState<{
    activeSection: number;
    activeSubsection: number;
  }>({
    activeSection: 0,
    activeSubsection: 0,
  });

  const dispatch = useDispatch<any>();

  const { activeSection, activeSubsection } = state;

  const auth = useSelector((store: StorageReducerInterface) => store.auth);

  const { default_company } = auth;

  const { data, refetch } = useQuery<SubscriptionsReducerInterface>({
    queryKey: ["subscriptions"],
    queryFn: () =>
      Axios.get(
        `${apiClient}/companies/${default_company?.company.id}/subscriptions`
      ).then(async ({ data }) => {
        return data;
      }),
    enabled: !!default_company,
  });

  // const {
  //   subscriptions: { all, active },
  // } = props;

  const backToHomeHeader = useBackToHomeHeader();

  useEffect(() => {
    backToHomeHeader();
    // eslint-disable-next-line
  }, []);

  const cancelSubscriptionModal = useCancelSubscriptionModal(refetch);

  const activateOrChangeSubscriptionModal =
    useActivateOrChangeSubscriptionModal(refetch);

  useEffect(() => {
    dispatch(indexSubscriptions());
    return () => {
      dispatch(resetSubscriptions());
    };
  }, [dispatch]);

  const handleLeftMenuChange = (section: number) => {
    setState({
      ...state,
      activeSection: section,
    });
  };

  const handleTabMenuChange = (subsection: number) => {
    setState({
      ...state,
      activeSubsection: subsection,
    });
  };

  const subscription: SubscriptionInterface | undefined =
    data?.all?.[activeSection];

  const cards = useCallback(
    () =>
      subscription?.plans &&
      Object.values(subscription.plans as object)[activeSubsection].map(
        (plan: PlanInterface) => {
          let activePlans: PlanInterface[] = [];

          data?.active.forEach((subscription) => {
            activePlans = subscription.plans.filter(
              (activePlan) => activePlan.product === plan.product
            );
          });

          const activeSubscription: ActiveSubscriptionInterface | undefined =
            data?.active.filter((subscription) => {
              return !!subscription.plans.filter(
                (activePlan) => activePlan.id === plan.id
              )[0];
            })[0];

          const isActive = activeSubscription ? true : false;

          if (activeSubscription?.cancel_at_period_end)
            plan.message = "Canceled at period end";

          return {
            title: `$ ${(plan.amount / 100)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
            suffix: "plus tax",
            subtitle: plan.nickname,
            abstract: plan.metadata.description
              ? JSON.parse(plan.metadata.description)
              : null,
            onPrimaryButtonClick: activateOrChangeSubscriptionModal({
              isActive,
              plan,
              activePlans,
              subscription,
            }),
            onSecondaryButtonClick: cancelSubscriptionModal({
              plan,
              activeSubscription,
              isActive,
              subscription,
            }),
            disabled: !plan.message,
            message:
              plan.message ??
              (plan.metadata?.soon && !isActive ? "Soon!" : null),
          };
        }
      ),
    [
      data,
      subscription,
      activeSubsection,
      activateOrChangeSubscriptionModal,
      cancelSubscriptionModal,
    ]
  );

  if (data?.all.length && subscription) {
    const leftMenuItems = data?.all.map((product) => product.name);
    const tabMenuItems = Object.keys(data?.all[activeSection].plans);

    return (
      <>
        <TwoColumns
          leftMenu={{
            title: "Services",
            items: leftMenuItems,
            onLeftMenuChange: handleLeftMenuChange,
          }}
          pageDescription={{
            title: subscription.name,
            description: subscription.metadata.description,
          }}
          tabMenu={{
            items: tabMenuItems,
            onLeftMenuChange: handleTabMenuChange,
          }}
        >
          <Cards type="vertical" cards={cards()}></Cards>
        </TwoColumns>
      </>
    );
  } else {
    return (
      <div style={{ width: "100%", height: window.innerHeight }}>
        <BallPuse style={{ height: "100%" }} />
      </div>
    );
  }
};

const mapStateToProps = (state: {
  windowSize: WindowSizeReducerInterface;
  subscriptions: SubscriptionsReducerInterface;
}) => ({
  windowSize: state.windowSize,
  subscriptions: state.subscriptions,
});

export default connect(mapStateToProps)(Subscriptions);
