import React, { useEffect, useState } from "react";
import { Col, Row, RowProps, Tooltip } from "antd";
import styled from "styled-components";
import { Body1, Body3 } from "../typography";
import { ReactComponent as Mp3Icon } from "../../icons/mp3.svg";
import { ReactComponent as PlayIcon } from "../../icons/play.svg";
import { ReactComponent as PauseIcon } from "../../icons/pause.svg";
import { ReactComponent as UploadIcon } from "../../icons/upload.svg";
import { ReactComponent as RetryIcon } from "../../icons/retry.svg";
import { guidelines } from "../../utils";
import { upperFirst } from "lodash";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getVoip } from "../../store/actions";
import { LoadingOutlined } from "@ant-design/icons";

let Mp3Upload = ({
  data: {
    file: { title, url, size },
    request,
  },
  className,
  ...props
}: {
  data: {
    file: {
      title: string;
      url: string;
      size?: number;
    };
    request: {
      key: string;
      url: string;
    };
  };
} & RowProps &
  React.RefAttributes<HTMLDivElement>) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [uploading, setUploading] = useState<{
    inProgress: boolean;
    uploadPercentage?: number;
    failed?: string;
  }>({
    inProgress: false,
  });
  const [failed, setFailed] = useState<string | null>();

  const dispatch = useDispatch<any>();

  const [audio, setAudio] = useState<HTMLAudioElement>(new Audio(url));

  useEffect(() => {
    setAudio(new Audio(url));
    return () => {};
  }, [url]);

  audio.onended = () => {
    setPlaying(false);
  };

  const handlePlayButton = () => {
    audio.play();
    setPlaying(true);
  };

  const handlePauseButton = () => {
    audio.pause();
    audio.currentTime = 0;
    setPlaying(false);
  };

  const input = document.createElement("input");

  input.type = "file";
  input.accept = ".mp3,.wav";

  input.onchange = (e) => {
    setUploading({
      inProgress: true,
      uploadPercentage: 0,
    });
    setFailed(null);

    const dom = e.currentTarget as HTMLInputElement,
      file = dom.files?.[0];

    let formData = new FormData();

    if (file) {
      formData.append(request.key, file);

      axios
        .post(request.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploading({
              inProgress: true,
              uploadPercentage:
                (progressEvent.loaded as number) /
                (progressEvent.total as number),
            });
          },
        })
        .then(async () => {
          await dispatch(getVoip());
          setUploading({
            inProgress: false,
          });
        })
        .catch((e: any) => {
          setFailed(e.response?.data?.message || e.message || "Failed");
        });
    }
  };

  const handleUploadButton = () => {
    input.click();
  };

  return (
    <Row className={`${className} ${failed ? "failed" : ""}`} {...props}>
      <Col span={24}>
        <Row align="middle" gutter={[15, 0]}>
          {uploading.inProgress ? (
            <Col className="icon">
              <Row align="middle">
                <Mp3Icon />
              </Row>
            </Col>
          ) : (
            <Col className="control">
              <Row align="middle" justify="center">
                {playing ? (
                  <PauseIcon onClick={handlePauseButton} />
                ) : (
                  <PlayIcon onClick={handlePlayButton} />
                )}
              </Row>
            </Col>
          )}
          <Col flex="1" className="info">
            {uploading.inProgress ? (
              <>
                <Row justify="space-between">
                  <Body1 className="title" marginTop="0">
                    {failed || "Uploading"}
                  </Body1>
                  <Body3 className="text" marginTop="0">
                    {uploading.uploadPercentage
                      ? (uploading.uploadPercentage * 100).toFixed(2)
                      : 0}
                    %
                  </Body3>
                </Row>
                <Row>
                  <div className="progress-bar-container">
                    <div
                      style={{
                        width:
                          uploading.uploadPercentage &&
                          `${uploading.uploadPercentage * 100}%`,
                      }}
                      className="progress-bar"
                    ></div>
                  </div>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Body1 className="title" marginTop="0">
                    {upperFirst(title)}
                  </Body1>
                </Row>
                <Row>
                  <Body3 className="text size" marginTop="0">
                    {size && (
                      <>
                        {(size / 1000).toFixed(2)}{" "}
                        <span className="underline">mb</span>
                      </>
                    )}
                  </Body3>
                </Row>
              </>
            )}
          </Col>
          {failed ? (
            <Col>
              <Tooltip title="Maximum 10mb, and file must be .wav or .mp3.">
                <div className="retry" onClick={handleUploadButton}>
                  <RetryIcon />
                </div>
              </Tooltip>
            </Col>
          ) : uploading.inProgress && uploading.uploadPercentage === 1 ? (
            <LoadingOutlined className="loading" />
          ) : !uploading.inProgress ? (
            <Col>
              <Tooltip
                placement="topRight"
                title="Maximum 10mb, and file must be .wav or .mp3."
              >
                <div className="upload" onClick={handleUploadButton}>
                  <UploadIcon />
                </div>
              </Tooltip>
            </Col>
          ) : (
            false
          )}
          {/* {(failed || !uploading.inProgress) && (
            <Col>
              <Tooltip
                placement="topRight"
                title="Maximum 10mb, and file must be .wav or .mp3."
                className="tooltip"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
          )} */}
        </Row>
      </Col>
    </Row>
  );
};

Mp3Upload = styled(Mp3Upload)`
  border: 1px solid ${guidelines.colors.blue[9]};
  padding: 15px 15px 15px 30px;
  border-radius: 5px;

  .upload {
    background-color: ${guidelines.colors.blue[4]};
    border: 1px dashed ${guidelines.colors.blue[8]};
    border-radius: 6px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 15px;
    }
  }

  .retry {
    background-color: ${guidelines.colors.red[2]};
    border: 1px dashed ${guidelines.colors.red[1]};
    border-radius: 6px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 15px;
    }
  }

  .info {
    .text {
      color: ${guidelines.colors.blue[10]};
      .underline {
        border-bottom: 1px solid ${guidelines.colors.blue[10]};
        padding-bottom: 1px;
      }
    }

    .progress-bar-container {
      margin-top: 5px;
      width: 100%;
      height: 5px;
      border-radius: 50px;
      overflow: hidden;
      background-color: ${guidelines.colors.blue[7]};
      .progress-bar {
        border-radius: 50px;
        background-color: ${guidelines.colors.blue[8]};
        height: 100%;
        transition: width 0.5s;
      }
    }
  }

  .control {
    width: 45px;
    svg {
      cursor: pointer;
    }
  }

  .icon {
    width: 45px;
  }

  &.failed {
    .info {
      .title {
        color: ${guidelines.colors.red[1]};
      }

      .progress-bar {
        background-color: ${guidelines.colors.red[3]};
      }
    }
  }

  .loading {
    color: ${guidelines.colors.blue[8]};
    font-size: 26px;
    padding: 0 14.5px;
  }

  .tooltip {
    color: ${guidelines.colors.grey[4]};
  }
`;

export default Mp3Upload;
